/* @flow */

import React, { useContext, memo } from 'react'
import styled from 'styled-components'

import {
  ProductTableContext,
  TableRow,
  TableColumn,
  createEmptyArray,
} from '../shared'
import { columnConfigToStyle, flattenRows } from './shared'

const HeaderRow = ({
  collapsed,
  horizontalAttributes,
  maxHorizontalColumns,
  noActionColumns,
  onTableControlsChange,
  product,
  productActions,
  productHeaderMode,
  toggles,
}) => {
  const {
    actions,
    columns,
    matrix,
    preview,
    productImage,
    rows,
    settings,
    variantImages,
  } = useContext(ProductTableContext)

  const toggleCollapsed = React.useCallback(() => {
    onTableControlsChange({
      type: 'update_toggles',
      product_ids: [product.id],
      data: {
        collapsed: !collapsed,
      },
    })
  }, [collapsed, onTableControlsChange, product])

  const flattenedRows = React.useMemo(
    () => (matrix ? [] : flattenRows(rows)[0]),
    [matrix, rows]
  )

  const showImageColumn =
    productHeaderMode === 'default' &&
    (productImage === true || variantImages === true)

  let colSpan = 0
  if (matrix) {
    colSpan = maxHorizontalColumns + 1
  } else {
    colSpan += 1 + flattenedRows.length
  }

  if (productHeaderMode === 'wide_product_data') {
    colSpan += columns.length

    // the image column is no longer shown
    colSpan += 1
  }

  const noEmptyActionColumns = noActionColumns - productActions.length

  return (
    // Header row
    <ProductHeaderRow
      backgroundColor={settings.product_header_color}
      fontColor={settings.product_header_font_color}
      onClick={toggleCollapsed}
    >
      {showImageColumn && <TableColumn key="image" />}
      <TableColumn key="attributes" colSpan={colSpan}>
        <span className="print-hide">
          {collapsed === true && <CollapsedIcon />}
          {collapsed === false && <OpenIcon />}
        </span>

        {product.name}
      </TableColumn>
      {matrix && <TableColumn />}
      {productHeaderMode !== 'wide_product_data' &&
        columns.map((column, i) => {
          let content
          if (settings.product_data_show) {
            content = column.renderHeader
              ? column.renderHeader
              : // check for undefined as it can be empty
              column.headerLabel !== undefined
              ? column.headerLabel
              : column.label
          }

          return (
            <TableColumn
              key={`${column.key}-header`}
              style={columnConfigToStyle(column)}
            >
              {content}
            </TableColumn>
          )
        })}
      {productActions.map(action => {
        return (
          <TableColumn key={action.key} style={{ width: 29 }}>
            {action.render({
              onTableControlsChange,
              product,
              toggles,
            })}
          </TableColumn>
        )
      })}
      {/* we give the column a fixed with so columns dont jump to much when collapsing */}
      {createEmptyArray(noEmptyActionColumns).map((a, i) => (
        <TableColumn key={`actions-${i}`} style={{ width: 29 }} />
      ))}
    </ProductHeaderRow>
  )
}

export default memo(HeaderRow)

// background important is needed to override chrome print styles
const ProductHeaderRow = styled(TableRow)`
  td {
    background: ${({ backgroundColor }) =>
      backgroundColor ? `${backgroundColor} !important` : '#707070 !important'};
    -webkit-print-color-adjust: exact;
    border: ${({ backgroundColor }) =>
      backgroundColor ? `1px solid  ${backgroundColor}` : '1px solid #707070'};
    /* border-top: none because for some reason the border stays on the previous page
       in Chrome when page-breaking right before this product */
    border-top: none;
    border-bottom: none;
    color: ${({ fontColor }) =>
      fontColor ? `${fontColor} !important` : 'white !important'};
    cursor: pointer;
    font-size: 11px;
    line-height: 1.55;
    font-weight: bold;
    text-transform: uppercase;
  }
`

const CollapsedIcon = styled.span.attrs({
  className: 'glyphicon glyphicon-chevron-up',
})`
  margin-right: 5px;
`

const OpenIcon = styled.span.attrs({
  className: 'glyphicon glyphicon-chevron-down',
})`
  margin-right: 5px;
`
