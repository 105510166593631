/* @flow */

import React from 'react'

import type { Product, Variant } from '../../types'

type Props = {
  product: {
    name: string,
    attributes: Array<string>,
  },
  showProductName?: boolean,
  variant: {
    attributes: { [string]: string },
  },
}

const VariantLabel = ({ product, showProductName = false, variant }: Props) => {
  const useLabel = React.useMemo(() => {
    if (!variant) {
      return product.name
    }

    let label = showProductName ? `${product.name} ` : ''

    const attributesLabel = []
    for (let attribute of product.attributes) {
      attributesLabel.push(variant.attributes[attribute])
    }

    return label + attributesLabel.join(', ')
  }, [product, showProductName, variant])

  return useLabel
}

export default VariantLabel
