/* @flow */

import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import diff from 'lodash/difference'
import memoize from 'memoize-one'
import isFunction from 'lodash/isFunction'
import sortBy from 'lodash/sortBy'
import keyBy from 'lodash/keyBy'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import type {
  ColumnOption,
  ColumnOptions,
  DataTableAttribute,
  DataTableOptions,
  Entity,
  Filter,
  Id,
  User,
} from '../types'
import { COLUMN_VISIBILITY, FakeLinkStyle } from './shared'
import {
  refreshSession,
  userHasFlag,
  SessionContext,
  HelpIndicator,
  Tooltip,
} from '../shared'

import { saveDataTableSettings } from './api'
import { UserHasPermissions } from '../../infrastructure/components/Authorization'

type BaseProps = {
  attributes: Array<DataTableAttribute>,
  checkable: boolean,
  checked: Array<Id>,
  columnOptions: ColumnOptions,
  columns: Array<string>,
  compact?: boolean,
  defaultColumns: Array<string>,
  defaultPerPage: number,
  entity: Entity,
  extraProps?: Object,
  filtersShouldLink?: boolean,
  filters: Array<Filter>,
  fixedColumns?: Array<string>,
  fixedWidths?: { [number]: number },
  id: string,
  page: number,
  pagination?: boolean,
  perPage: number,
  onCheck: (checked: Array<Id>, checkedObjects: Array<Object>) => void,
  onOptionsChange: (
    options: $Shape<DataTableOptions>,
    changedProperty?: string
  ) => void,
  renderFilters?: Function,
  renderPagination?: Function,
  rows: Array<Object>,
  sort: string,
  sortDirection: string,
  total: number,
  user: User,
}

type CheckableProps = {
  checkable: true,
  onCheck: (checked: Array<Id>, checkedObjects: Array<Object>) => void,
}

type NonCheckableProps = {
  checkable: false,
}

type Props = CheckableProps | NonCheckableProps

type State = {
  columns: Array<string>,
  externalData: Object,
  externalDataFetching: Object,
  showFilterMenu: false | Filter,
}

export default class DataTable extends PureComponent<Props, State> {
  static defaultProps = {
    compact: false,
    defaultPerPage: 20,
    filtersShouldLink: false,
    fixedColumns: [],
    fixedWidths: {},
    pagination: true,
  }

  static contextType = SessionContext

  state = {
    columns: this.props.columns,
    externalData: {},
    externalDataFetching: {},
    pinned: [],
    showAddAdvancedFilterModal: false,
    showFilterMenu: false,
    showColumnsManagerModal: false,
  }

  componentDidMount() {
    this._hydrateFromDataTableSettings()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const shouldUpdateGrid =
      prevProps.rows !== this.props.rows ||
      prevProps.columns !== this.props.columns ||
      prevProps.checked !== this.props.checked ||
      prevState.externalData !== this.state.externalData ||
      prevProps.extraProps !== this.props.extraProps

    if (
      this.props.columnOptions !== prevProps.columnOptions ||
      this.props.defaultColumns !== prevProps.defaultColumns
    ) {
      this._hydrateFromDataTableSettings()
    }

    if (prevProps.rows !== this.props.rows) {
      this._getExternalData(true)
    } else if (prevProps.columns !== this.props.columns) {
      this._getExternalData(false)
      // When expanding page new rows will appear that are missing the external data
    } else if (prevProps.perPage != this.props.perPage) {
      this._getExternalData(true)
    }

    // Happens if you have per page = 30 on page 10 (total = 500) and you change to per page 100.
    // I this case we should go back to the last page available.
    if (
      prevProps.columns !== this.props.columns ||
      prevProps.perPage !== this.props.perPage
    ) {
      this._saveTableSettings()
    }
  }

  render() {
    const {
      attributes,
      checkable,
      checked,
      compact,
      extraProps,
      filters,
      fixedColumns,
      onOptionsChange,
      page,
      pagination,
      perPage,
      renderFilters,
      renderPagination,
      rows,
      total,
    } = this.props
    const { brand, entity } = this.context
    const {
      externalData,
      externalDataFetching,
      pinned,
      showFilterMenu,
      showAddAdvancedFilterModal,
      showColumnsManagerModal,
    } = this.state

    const columnOptions = this._prepareColumnOptions(
      this.props.columnOptions,
      entity
    )
    const columns = this._prepareColumns(this.props.columns, columnOptions)
    const selectedColumns = this._prepareSelectedColumns(columns, columnOptions)
    const columnOptionsByKey = this._getAllColumnOptionsByKey(columnOptions)

    let columnCount = selectedColumns.length
    if (checkable) {
      columnCount++
    }

    const showSubtotals = selectedColumns.some(
      column => columnOptionsByKey[column.key].renderSubtotal !== undefined
    )

    return (
      <Container>
        {attributes.length > 0 && renderFilters && (
          <Filters>
            {renderFilters({
              attributes,
              columns: this._prepareToggleableColumns(columns, columnOptions),
              selectedColumns,
              columnOptions:
                this._prepareToggleableColumnOptions(columnOptions),
              filters,
              onChange: this._onFiltersUpdate,
              onOptionsChange: onOptionsChange,
              onPinChange: this._onPinChange,
              pagination,
              perPage,
              pinnedAttributes: pinned,
              showAddAdvancedFilterModal,
              showFilterMenu,
              showColumnsManagerModal,
              toggleAddAdvancedFilterModal: this._toggleAddAdvancedFilterModal,
              toggleFilterMenu: this._toggleFilterMenu,
              toggleShowColumnsManagerModal:
                this._toggleShowColumnsManagerModal,
            })}
          </Filters>
        )}
        <TableContainer showSubtotals={showSubtotals}>
          <ScrollContainer>
            {/* do not remove the database class. it is used to override important styles set by table-condensed */}
            <table
              className={`datatable table ${
                compact ? 'table-condensed-xs' : 'table-condensed'
              }`}
            >
              <thead>
                <tr>
                  {checkable === true && (
                    <HeaderColumn>{this._renderHeaderCheckbox()}</HeaderColumn>
                  )}
                  {selectedColumns.map(column => {
                    const options = columnOptionsByKey[column.key]

                    if (options.feature_flag) {
                      if (!userHasFlag(brand, options.feature_flag)) {
                        return null
                      }
                    }

                    return (
                      <HeaderColumn>{this._renderHeader(options)}</HeaderColumn>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {rows.map((data, i) => {
                  return (
                    <tr key={data.id}>
                      {checkable === true && (
                        <td width={35}>{this._renderBodyCheckbox(data)}</td>
                      )}
                      {selectedColumns.map(column => {
                        const options = columnOptionsByKey[column.key]

                        if (options.feature_flag) {
                          if (!userHasFlag(brand, options.feature_flag)) {
                            return null
                          }
                        }

                        return (
                          <td width={options.width}>
                            {this._renderBodyCell(
                              options,
                              data,
                              externalData,
                              externalDataFetching,
                              extraProps
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
              {showSubtotals && (
                <tfoot>
                  <tr>
                    {checkable === true && <FooterColumn />}

                    {selectedColumns.map(column => {
                      const options = columnOptionsByKey[column.key]

                      return (
                        <FooterColumn key={column.key}>
                          <FooterCellContainer rightAlign={options.rightAlign}>
                            <FixedFooterCell>
                              {options.renderSubtotal
                                ? options.renderSubtotal(rows)
                                : null}
                            </FixedFooterCell>
                          </FooterCellContainer>
                        </FooterColumn>
                      )
                    })}
                  </tr>
                </tfoot>
              )}
            </table>
          </ScrollContainer>
          {showSubtotals && (
            <FooterSubtotalsLabel>
              Subtotals for shown rows
            </FooterSubtotalsLabel>
          )}
        </TableContainer>
        {pagination === true && renderPagination && (
          <ListControls>
            {renderPagination({ onOptionsChange, page, perPage, total })}
          </ListControls>
        )}
      </Container>
    )
  }

  _createFilterLink = (text, filters) => {
    const { filters: currentFilters, filtersShouldLink } = this.props

    const onClick = () => {
      const updatedFilters = [...currentFilters]

      for (let addFilter of filters) {
        let foundIndex = false
        for (let [i, filter] of updatedFilters.entries()) {
          if (filter.key === addFilter.key) {
            foundIndex = i
          }
        }

        if (foundIndex === false) {
          updatedFilters.push(addFilter)
        } else {
          updatedFilters[foundIndex] = addFilter
        }
      }

      this.props.onOptionsChange({ filters: updatedFilters }, 'filters')
    }

    /**
      TODO: It would be nice if we had actual URL links for this
     */

    return (
      <Tooltip tip={`Filter by ${text}`}>
        <FakeLinkStyle onClick={onClick}>{text}</FakeLinkStyle>
      </Tooltip>
    )
  }

  _hydrateFromDataTableSettings = () => {
    const {
      columnOptions,
      defaultColumns,
      defaultPerPage,
      id,
      onOptionsChange,
    } = this.props
    const { user } = this.context
    const settings = user.data_table_settings[id] || {}

    settings.columns = convertLegacyColumns(settings.columns, columnOptions)

    this.setState(
      {
        pinned: settings.pinned || [],
      },
      () => {
        onOptionsChange({
          columns:
            settings.columns && settings.columns.length > 0
              ? addNewNonToggleableColumns(settings.columns, columnOptions)
              : defaultColumns,
          perPage: settings.perPage || defaultPerPage,
        })
      }
    )
  }

  _getExternalData = (forceRefresh: boolean) => {
    const { entity } = this.context
    const { rows } = this.props
    const { externalData } = this.state

    const columnOptions = this._prepareColumnOptions(
      this.props.columnOptions,
      entity
    )
    const columns = this._prepareColumns(this.props.columns, columnOptions)
    const selectedColumns = this._prepareSelectedColumns(columns, columnOptions)
    const columnOptionsByKey = this._getAllColumnOptionsByKey(columnOptions)

    const promises = []
    const promisesKeys = []
    const externalDataFetching = {}
    for (var column of selectedColumns) {
      const columnOptions = columnOptionsByKey[column.key]

      if (columnOptions.externalData) {
        for (var externalDataKey in columnOptions.externalData) {
          const promiseCreator = columnOptions.externalData[externalDataKey]

          // When toggling columns, e.g. hiding/showing a data-dependant
          // column, we only want to fetch the data if it is not already fetched
          if (!forceRefresh && externalData[externalDataKey]) {
            continue
          }

          promises.push(promiseCreator(rows))
          promisesKeys.push(externalDataKey)

          externalDataFetching[externalDataKey] = true
        }
      }
    }

    if (promises.length > 0) {
      Promise.all(promises).then(responses => {
        const externalData = {}
        const externalDataFetching = {}
        for (var i = 0; i < promisesKeys.length; i++) {
          externalData[promisesKeys[i]] = responses[i]
          externalDataFetching[promisesKeys[i]] = false
        }

        this.setState(s => ({
          externalData: { ...s.externalData, ...externalData },
          externalDataFetching: {
            ...s.externalDataFetching,
            ...externalDataFetching,
          },
        }))
      })
    }
  }

  _getPinnedFromSettings = () => {
    const { id } = this.props
    const { user } = this.context
    const settings = user.data_table_settings[id] || {}

    this.setState({
      pinned: settings.pinned || [],
    })
  }

  _prepareColumns = memoize((columns, columnOptions) => {
    const { user } = this.context
    const columnOptionsByKey = this._getAllColumnOptionsByKey(columnOptions)

    const columnsSorted = sortBy(columns, 'sortOrder')

    return columnsSorted
      .map((column, index) => {
        if (!column) {
          return false
        }

        const options = columnOptionsByKey[column.key]

        if (!options) {
          return false
        }

        if (
          options.permission &&
          !UserHasPermissions(user, options.permission)
        ) {
          return false
        }

        return {
          ...column,
          show: column.show,
          sortOrder: index,
          visibility: options.visibility,
        }
      })
      .filter(c => c !== false)
  })

  _prepareSelectedColumns = memoize(columns => columns.filter(c => c.show))

  _prepareColumnOptions = memoize((columnOptions, entity) =>
    columnOptions.map(group => ({
      ...group,
      columns: group.columns.filter(columnOptions => {
        if (columnOptions.visibility) {
          if (
            entity.entity_type === 'shop' &&
            columnOptions.visibility === COLUMN_VISIBILITY.BRAND
          ) {
            return false
          } else if (
            entity.entity_type === 'brand' &&
            columnOptions.visibility === COLUMN_VISIBILITY.RETAILER
          ) {
            return false
          }
        }

        return true
      }),
    }))
  )

  _prepareToggleableColumns = memoize((columns, columnOptions) => {
    const columnOptionsByKey = this._getAllColumnOptionsByKey(columnOptions)

    return columns
  })

  _prepareToggleableColumnOptions = memoize(columnOptions =>
    columnOptions
      .map(group => ({
        ...group,
        columns: group.columns
          // we have seen that options can be false
          .filter(options => options !== false)
          /*.filter(options =>
            // cannot use || operator since toggleable might be false
            options.toggleable !== undefined ? options.toggleable : true
          )*/
          .map(options => {
            options.group = group.label
            return options
          }),
      }))
      .reduce((carry, group) => carry.concat(group.columns), [])
  )

  _getAllColumnOptionsByKey = memoize(columnOptions => {
    return columnOptions
      .reduce((carry, group) => carry.concat(group.columns), [])
      .reduce((carry, column) => {
        carry[column.value] = column
        return carry
      }, {})
  })

  _hideFilterMenu = () => {
    this.setState({
      showFilterMenu: false,
    })
  }

  _onFiltersUpdate = (
    filters: Array<Filter>,
    attribute: DataTableAttribute,
    action: 'add' | 'edit' | 'remove',
    changedFilter: Filter
  ) => {
    this.props.onOptionsChange({ filters }, 'filters')
    this.setState({
      showFilterMenu: false,
    })
  }

  _onPinChange = attribute => {
    const pinned = this.state.pinned

    const updated = [...pinned]
    const index = updated.indexOf(attribute)

    if (index === -1) {
      updated.push(attribute)
    } else {
      updated.splice(index, 1)
    }

    this.setState(
      {
        pinned: updated,
      },
      this._saveTableSettings
    )
  }

  _renderBodyCell = (
    column: ColumnOption,
    data: Object,
    externalData: Object,
    externalDataFetching: Object,
    extraProps?: Object
  ) => {
    return (
      <BodyCellContainer id={column.value} rightAlign={column.rightAlign}>
        {column.render(
          data,
          externalData,
          externalDataFetching,
          extraProps,
          this._createFilterLink
        )}
      </BodyCellContainer>
    )
  }

  _renderBodyCheckbox = (data: { id: Id }) => {
    const { checked, id } = this.props

    return (
      <CheckboxContainer>
        <input
          checked={checked.indexOf(data.id) !== -1}
          onChange={() => this._toggleRow(data)}
          type="checkbox"
        />
      </CheckboxContainer>
    )
  }

  _renderHeader = (column: ColumnOption, columnIndex: number) => {
    const { id, sort, sortDirection } = this.props
    const { user } = this.context

    let sortProperty = column.sortProperty || column.value
    const help = column.help

    let callback
    if (column.sortable) {
      if (isFunction(sortProperty)) {
        sortProperty = sortProperty({ user })
      }

      callback = () => {
        let direction = 'asc'
        if (sort === sortProperty && sortDirection === 'asc') {
          direction = 'desc'
        }

        this.props.onOptionsChange(
          {
            sort: sortProperty,
            sortDirection: direction,
          },
          'sort'
        )
      }
    }

    const content = (
      <div>
        <span>
          {column.renderHeader ? column.renderHeader() : column.label}
        </span>

        {sort === sortProperty &&
          (sortDirection === 'asc' ? <SortIconAsc /> : <SortIconDesc />)}

        {help && (
          <HelpIndicator
            id={`${id}-help-${column.value}`}
            size="sm"
            style={{ marginLeft: 3 }}
          >
            {help}
          </HelpIndicator>
        )}
      </div>
    )

    /**
     * We use position absolute in FixedHeaderCell to create fixed headers. FixedheaderCellWidthElement is used
     * to generate width. It contains the same content as FixedHeaderCell but is not shown
     */

    return (
      <HeaderCellContainer rightAlign={column.rightAlign}>
        <FixedHeaderCellWidthElement>{content}</FixedHeaderCellWidthElement>
        <FixedHeaderCell
          onClick={callback}
          sortable={column.sortable}
          sorted={sort === sortProperty}
        >
          {content}
        </FixedHeaderCell>
      </HeaderCellContainer>
    )
  }

  _renderHeaderCheckbox = () => {
    const { checked, rows } = this.props

    const isAllChecked =
      diff(
        rows.map(row => row.id),
        checked
      ).length === 0

    const content = (
      <CheckboxContainer>
        <input
          checked={isAllChecked}
          onChange={this._toggleAllPageRows}
          type="checkbox"
        />
      </CheckboxContainer>
    )

    return (
      <div>
        <FixedHeaderCellWidthElement>{content}</FixedHeaderCellWidthElement>
        <FixedHeaderCell style={{ top: 16 }}>{content}</FixedHeaderCell>
      </div>
    )
  }

  _saveTableSettings = () => {
    const { columns, id, perPage } = this.props

    saveDataTableSettings(id, {
      columns,
      perPage,
      pinned: this.state.pinned,
    }).then(response => {
      if (!response.error) {
        refreshSession()
      }
    })
  }

  _toggleAllPageRows = () => {
    const { checked, rows } = this.props

    const rowIds = rows.map(row => row.id)
    const isAllChecked = diff(rowIds, checked).length === 0

    /**
     * Because we have pagination we have to only remove/add the current page
     * from the checked IDs. One downside is that checkedObjects will only contain
     * the current rows that are checked and newChecked will contain IDs of multiple pages
     */
    let newChecked = [...checked]
    if (isAllChecked) {
      newChecked = newChecked.filter(id => !rowIds.includes(id))
    } else {
      for (let row of rows) {
        if (!newChecked.includes(row.id)) {
          newChecked.push(row.id)
        }
      }
    }

    const checkedObjects = rows.filter(row => newChecked.indexOf(row.id) !== -1)

    this.props.onCheck(newChecked, checkedObjects)
  }

  _toggleFilterMenu = (filter: Filter | false) => {
    this.setState({
      showFilterMenu: filter,
    })
  }

  _toggleAddAdvancedFilterModal = show => {
    this.setState({
      showAddAdvancedFilterModal: show,
    })
  }

  _toggleShowColumnsManagerModal = show => {
    this.setState({
      showColumnsManagerModal: show,
    })
  }

  _toggleRow = (row: { id: Id }) => {
    const { checked, rows } = this.props

    const index = checked.indexOf(row.id)

    let newChecked
    if (index === -1) {
      newChecked = [...checked, row.id]
    } else {
      newChecked = [...checked]
      newChecked.splice(index, 1)
    }

    const checkedObjects = rows.filter(row => newChecked.indexOf(row.id) !== -1)

    this.props.onCheck(newChecked, checkedObjects)
  }
}

const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: '1px solid #eee',
}
const STYLE_TOP_LEFT_GRID = {
  backgroundColor: '#f9f9f9',
  borderBottom: '1px solid #eee',
  borderRight: '1px solid #eee',
  fontWeight: 'bold',
}
const STYLE_TOP_RIGHT_GRID = {
  backgroundColor: '#f9f9f9',
  borderBottom: '1px solid #eee',
  fontWeight: 'bold',
}

const HeaderColumn = styled.th.attrs({ className: 'zero-vertical-padding' })`
  position: initial;
  height: 0;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`

const Filters = styled.div`
  position: relative;
  z-index: 1;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 42px;
  padding-bottom: ${props => (props.showSubtotals ? '42px' : 0)};
  position: relative;
  overflow: auto;
  width: 100%;
`

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: fit-content;
  padding-left: 15px;
`

const FooterSubtotalsLabel = styled.div`
  position: absolute;
  left: 20px;
  bottom: 10px;
  font-weight: bold;
`

const ListControls = styled.div`
  display: flex;
`

const Cell = styled.div`
  align-items: center;
  background-color: ${({ isChecked }: { isChecked: boolean }) =>
    isChecked ? '#f8fbff' : 'transparent'};
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  display: flex;
`

const BodyCellContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: ${({ rightAlign = false }: { rightAlign?: boolean }) =>
    rightAlign ? 'flex-end' : 'normal'};
  height: 100%;
  padding: 0 6px;
  white-space: nowrap !important;

  a:not(.btn) {
    text-decoration: underline;
  }
`

const HeaderCellContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.rightAlign ? 'flex-end' : 'normal')};
`

const FixedHeaderCellWidthElement = styled.span`
  visibility: hidden;
  display: block;
  height: 0;
  white-space: nowrap !important;
`

const FixedHeaderCell = styled.div`
  background: ${({ sorted }: { sorted: boolean }) =>
    sorted ? '#efefef' : 'transparent'};
  cursor: ${({ sortable }: { sortable: boolean }) =>
    sortable ? 'pointer' : 'inherit'};
  font-weight: bold;
  position: absolute;
  top: 12px;
  padding: 0 6px;
  white-space: nowrap !important;
`

const SortIconAsc = styled.span.attrs({ className: 'fa fa-chevron-down' })`
  font-size: 10px;
  margin-left: 3px;
`

const SortIconDesc = styled.span.attrs({ className: 'fa fa-chevron-up' })`
  font-size: 10px;
  margin-left: 3px;
`

const FooterColumn = styled.th`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`

const FooterCellContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.rightAlign ? 'flex-end' : 'normal')};
`

const FixedFooterCell = styled.div`
  position: absolute;
  bottom: 12px;
  padding: 0 6px;
  white-space: nowrap !important;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

// Sometimes a new non-toggleable column is added, e.g. by Traede og in Rolecenter.
// So here we add it to the persisted columns.
const addNewNonToggleableColumns = (columns, columnOptions) => {
  const newColumns = [...columns]
  const columnsByKey = keyBy(newColumns, 'key')

  for (const group of columnOptions) {
    for (const options of group.columns) {
      if (options.toggleable === false && !columnsByKey[options.value]) {
        newColumns.push({
          key: options.value,
          sortOrder: options.sortOrder,
        })
      }
    }
  }

  return newColumns
}

const convertLegacyColumns = (columns, columnOptions) => {
  if (!columnOptions.length) {
    return columns
  }

  const usingLegacyFormat = columns && isString(columns[0])

  const allColumnOptionsSorted = sortBy(
    columnOptions.reduce((carry, group) => carry.concat(group.columns), []),
    'sortOrder'
  )

  let mappedAndSortedColumns = []
  if (usingLegacyFormat) {
    mappedAndSortedColumns = allColumnOptionsSorted.map(options => ({
      key: options.value,
      show: columns.includes(options.value),
      sortOrder: options.sortOrder,
    }))
  } else {
    // the user has a entry for this data table in their saved data table settings
    if (columns) {
      const legacyCompatibleColumns = [...columns]
      for (let [index, column] of legacyCompatibleColumns.entries()) {
        if (column.sortOrder === undefined) {
          legacyCompatibleColumns[index] = {
            ...column,
            sortOrder: index,
          }
        }
      }

      mappedAndSortedColumns = sortBy(legacyCompatibleColumns, 'sortOrder').map(
        (column, index) => ({
          ...column,
          sortOrder: index,
        })
      )
      const existingColumnKeys = legacyCompatibleColumns.map(c => c.key)

      // add column options to the columns array if they are not present
      const sortedColumnOptions = sortBy(allColumnOptionsSorted, 'sortOrder')
      let nextSortOrder = mappedAndSortedColumns.length
      for (let option of sortedColumnOptions) {
        if (!existingColumnKeys.includes(option.value)) {
          mappedAndSortedColumns.push({
            key: option.value,
            show: false,
            sortOrder: nextSortOrder,
          })

          nextSortOrder++
        }
      }

      // the user does not have a entry for this data table in their saved data table settings
      // so we need to create one from the column options. this is used to populate both the
      // data table itself, but also the columns manager
    } else {
      for (let [index, columnOption] of allColumnOptionsSorted.entries()) {
        mappedAndSortedColumns.push({
          key: columnOption.value,
          show: columnOption.default,
          sortOrder: index,
        })
      }
    }

    return mappedAndSortedColumns
  }
}
