/* @flow */

import React from 'react'
import { Link } from 'react-router-dom'

import { PermissionContext } from '../../../../infrastructure/components/Authorization'

import { SessionContext, SplitComponent } from '../../../shared'

type Props = {
  entityType: string,
}

const SettingsNavigation = (props: Props) => {
  const { entity, settings } = React.useContext(SessionContext)

  return (
    <div className="template-main-sidebar" style={{ borderRight: 0 }}>
      <nav className="template-main-navigation">
        <p className="menu-title">Personal Settings</p>
        <ul className="template-main-navigation-ul-handle">
          <li>
            <Link to="/settings/my" activeClassName="active">
              <i className="glyphicon glyphicon-cog" />{' '}
              <span className="title">Your Settings</span>
            </Link>
          </li>
          <li>
            <Link to="/settings/localization" activeClassName="active">
              <i className="glyphicon glyphicon-globe" />{' '}
              <span className="title">Localization</span>
            </Link>
          </li>
        </ul>

        {props.entityType === 'brand' && (
          <p className="menu-title">Company Settings</p>
        )}
        <ul className="template-main-navigation-ul-handle">
          <PermissionContext permission="settings:company:contact:read">
            <li>
              <Link to="/settings" activeClassName="active" onlyActiveOnIndex>
                <i className="glyphicon glyphicon-home" />{' '}
                <span className="title">Contact Information</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="users:read">
            <li>
              <Link to="/settings/users" activeClassName="active">
                <i className="glyphicon glyphicon-user" />{' '}
                <span className="title">Users</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="users:roles:read">
            <li>
              <Link to="/settings/roles" activeClassName="active">
                <i className="glyphicon glyphicon-lock" />{' '}
                <span className="title">User Roles</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="users:read">
            <li>
              <Link to="/settings/agencies" activeClassName="active">
                <i className="glyphicon glyphicon-certificate" />{' '}
                <span className="title">Agencies</span>
              </Link>
            </li>
          </PermissionContext>
          {props.entityType === 'brand' && (
            <li>
              <Link to="/settings/addresses">
                <i className="glyphicon glyphicon-envelope" />{' '}
                <span className="title">Addresses</span>
              </Link>
            </li>
          )}
          {/*<PermissionContext permission="workflows:read">
            <li>
              <Link to="/settings/workflows" activeClassName="active">
                <i className="glyphicon glyphicon-random" />{' '}
                <span className="title">Workflows</span>
              </Link>
            </li>
          </PermissionContext>*/}
        </ul>

        {props.entityType === 'brand' && (
          <p className="menu-title">Sales Settings</p>
        )}

        <ul className="template-main-navigation-ul-handle">
          <PermissionContext permission="settings:company:sales:read">
            <li>
              <Link to="/settings/sales" activeClassName="active">
                <i className="glyphicon glyphicon-usd" />{' '}
                <span className="title">Sales Settings</span>
              </Link>
            </li>
          </PermissionContext>
          <SplitComponent
            entityOrUser
            splitKey="order_channels"
            true={
              <PermissionContext permission="order_channels:read">
                <li>
                  <Link to="/settings/order-channels" activeClassName="active">
                    <i className="glyphicon glyphicon-usd" />{' '}
                    <span className="title">Order Channels</span>
                  </Link>
                </li>
              </PermissionContext>
            }
          />
          <PermissionContext permission="settings:company:sales:read">
            <li>
              <Link to="/settings/number-series" activeClassName="active">
                <i className="glyphicon glyphicon-usd" />{' '}
                <span className="title">Number Series</span>
              </Link>
            </li>
            <li>
              <Link to="/settings/country-settings" activeClassName="active">
                <i className="glyphicon glyphicon-map-marker" />{' '}
                <span className="title">Country Settings</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="payment_terms:read">
            <li>
              <Link to="/settings/payment-terms" activeClassName="active">
                <i className="glyphicon glyphicon-th-list" />{' '}
                <span className="title">Payment Terms</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="orders:claims:read">
            <li>
              <Link to="/settings/claims" activeClassName="active">
                <i className="glyphicon glyphicon-refresh" />{' '}
                <span className="title">Claims</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="return_portals:read">
            <li>
              <Link to="/settings/return-portals" activeClassName="active">
                <i className="glyphicon glyphicon-refresh" />{' '}
                <span className="title">Return portals</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="orders:cancellation_reasons:read">
            <li>
              <Link
                to="/settings/cancellation-reasons"
                activeClassName="active"
              >
                <i className="glyphicon glyphicon-question-sign" />{' '}
                <span className="title">Cancellation reasons</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="document_settings:read">
            <li>
              <Link to="/settings/documents" activeClassName="active">
                <i className="glyphicon glyphicon-file" />{' '}
                <span className="title">Documents</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="email_settings:read">
            <li>
              <Link to="/settings/emails" activeClassName="active">
                <i className="glyphicon glyphicon-send" />{' '}
                <span className="title">Emails</span>
              </Link>
            </li>
          </PermissionContext>
        </ul>

        {props.entityType === 'brand' && (
          <>
            <p className="menu-title">Accounting Settings</p>
            <ul className="template-main-navigation-ul-handle">
              <PermissionContext permission="accounting">
                <li>
                  <Link to="/settings/accounting" activeClassName="active">
                    <i className="glyphicon glyphicon-cog" />{' '}
                    <span className="title">Accounting settings</span>
                  </Link>
                </li>
              </PermissionContext>
              <PermissionContext permission="accounting:years:read">
                <li>
                  <Link
                    to="/settings/accounting-years"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-calendar" />{' '}
                    <span className="title">Accounting years</span>
                  </Link>
                </li>
              </PermissionContext>
              <PermissionContext permission="internal_companies:read">
                <li>
                  <Link
                    to="/settings/internal-companies"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-home" />{' '}
                    <span className="title">Internal companies</span>
                  </Link>
                </li>
              </PermissionContext>
              {/*<PermissionContext permission="external_document_exchanges:read">
                <li>
                  <Link
                    to="/settings/external-document-exchanges"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-resize-small" />{' '}
                    <span className="title">External document exchanges</span>
                  </Link>
                </li>
              </PermissionContext>*/}
              <PermissionContext permission="product_accounting_groups:read">
                <li>
                  <Link
                    to="/settings/product-accounting-groups"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-folder-open" />{' '}
                    <span className="title">Product accounting groups</span>
                  </Link>
                </li>
              </PermissionContext>
            </ul>

            <p className="menu-title">Webshop Settings</p>

            <ul className="template-main-navigation-ul-handle">
              <PermissionContext permission="webshop:settings">
                <li>
                  <Link to="/settings/webshop" activeClassName="active">
                    <i className="glyphicon glyphicon-wrench" />{' '}
                    <span className="title">Webshop Settings</span>
                  </Link>
                </li>
                <li>
                  <Link to="/settings/webshop/design" activeClassName="active">
                    <i className="glyphicon glyphicon-picture" />{' '}
                    <span className="title">Webshop Design</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/settings/webshop/navigation"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-link" />{' '}
                    <span className="title">Webshop Navigation</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/settings/webshop-product-labels"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-paperclip" />{' '}
                    <span className="title">Webshop Product Labels</span>
                  </Link>
                </li>
              </PermissionContext>
              <PermissionContext permission="settings:company:pages:read">
                <li>
                  <Link to="/settings/pages" activeClassName="active">
                    <i className="glyphicon glyphicon-book" />{' '}
                    <span className="title">Webshop Pages</span>
                  </Link>
                </li>
              </PermissionContext>
            </ul>
          </>
        )}

        {props.entityType === 'brand' && (
          <p className="menu-title">Inventory Settings</p>
        )}

        <ul className="template-main-navigation-ul-handle">
          <PermissionContext permission="settings:company:inventory:read">
            <li>
              <Link to="/settings/inventory-locations" activeClassName="active">
                <i className="glyphicon glyphicon-list-alt" />{' '}
                <span className="title">Inventory Locations</span>
              </Link>
            </li>
          </PermissionContext>
          {props.entityType === 'brand' &&
            settings.stock_settings.use_batch_control && (
              <PermissionContext permission="settings:company:inventory:read">
                <li>
                  <Link
                    to="/settings/inventory-batches"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-list-alt" />{' '}
                    <span className="title">Inventory Batches</span>
                  </Link>
                </li>
              </PermissionContext>
            )}
          <PermissionContext permission="settings:company:inventory:read">
            <li>
              <Link to="/settings/stock" activeClassName="active">
                <i className="glyphicon glyphicon-list-alt" />{' '}
                <span className="title">Inventory Settings</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="replenishment_settings:read">
            <li>
              <Link to="/settings/replenishment" activeClassName="active">
                <i className="glyphicon glyphicon-refresh" />{' '}
                <span className="title">Replenishment settings</span>
              </Link>
            </li>
          </PermissionContext>
        </ul>

        {props.entityType === 'brand' && (
          <>
            <p className="menu-title">Warehouse Management Settings</p>

            <ul className="template-main-navigation-ul-handle">
              <li>
                <Link
                  to="/settings/warehouse-management"
                  activeClassName="active"
                >
                  <i className="glyphicon glyphicon-home" />{' '}
                  <span className="title">Warehouse Management</span>
                </Link>
              </li>
              <PermissionContext permission="warehousing:colli_types:read">
                <li>
                  <Link to="/settings/colli-types" activeClassName="active">
                    <i className="fa fa-truck" />{' '}
                    <span className="title">Colli types</span>
                  </Link>
                </li>
              </PermissionContext>
              <PermissionContext permission="warehousing:shipping_code_conversions:read">
                <li>
                  <Link
                    to="/settings/shipping-code-conversions"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-share-alt" />{' '}
                    <span className="title">Shipping code conversions</span>
                  </Link>
                </li>
              </PermissionContext>
              <PermissionContext permission="warehousing:adjustment_codes:read">
                <li>
                  <Link
                    to="/settings/warehouse-adjustment-codes"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-pencil" />{' '}
                    <span className="title">Warehouse adjustment codes</span>
                  </Link>
                </li>
              </PermissionContext>
              <PermissionContext permission="warehousing:locations:read">
                <li>
                  <Link
                    to="/settings/warehouse-locations"
                    activeClassName="active"
                  >
                    <i className="glyphicon glyphicon-tag" />{' '}
                    <span className="title">Warehouse locations</span>
                  </Link>
                </li>
              </PermissionContext>
              <SplitComponent
                entityOrUser
                splitKey="traede_printer_client"
                true={
                  <PermissionContext permission="printers:read">
                    <li>
                      <Link to="/settings/printers" activeClassName="active">
                        <i className="glyphicon glyphicon-print" />{' '}
                        <span className="title">Printers</span>
                      </Link>
                    </li>
                  </PermissionContext>
                }
              />
            </ul>
          </>
        )}

        {props.entityType === 'brand' && (
          <p className="menu-title">Master data Settings</p>
        )}

        <ul className="template-main-navigation-ul-handle">
          <PermissionContext permission="products:prices:write">
            <li>
              <Link to="/settings/price-groups" activeClassName="active">
                <i className="glyphicon glyphicon-usd" />{' '}
                <span className="title">Price groups</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="products:basic:write">
            <li>
              <Link to="/settings/tags" activeClassName="active">
                <i className="glyphicon glyphicon-tag" />{' '}
                <span className="title">Product tags</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="settings:company:file_tags:read">
            <li>
              <Link to="/settings/file-tags" activeClassName="active">
                <i className="glyphicon glyphicon-tag" />{' '}
                <span className="title">File tags</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="settings:company:misc:read">
            <li>
              <Link to="/settings/misc" activeClassName="active">
                <i className="glyphicon glyphicon-star" />{' '}
                <span className="title">Miscellaneous</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="products:basic:write">
            <li>
              <Link to="/settings/product-tables" activeClassName="active">
                <i className="glyphicon glyphicon-tag" />{' '}
                <span className="title">Product tables</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="products:basic:write">
            <li>
              <Link to="/settings/attributes" activeClassName="active">
                <i className="glyphicon glyphicon-tag" />{' '}
                <span className="title">Attributes</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="products:basic:write">
            <li>
              <Link to="/settings/product-locales" activeClassName="active">
                <i className="glyphicon glyphicon-globe" />{' '}
                <span className="title">Product locales</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="settings:company:sales:write">
            <li>
              <Link to="/settings/images" activeClassName="active">
                <i className="glyphicon glyphicon-picture" />{' '}
                <span className="title">Images</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="bank_accounts:read">
            <li>
              <Link to="/settings/bank-accounts" activeClassName="active">
                <i className="glyphicon glyphicon-usd" />{' '}
                <span className="title">Bank Accounts</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="forwarders:read">
            <li>
              <Link to="/settings/forwarders" activeClassName="active">
                <i className="fa fa-truck" />{' '}
                <span className="title">Forwarders</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="mode_of_transportations:read">
            <li>
              <Link
                to="/settings/mode-of-transportations"
                activeClassName="active"
              >
                <i className="glyphicon glyphicon-globe" />{' '}
                <span className="title">Mode of transportations</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="settings:company:exchangerates:read">
            <li>
              <Link to="/settings/exchange-rates" activeClassName="active">
                <i className="glyphicon glyphicon-transfer" />{' '}
                <span className="title">Exchange Rates</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="custom_fields:read">
            <li>
              <Link to="/settings/custom-fields" activeClassName="active">
                <i className="glyphicon glyphicon-list" />{' '}
                <span className="title">Custom Fields</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="settings:company:fonts:read">
            <li>
              <Link to="/settings/fonts" activeClassName="active">
                <i className="glyphicon glyphicon-pencil" />{' '}
                <span className="title">Fonts</span>
              </Link>
            </li>
          </PermissionContext>
          <PermissionContext permission="settings:company:outbound_webhooks:read">
            <li>
              <Link to="/settings/outbound-webhooks" activeClassName="active">
                <i className="glyphicon glyphicon-flash" />{' '}
                <span className="title">Outbound webhooks</span>
              </Link>
            </li>
          </PermissionContext>
        </ul>
      </nav>
    </div>
  )
}

export default SettingsNavigation
