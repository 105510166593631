/* @flow */

import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

import { renderDate, renderDateTime } from '../../app/shared'

type Props = {
  comparisonIsInPast?: boolean,
  comparisonDate?: string,
  date: ?string,
  colored?: boolean,
  colorShortIsBad?: boolean,
  shortDate?: boolean,
  showDaysUntil?: boolean,
  time?: boolean,
}

const RelativeDate = ({
  comparisonIsInPast = false,
  comparisonDate,
  date,
  colored = false,
  colorShortIsBad = true,
  shortDate = false,
  showNegativeDates = false,
  showDaysUntil = true,
  time = false,
  ...props
}: Props) => {
  const { daysUntil, daysUntilColor, displayValue, suffix } =
    React.useMemo(() => {
      if (!date) {
        return {}
      }

      const now = comparisonDate ? moment(comparisonDate) : moment()

      const daysUntil = moment(date).diff(now, 'days')

      let displayValue = daysUntil
      let suffix = 'days'
      if (time && daysUntil === 0) {
        displayValue = moment(date).diff(now, 'hours')
        suffix = 'hours'

        if (displayValue === 0) {
          displayValue = moment(date).diff(now, 'minutes')
          suffix = 'minutes'
        }
      }

      let daysUntilColor
      if (daysUntil < 5) {
        daysUntilColor = 'red'
      } else if (daysUntil < 10) {
        daysUntilColor = 'orange'
      }

      return { daysUntil, daysUntilColor, displayValue, suffix }
    }, [date, time, comparisonIsInPast, comparisonDate])

  const daysStyle = colored
    ? {
        color: daysUntilColor,
      }
    : {}

  return !date ? null : (
    <span {...props}>
      {time
        ? renderDateTime(date)
        : renderDate(date, shortDate === false ? 'short' : 'day_month')}{' '}
      {showDaysUntil && (
        <span style={daysStyle}>
          {(displayValue > 0 || showNegativeDates) &&
            `(${displayValue} ${suffix})`}
        </span>
      )}
    </span>
  )
}

export default RelativeDate
