/* @flow */

import * as React from 'react'

const colors = ['success', 'green', 'warning', 'important']

const ColoredLabel = ({ className = '', children, index, ...rest }) => {
  const classes = ['label']

  const color = colors[index] === undefined ? 'default' : colors[index]

  classes.push(`label-${color}`)

  return (
    <span className={classes.concat(className.split(' ')).join(' ')} {...rest}>
      {children}
    </span>
  )
}

export default ColoredLabel
