/* @flow */

import api from '../api'

import type {
  ApiResponse,
  BrunoRequestOptions,
  CreditNote,
  DataTableAttribute,
  DataTableRequestOptions,
  Drop,
  Filter,
  Id,
  Invoice,
  Order,
  OrderCancellationReason,
  OrderClaimCause,
  OrderClaim,
  OrderChangeProposalGroup,
  OrderChangeProposal,
  OrderElastic,
  OrderGroup,
  Prepayment,
  PrepaymentPayment,
  SortDirection,
  Tag,
} from '../types'

export const getOrders = (
  options?: BrunoRequestOptions
): Promise<ApiResponse<{ orders: Array<Order> }>> =>
  api.get('/orders', {
    query: options,
  })

export const getOrderInvoices = (
  orderId
): Promise<ApiResponse<{ orders: Array<Order> }>> =>
  api.get(`/orders/${orderId}/invoices`)

export const getOrdersList = (
  options?: DataTableRequestOptions
): Promise<ApiResponse<{ orders: Array<OrderElastic>, total: number }>> =>
  api.get('/orders/list', {
    query: options,
  })

export const getOrdersAttributes = (): Promise<
  ApiResponse<{ attributes: Array<DataTableAttribute> }>
> => api.get('/orders/table-parameters')

export const updateOrdersBulk = (orders: Array<Id>, data) =>
  api.put('/orders', {
    body: {
      orders,
      data,
    },
  })

export const updateOrderAddress = (orderId, type, address) =>
  api.put(`/orders/${orderId}/shop-or-delivery`, {
    body: {
      type,
      data: address,
    },
  })

export const getPrepaymentsList = (
  options?: DataTableRequestOptions
): Promise<ApiResponse<{ orders: Array<OrderElastic>, total: number }>> =>
  api.get('/orders/prepayments', {
    query: options,
  })

export const getPrepaymentsAttributes = (): Promise<
  ApiResponse<{ attributes: Array<DataTableAttribute> }>
> => api.get('/orders/prepayments/attributes')

export const getBrandList = () => api.get('/brands/list')

export const getRetailerOrdersList = (
  options?: DataTableRequestOptions
): Promise<ApiResponse<{ orders: Array<OrderElastic>, total: number }>> =>
  api.get('/brands/orders', {
    query: options,
  })

export const exportRetailerOrder = (orderId: Id) =>
  api.post(`/brands/orders/${orderId}/export-excel`)

export const exportRetailerOrders = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  includeLines: boolean,
  orderPerFile: boolean
) =>
  api.post(`/brands/orders/export`, {
    body: {
      columns,
      filters,
      include_lines: includeLines,
      order_per_file: orderPerFile,
      sort,
    },
  })

// orders

export const getOrder = (id: Id, options?: BrunoRequestOptions) =>
  api.get(`/orders/${id}`, {
    query: options,
  })

export const getOrderLines = (id: Id, options?: BrunoRequestOptions) =>
  api.get(`/orders/${id}/lines`, {
    query: options,
  })

export const getOrderLinesWithDeliveryStatus = (
  id: Id,
  productIds: Array<Id>
) =>
  api.get(`/orders/${id}/lines-with-delivery-status`, {
    query: {
      product_ids: productIds,
    },
  })

export const getOrderEdiActions = (id: Id) =>
  api.get(`/orders/${id}/edi-actions`)

export const getOrderUiView = (id: Id) => api.get(`/orders/${id}/ui-view`)

export const getOrderComments = (id: Id) => api.get(`/orders/${id}/comments`)

export const archiveOrders = (orderIds: Id[]): Promise<ApiResponse<void>> =>
  api.post('/orders/archive', {
    body: {
      orders: orderIds,
    },
  })

export const unarchiveOrders = (orderIds: Id[]): Promise<ApiResponse<void>> =>
  api.post('/orders/unarchive', {
    body: {
      orders: orderIds,
    },
  })

export const resendOrderConfirmation = (
  orderId: Id,
  emails: Array<{ email: string, name?: string }>,
  message: string
): Promise<ApiResponse<void>> =>
  api.post(`/orders/${orderId}/resend-order-confirmation`, {
    body: {
      emails,
      message,
    },
  })

export const exportOrders = (
  columns: Array<string>,
  filterGroups: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  includeLines: boolean,
  orderPerFile: boolean,
  options?: Object
) =>
  api.post(`/orders/export`, {
    body: {
      columns,
      filter_groups: filterGroups,
      include_lines: includeLines,
      order_per_file: orderPerFile,
      sort,
      options,
    },
  })

export const getUnpaidCreditCardPrepayment = (orderId: Id) =>
  api.get(`/orders/${orderId}/unpaid-credit-card-prepayment`)

export const sendUnpaidCreditCardPrepaymentPaymentLink = (
  orderId,
  recipients
) =>
  api.post(
    `/orders/${orderId}/send-unpaid-credit-card-prepayment-payment-link`,
    {
      body: {
        recipients,
      },
    }
  )

export const updateOrderPrices = (orderIds, productIds) =>
  api.post('/orders/update-prices', {
    body: {
      order_ids: orderIds,
      product_ids: productIds,
    },
  })

export const calculateOrderIncomingAvailableDistribution = (orderId, body) =>
  api.post(`/orders/${orderId}/calculate-incoming-available`, {
    body: body,
  })

export const persistIncomingDistribution = orderLineDeliveries =>
  api.post('/orders/persist-incoming-available', {
    body: {
      order_line_deliveries: orderLineDeliveries,
    },
  })

export const cancelOrder = (
  id: Id,
  regulateInventory: string,
  cancellationReasonId: Id | null
) =>
  api.post(`/orders/${id}/cancel`, {
    body: {
      regulate_inventory: regulateInventory,
      cancellation_reason_id: cancellationReasonId,
    },
  })

export const reopenOrder = (id: Id) => api.post(`/orders/${id}/reopen`)

export const deleteOrder = (id: Id) => api.delete(`/orders/${id}`)

export const exportOrderToOrderSheet = (id: Id) =>
  api.post(`/orders/${id}/export-order-sheet`)

export const updateOrderFromOrderSheet = (id, file) =>
  api.post(`/orders/${id}/update-from-order-sheet`, {
    body: {
      file,
    },
  })

// order groups

export const createOrderGroup = (data: $Shape<OrderGroup>) =>
  api.post('/orders-v2', {
    body: {
      order_group: data,
    },
  })

export const createOrder = (data, source, webshopSessionId) =>
  api.post('/orders', {
    body: {
      order: data,
      source: source,
      webshop_session_id: webshopSessionId,
    },
  })

export const getOrderGroup = (id: Id, options?: BrunoRequestOptions) =>
  api.get(`/orders-v2/${id}`, {
    query: options,
  })

export const updateOrderGroup = (id: Id, data: Object) =>
  api.put(`/orders-v2/${id}`, {
    body: {
      order_group: data,
    },
  })

export const approveOrderGroup = (id: Id, emailData: Object) =>
  api.post(`/orders-v2/${id}/approve`, {
    body: {
      email: emailData,
    },
  })

export const saveAsSelection = (id: Id, emailData: Object) =>
  api.post(`/orders-v2/${id}/save-as-selection`, {
    body: {
      email: emailData,
    },
  })

export const sendOrderGroupConfirmation = (
  id: Id,
  orderId: Id,
  emailData: Object
) =>
  api.post(`/orders-v2/${id}/send-confirmation`, {
    body: {
      email: emailData,
      order_id: orderId,
    },
  })

export const persistMultiOrders = (
  orderGroupId: Id,
  data: Object,
  source: string,
  webshopSessionId: ?Id
) =>
  api.post(`/orders-v2/${orderGroupId}/persist-multi-orders`, {
    body: {
      data,
      source,
      webshop_session_id: webshopSessionId,
    },
  })

export const deleteOrderGroup = (orderGroupId: Id) =>
  api.delete(`/order-groups/${orderGroupId}`)

export const getOrderPrices = (
  orderId: Id,
  productIds: Array<Id>,
  configuredVariants
) =>
  api.post(`/orders/${orderId}/prices`, {
    body: {
      product_ids: productIds,
      configured_variants: configuredVariants,
    },
  })

export const getOrderDeliveryStatus = (
  orderId: Id,
  productIds: Array<Id> = [],
  variantIds: Array<Id> = []
) =>
  api.post(`/orders/${orderId}/delivery-status`, {
    body: {
      product_ids: productIds,
      variant_ids: variantIds,
    },
  })

export const moveOrders = (targetOrderId: Id, move: Array<Object>) =>
  api.post('/orders/move', {
    body: {
      target_order_id: targetOrderId,
      move,
    },
  })

export const createOrdersBulk = orders =>
  api.post(`/orders-v2/bulk`, {
    body: {
      orders,
    },
  })

export const createOrderDataFromQuickOrder = (values: Object) =>
  api.post(`/orders/create-order-data-from-quick-order`, {
    body: values,
  })

export const scanOrderBarcodes = (id: Id, scans) =>
  api.post(`/orders-v2/${id}/scan-barcode`, {
    body: {
      scans: scans,
    },
  })

export const convertToConsignmentMoveOrder = (
  id: Id,
  moveTo: boolean,
  otherLocationId
) =>
  api.post(`/orders/${id}/convert-to-move`, {
    body: {
      move_to: moveTo,
      other_location_id: otherLocationId,
    },
  })

export const changeOrderInventoryLocation = (
  orderId: Id,
  values: { change_inventory_location_id: Id }
) =>
  api.post(`/orders/${orderId}/change-inventory-location`, {
    body: values,
  })

export const splitOrder = (orderId: Id, lines) =>
  api.post(`/orders/${orderId}/split`, {
    body: {
      lines,
    },
  })

export const skipOrderChecks = ids =>
  api.post(`/orders/skip-order-checks`, {
    body: {
      order_ids: ids,
    },
  })

export const checkOrder = (id, data) =>
  api.post(`/orders/${id}/check-order`, {
    body: {
      order: data,
    },
  })

export const attemptTranslateOrder = (service, address) =>
  api.post('/orders/translate-address', {
    body: {
      service,
      address,
    },
  })

export const createOrderOverview = (axiosOptions = {}, customerId, overview) =>
  api.post(`/orders/overviews`, {
    body: {
      customer_id: customerId,
      overview,
    },
    ...axiosOptions,
  })

export const sendOrderOverview = (orderOverviewId, data) =>
  api.post(`/orders/overviews/${orderOverviewId}/send`, {
    body: {
      overview: data,
    },
  })

export const getOrderOverviewForDocument = (
  axiosOptions = {},
  orderOverviewId: Id
) =>
  api.get(`/orders/overviews/${orderOverviewId}/for-document`, {
    ...axiosOptions,
  })

// e-conomic

export const debugEconomicOrder = (orderId: Id) =>
  api.post(`/economic/orders/${orderId}/debug`)

export const getEconomicOrderStatusesBulk = (orders: Array<Id>) =>
  api.post(`/economic/orders/statuses`, {
    body: {
      order_ids: orders,
    },
  })

// documents

export const getLatestDocumentType = (
  type: string,
  key: string,
  value: string
) =>
  api.get('/documents/latest-type', {
    query: {
      type: type,
      key: key,
      value: value,
    },
  })

// order change proposals

export const createOrderChangeProposalGroup = (
  data: $Shape<OrderChangeProposalGroup>
) =>
  api.post('/orders/change-proposal-groups', {
    body: {
      order_change_proposal_group: data,
    },
  })

export const getOrderChangeProposalGroupCustomerPriorities = (
  orderChangeProposalGroupId: Id,
  context: ?string,
  contextId: Id
) =>
  api.get(
    `/orders/change-proposal-groups/${orderChangeProposalGroupId}/customer-priorities`,
    {
      query: {
        context,
        context_id: contextId,
      },
    }
  )

export const updateOrderChangeProposalGroup = (
  orderChangeProposalGroupId: Id,
  data: $Shape<OrderChangeProposalGroup>
) =>
  api.put(`/orders/change-proposal-groups/${orderChangeProposalGroupId}`, {
    body: {
      order_change_proposal_group: data,
    },
  })

export const updateOrderChangeProposalGroupPriorities = (
  orderChangeProposalGroupId: Id,
  data: Array<{
    customer_id: Id,
    priority: ?number,
    order_id: ?number,
    product_id: ?number,
  }>
) =>
  api.put(
    `/orders/change-proposal-groups/${orderChangeProposalGroupId}/customer-priorities`,
    {
      body: {
        priorities: data,
      },
    }
  )

export const getOrderChangeProposal = (id: Id, options?: Options) =>
  api.get(`/orders/change-proposals/${id}`, {
    query: options,
  })

export const generateOrderChangeProposals = (orderChangeProposalGroupId: Id) =>
  api.post(
    `/orders/change-proposal-groups/${orderChangeProposalGroupId}/generate-proposals`
  )

export const getOrderChangeProposalGroups = () =>
  api.get('/orders/change-proposal-groups')

export const getOrderChangeProposalGroup = (orderChangeProposalGroupId: Id) =>
  api.get(`/orders/change-proposal-groups/${orderChangeProposalGroupId}`)

export const getOrderChangeProposalGroupStatus = (
  orderChangeProposalGroupId: Id
) =>
  api.get(`/orders/change-proposal-groups/${orderChangeProposalGroupId}/status`)

export const applyOrderChangeProposal = (orderChangeProposalId: Id) =>
  api.post(`/orders/change-proposals/${orderChangeProposalId}/apply`)

export const updateOrderChangeProposal = (orderChangeProposalId: Id, data) =>
  api.put(`/orders/change-proposals/${orderChangeProposalId}`, {
    body: {
      order_change_proposal: data,
    },
  })

// consolidated invoices
export const getConsolidatedInvoice = (
  consolidatedInvoiceId: Id,
  options?: DataTableRequestOptions
) =>
  api.get(`/consolidated-invoices/${consolidatedInvoiceId}`, {
    query: options,
  })

export const getConsolidatedInvoiceList = (options?: DataTableRequestOptions) =>
  api.get('/consolidated-invoices', {
    query: options,
  })

export const updateConsolidatedInvoice = (consolidatedInvoiceId: Id, data) =>
  api.put(`/consolidated-invoices/${consolidatedInvoiceId}`, {
    body: {
      consolidated_invoice: data,
    },
  })

export const moveInvoicesToNewConsolidatedInvoice = (
  orderInvoiceIds: Array,
  data
) =>
  api.post(`/consolidated-invoices/move-to-new`, {
    body: {
      order_invoice_ids: orderInvoiceIds,
    },
  })

export const removeInvoiceFromConsolidatedInvoice = (
  consolidatedInvoiceId: Id,
  orderInvoiceIds: Id
) =>
  api.post(`/consolidated-invoices/${consolidatedInvoiceId}/remove-invoice`, {
    body: {
      invoice_ids: orderInvoiceIds,
    },
  })

export const deleteConsolidatedInvoice = (id: Id) =>
  api.delete(`/consolidated-invoices/${id}`)

export const exportConsolidatedInvoiceToExcel = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  includeInvoices: boolean,
  includeLines: boolean
) =>
  api.post(`/consolidated-invoices/export-excel`, {
    body: {
      columns,
      filters,
      include_invoices: includeInvoices,
      include_lines: includeLines,
      sort,
    },
  })

export const exportConsolidatedInvoiceToVoecCsv = (id: Id) =>
  api.post(`/consolidated-invoices/${id}/export-voec-csv`)

// invoices

export const getInvoiceList = (options?: DataTableRequestOptions) =>
  api.get('/invoices', {
    query: options,
  })

export const getInvoiceAttributes = () => api.get('/invoices/attributes')

export const getRetailerInvoicesList = (
  options?: DataTableRequestOptions
): Promise<ApiResponse<{ invoices: Array<OrderElastic>, total: number }>> =>
  api.get('/brands/invoices', {
    query: options,
  })

export const getInvoice = (invoiceId: Id, options?: BrunoRequestOptions) =>
  api.get(`/invoices/${invoiceId}`, {
    query: options,
  })

export const getInvoices = (options?: BrunoRequestOptions) =>
  api.get(`/invoices-v2`, {
    query: options,
  })

export const getNextInvoiceNumber = () =>
  api.get('/orders/invoices/next-invoice-number')

type InvoiceApiBody = $Shape<{|
  ...Invoice,
  lines?: Array<{| id?: Id, quantity: number, delete?: boolean |}>,
|}>

export const createInvoice = (data: InvoiceApiBody) =>
  api.post(`/invoices`, {
    body: {
      invoice: data,
    },
  })

export const createInvoicesBulk = invoices =>
  api.post(`/invoice-list`, {
    body: {
      invoices,
    },
  })

export const createDataFromQuickImport = (values: Object) =>
  api.post(`/invoice-list/create-data-from-quick-import`, {
    body: values,
  })

export const updateInvoice = (invoiceId: Id, data: InvoiceApiBody) =>
  api.put(`/invoices/${invoiceId}`, {
    body: {
      invoice: data,
    },
  })

export const updateInvoicesBulk = (invoices: Array<Id>, data: InvoiceApiBody) =>
  api.put('/invoice-list', {
    body: {
      invoices,
      data,
    },
  })

export const deleteInvoice = (id: Id) => api.delete(`/invoices/${id}`)

export const deleteInvoicesBulk = (invoices: Array<{ id: Id }>) =>
  api.delete('/invoice-list', {
    body: {
      invoices,
    },
  })

export const bookInvoice = (
  invoiceId: Id,
  emailData: Object,
  invoiceData?: Object
) =>
  api.post(`/invoices/${invoiceId}/book`, {
    body: {
      invoice: invoiceData,
      email: emailData,
    },
  })

export const sendInvoice = (invoiceId: Id, emailData: Object) =>
  api.post(`/invoices/${invoiceId}/send-confirmation`, {
    body: {
      email: emailData,
    },
  })

export const sendInvoiceReminder = (invoiceId: Id, emailData: Object) =>
  api.post(`/invoices/${invoiceId}/send-reminder`, {
    body: {
      email: emailData,
    },
  })

export const sendInvoiceReminderBulk = (invoiceIds: Id) =>
  api.post(`/invoices/send-reminders`, {
    body: {
      invoice_ids: invoiceIds,
    },
  })

export const exportInvoices = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  includeLines: boolean,
  invoicePerFile: boolean,
  options?: Object
) =>
  api.post(`/invoices/export`, {
    body: {
      columns,
      filters,
      include_lines: includeLines,
      invoice_per_file: invoicePerFile,
      sort,
      options,
    },
  })

export const debugEdiSales = (connection: string, values) =>
  api.post(`/edi/sales/debug`, {
    body: {
      connection,
      ...values,
    },
  })

export const exportInvoiceEdi = (invoiceId: Id, connection: string) =>
  api.post(`/edi/invoices/export`, {
    body: {
      connection,
      invoice_id: invoiceId,
    },
  })

export const copyAndFlipInvoice = (invoiceType, invoiceId) =>
  api.post(`/invoices/copy-and-flip`, {
    body: {
      invoice_type: invoiceType,
      invoice_id: invoiceId,
    },
  })

export const mergeInvoices = (targetInvoiceId, merge) =>
  api.post('/invoices/merge', {
    body: {
      target_invoice_id: targetInvoiceId,
      merge,
    },
  })

// credit notes

export const getCreditNote = (
  creditNoteId: Id,
  options?: BrunoRequestOptions
) =>
  api.get(`/credit-notes/${creditNoteId}`, {
    query: options,
  })

type CreditNoteApiBody = $Shape<{|
  ...CreditNote,
  lines?: Array<{| id?: Id, quantity: number, delete?: boolean |}>,
|}>

export const getCreditNotes = (options?: BrunoRequestOptions) =>
  api.get('/credit-notes', {
    query: options,
  })

export const createCreditNote = (data: CreditNoteApiBody) =>
  api.post(`/credit-notes`, {
    body: {
      credit_note: data,
    },
  })

export const updateCreditNote = (creditNoteId: Id, data: CreditNoteApiBody) =>
  api.put(`/credit-notes/${creditNoteId}`, {
    body: {
      credit_note: data,
    },
  })

export const bookCreditNote = (
  creditNoteId: Id,
  emailData: Object,
  creditNoteData: Object
) =>
  api.post(`/credit-notes/${creditNoteId}/book`, {
    body: {
      email: emailData,
      credit_note: creditNoteData,
    },
  })

export const sendCreditNote = (creditNoteId: Id, emailData: Object) =>
  api.post(`/credit-notes/${creditNoteId}/send-confirmation`, {
    body: {
      email: emailData,
    },
  })

export const deleteCreditNote = (id: Id) => api.delete(`/credit-notes/${id}`)

// drops

export const getDrops = (
  options
): Promise<ApiResponse<{ drops: Array<Drop> }>> =>
  api.get('/drops', {
    query: options,
  })

// tags

export const getTags = (options): Promise<ApiResponse<{ tags: Array<Tag> }>> =>
  api.get('/tags', {
    query: options,
  })

// claims

export const getClaims = (): Promise<
  ApiResponse<{ claims: Array<OrderClaim> }>
> => api.get('/claims')

export const getClaimById = (
  id: Id
): Promise<ApiResponse<{ claim: OrderClaim }>> => api.get(`/claims/${id}`)

export const createClaim = (
  claim: $Shape<OrderClaim>
): Promise<ApiResponse<OrderClaim>> =>
  api.post('/claims', {
    body: {
      claim: claim,
    },
  })

export const updateClaim = (
  id: Id,
  claim: $Shape<OrderClaim>
): Promise<ApiResponse<OrderClaim>> =>
  api.put(`/claims/${id}`, {
    body: {
      claim: claim,
    },
  })

export const deleteClaim = (id: Id): Promise<ApiResponse<null>> =>
  api.delete(`/claims/${id}`)

export const getClaimCauses = (): Promise<
  ApiResponse<{ claim_causes: Array<OrderClaimCause> }>
> => api.get('/claims/causes')

export const createClaimCause = (
  claimCause: $Shape<OrderClaimCause>
): Promise<ApiResponse<OrderClaimCause>> =>
  api.post('/claims/causes', {
    body: {
      claim_cause: claimCause,
    },
  })

export const updateClaimCause = (
  id: Id,
  claimCause: $Shape<OrderClaimCause>
): Promise<ApiResponse<OrderClaimCause>> =>
  api.put(`/claims/causes/${id}`, {
    body: {
      claim_cause: claimCause,
    },
  })

export const deleteClaimCause = (id: Id): Promise<ApiResponse<null>> =>
  api.delete(`/claims/causes/${id}`)

// prepayments

export const getPrepaymentList = (options?: DataTableRequestOptions) =>
  api.get('/orders/prepayments', {
    query: options,
  })

export const getPrepaymentAttributes = () =>
  api.get('/orders/prepayments/attributes')

export const getPrepayment = (
  prepaymentId: Id,
  options?: BrunoRequestOptions
) =>
  api.get(`/orders/prepayments/${prepaymentId}`, {
    query: options,
  })

export const createPrepayment = (prepayment: $Shape<Prepayment>) =>
  api.post(`/orders/prepayments`, {
    body: {
      prepayment,
    },
  })

export const updatePrepayment = (
  prepaymentId: Id,
  prepayment: $Shape<Prepayment>
) =>
  api.put(`/orders/prepayments/${prepaymentId}`, {
    body: {
      prepayment,
    },
  })

export const deletePrepayment = (prepaymentId: Id) =>
  api.delete(`/orders/prepayments/${prepaymentId}`)

export const deletePrepaymentPayment = (prepaymentPaymentId: Id) =>
  api.delete(`/orders/prepayments/payment/${prepaymentPaymentId}`)

export const registerPrepaymentPayment = (
  prepaymentId: Id,
  payment: $Shape<PrepaymentPayment>
) =>
  api.post(`/orders/prepayments/${prepaymentId}/payments`, {
    body: {
      payment,
    },
  })

export const bookPrepayment = (prepaymentId: Id, emailData: Object) =>
  api.post(`/orders/prepayments/${prepaymentId}/book`, {
    body: {
      email: emailData,
    },
  })

export const sendPrepayment = (prepaymentId: Id, emailData: Object) =>
  api.post(`/orders/prepayments/${prepaymentId}/send-confirmation`, {
    body: {
      email: emailData,
    },
  })

export const exportPrepayments = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  prepaymentPerFile: boolean
) =>
  api.post('/orders/prepayments/export', {
    body: {
      columns,
      filters,
      prepayment_per_file: prepaymentPerFile,
      sort,
    },
  })

// prepayments pay

export const getPrepaymentPayStatus = (prepaymentId: Id, token: string) =>
  api.get(`/orders/prepayment-pay/${prepaymentId}/${token}/status`)

export const acceptPrepaymentPay = (
  prepaymentId: Id,
  token: string,
  data: Object
) =>
  api.post(`/orders/prepayment-pay/${prepaymentId}/${token}/accept`, {
    body: {
      data,
    },
  })

export const sendPrepaymentPayPaymentLink = (
  prepaymentId: Id,
  emailData: Object
) =>
  api.post(`/orders/prepayments-pay/${prepaymentId}/send-payment-link`, {
    body: {
      email: emailData,
    },
  })

// eAccounting

export const pushOrderToEaccounting = (orderId: Id) =>
  api.post(`/eaccounting/orders/${orderId}/push`)

// cancellation reasons

export const getCancellationReasons = (): Promise<
  ApiResponse<Array<OrderCancellationReason>>
> => api.get('/orders/cancellation-reasons')

export const createCancellationReason = (
  cancellationReason: $Shape<OrderCancellationReason>
): Promise<ApiResponse<OrderCancellationReason>> =>
  api.post('/orders/cancellation-reasons', {
    body: {
      cancellation_reason: cancellationReason,
    },
  })

export const updateCancellationReason = (
  id: Id,
  cancellationReason: $Shape<OrderCancellationReason>
): Promise<ApiResponse<OrderCancellationReason>> =>
  api.put(`/orders/cancellation-reasons/${id}`, {
    body: {
      cancellation_reason: cancellationReason,
    },
  })

export const deleteCancellationReason = (id: Id): Promise<ApiResponse<null>> =>
  api.delete(`/orders/cancellation-reasons/${id}`)

export const sendOrderConfirmationToEdi = orderId =>
  api.post(`/edi/orders/send`, {
    body: {
      order_id: orderId,
    },
  })

export const sendShipmentToEdi = (orderId, shipmentId) =>
  api.post(`/edi/shipments/send`, {
    body: {
      order_id: orderId,
      shipment_id: shipmentId,
    },
  })

export const sendInvoiceToEdi = (orderId, invoiceId, type = 'invoice') =>
  api.post(`/edi/invoices/send`, {
    body: {
      order_id: orderId,
      invoice_id: invoiceId,
      type,
    },
  })

export const generateOrderAccountingAnalysis = (axiosOptions = {}, orderId) =>
  api.get(`/erp/orders/${orderId}/analysis`, {
    ...axiosOptions,
  })
