/* @flow */

import * as React from 'react'
import memo from 'memoize-one'
import moment from 'moment-timezone'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import partition from 'lodash/partition'

import ActionButton from '../../../infrastructure/components/ActionButton'
import CloudinaryResource from '../../../infrastructure/components/CloudinaryResource'
import ConfigCheckbox from '../../../infrastructure/components/ConfigCheckbox'
import ColoredLabel from '../../../infrastructure/components/ColoredLabel'
import FormatCurrency from '../../../infrastructure/components/FormatCurrency'
import ProductionOrderStatusLabel from '../../production/ProductionOrderStatusLabel'
import ProductionOrderDeliveryNoteStatusLabel from '../../production/ProductionOrderDeliveryNoteStatusLabel'
import RelativeDate from '../../../infrastructure/components/RelativeDate'
import { PriorityLabel } from '../../customers/shared'
import OrderStatusLabel from './OrderStatusLabel'
import OrderDeliveryDateLabel from './OrderDeliveryDateLabel'
import {
  CheckboxInput,
  SaveButton,
} from '../../../infrastructure/components/Formik'
import VariantLabel from '../../products/components/VariantLabel'

import { persistIncomingDistribution } from '../api'
import { useIncomingAvailableDistributionCalculation } from '../hooks'

import type { Id } from '../../types'
import { SessionContext, renderDate, renderDateTime, msg } from '../../shared'

type Props = {
  batch: null | string,
  onHide: Function,
  onSaved: Function,
  orderId: Id,
  show: boolean,
  variantId: Id,
}

const OrderIncomingAvailableDistributionModal = ({
  batch,
  onHide,
  onSaved,
  orderId,
  show,
  variantId,
}: Props) => {
  const { brand } = React.useContext(SessionContext)
  const fetchArgs = React.useMemo(
    () => [
      orderId,
      {
        batch: batch,
        variant_id: variantId,
      },
    ],
    [batch, orderId, variantId]
  )
  const [calculation, isFetching, { isInitialized, refresh }] =
    useIncomingAvailableDistributionCalculation(fetchArgs)

  const [orderLineDeliveriesByKey, setOrderLineDeliveriesByKey] =
    React.useState(false)

  React.useEffect(() => {
    if (!calculation) {
      return
    }

    const deliveriesByKey = {}

    const { order_lines_deliveries } = calculation

    for (let delivery of order_lines_deliveries) {
      const key = createDeliveryKey(delivery)

      deliveriesByKey[key] = delivery
    }

    setOrderLineDeliveriesByKey(deliveriesByKey)
  }, [calculation, setOrderLineDeliveriesByKey])

  const onUpdateDelivery = React.useCallback(
    (key, delivery) => {
      setOrderLineDeliveriesByKey(s => {
        const copy = { ...s }

        if (!copy[key]) {
          copy[key] = delivery
        } else {
          copy[key] = { ...copy[key], ...delivery }
        }

        return copy
      })
    },
    [setOrderLineDeliveriesByKey]
  )

  const onSaveAllocation = React.useCallback(
    closeOnSuccess => {
      const orderLineDeliveries = Object.values(orderLineDeliveriesByKey)

      return persistIncomingDistribution(orderLineDeliveries).then(response => {
        if (!response.error) {
          onSaved(closeOnSuccess)

          if (!closeOnSuccess) {
            refresh()
          }

          msg('success', 'The distribution was saved')
        }
      })
    },
    [onSaved, orderLineDeliveriesByKey, refresh]
  )

  if (orderLineDeliveriesByKey === false) {
    return null
  }

  return (
    <Modal show={show} onHide={onHide} dialogClassName="xl-modal">
      <Modal.Header closeButton>
        <Modal.Title>Distribute incoming pieces</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isInitialized && <span>Loading...</span>}
        {isInitialized && (
          <MemoizedTable
            brand={brand}
            calculation={calculation}
            orderLineDeliveriesByKey={orderLineDeliveriesByKey}
            onUpdateDelivery={onUpdateDelivery}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-white" onClick={onHide}>
          Cancel
        </button>
        <ActionButton
          className="btn btn-success"
          onClick={() => onSaveAllocation(false)}
        >
          Save allocation and stay
        </ActionButton>
        <ActionButton
          className="btn btn-success"
          onClick={() => onSaveAllocation(true)}
        >
          Save allocation and close
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

export default OrderIncomingAvailableDistributionModal

const SettingsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`

const Table = ({
  brand,
  calculation,
  orderLineDeliveriesByKey,
  onUpdateDelivery,
}) => {
  const [
    expandedProductionOrderDeliveryNotes,
    setExpandedProductionOrderDeliveryNotes,
  ] = React.useState([])
  const [expandedProductionOrders, setExpandedProductionOrders] =
    React.useState([])

  const toggleProductionOrderDeliveryNote = React.useCallback(
    productionOrderDeliveryNoteId => {
      setExpandedProductionOrderDeliveryNotes(s => {
        const copy = [...s]

        const index = copy.indexOf(productionOrderDeliveryNoteId)
        if (index === -1) {
          copy.push(productionOrderDeliveryNoteId)
        } else {
          copy.splice(index, 1)
        }

        return copy
      })
    },
    [setExpandedProductionOrderDeliveryNotes]
  )

  const toggleProductionOrder = React.useCallback(
    productionOrderId => {
      setExpandedProductionOrders(s => {
        const copy = [...s]

        const index = copy.indexOf(productionOrderId)
        if (index === -1) {
          copy.push(productionOrderId)
        } else {
          copy.splice(index, 1)
        }

        return copy
      })
    },
    [setExpandedProductionOrders]
  )

  const {
    product,
    order_line,
    all_order_lines,
    order_lines_deliveries,

    received_deliveries,
    on_the_way_deliveries,
    in_delivery_deliveries,

    in_production_deliveries,

    freely_available,
  } = calculation

  return (
    <div>
      <HeaderContainer>
        <ProductDataContainer>
          {product.image && (
            <ImageContainer>
              <CloudinaryResource presets="table" id={product.image} />
            </ImageContainer>
          )}

          <div>
            <ProductLabel to={`/products/${product.id}`}>
              #{product.item_number} {product.product_name}
            </ProductLabel>

            <div>
              <VariantLabel
                product={{
                  name: product.product_name,
                  attributes: product.attributes,
                }}
                showProductName={false}
                variant={{ attributes: product.variant_attributes }}
              />
            </div>

            <div>SKU {product.sku}</div>

            {order_line.batch && (
              <span className="label">{order_line.batch}</span>
            )}
          </div>
        </ProductDataContainer>
        <OrderDataContainer>
          <div>
            <Link to={`/orders/show/${order_line.order_id}`}>
              #{order_line.order_number}
            </Link>
          </div>

          <div>
            <StyledPriorityLabel priority={order_line.customer_priority} />

            <Link to={`/customers/${order_line.customer_id}`}>
              {order_line.customer_name}
            </Link>
          </div>
        </OrderDataContainer>
      </HeaderContainer>

      <CurrentOrderHeroesContainer>
        <CurrentOrderHero>
          <CurrentOrderHeroLabel>Order need</CurrentOrderHeroLabel>

          <CurrentOrderHeroCount>
            {order_line.current_need}
          </CurrentOrderHeroCount>
        </CurrentOrderHero>

        <CurrentOrderHero>
          <CurrentOrderHeroLabel>Allocated to inventory</CurrentOrderHeroLabel>

          <CurrentOrderHeroCount>
            {order_line.currently_allocated}
          </CurrentOrderHeroCount>
        </CurrentOrderHero>

        <CurrentOrderHero>
          <CurrentOrderHeroLabel>Allocated to POs</CurrentOrderHeroLabel>

          <CurrentOrderHeroCount>
            {order_line.currently_allocated_from_production_orders}
          </CurrentOrderHeroCount>
        </CurrentOrderHero>

        <CurrentOrderHero>
          <CurrentOrderHeroLabel>
            Allocated to PO deliveries
          </CurrentOrderHeroLabel>

          <CurrentOrderHeroCount>
            {
              order_line.currently_allocated_from_production_order_delivery_notes
            }
          </CurrentOrderHeroCount>
        </CurrentOrderHero>

        <CurrentOrderHero>
          <CurrentOrderHeroLabel>Left to allocate</CurrentOrderHeroLabel>

          <CurrentOrderHeroCount
            style={{
              color:
                order_line.currently_left_to_allocate > 0 ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {order_line.currently_left_to_allocate}
          </CurrentOrderHeroCount>
        </CurrentOrderHero>
      </CurrentOrderHeroesContainer>

      <OrdersHtmlTable>
        <tbody>
          <SectionHeaderRow>
            <td />
            <td colSpan="9">Received</td>
          </SectionHeaderRow>

          <ProductionOrderDeliveryNoteHeaders />

          {received_deliveries.length === 0 && (
            <DeliveryRow>
              <td />
              <td colSpan="9">
                <i>There are no received deliveries</i>
              </td>
            </DeliveryRow>
          )}
          {sortDeliveries(received_deliveries).map(delivery => {
            const expanded = expandedProductionOrderDeliveryNotes.includes(
              delivery.production_order_delivery_note_id
            )

            return (
              <>
                <DeliveryRow
                  active={expanded}
                  key={delivery.production_order_delivery_note_id}
                  onClick={() =>
                    toggleProductionOrderDeliveryNote(
                      delivery.production_order_delivery_note_id
                    )
                  }
                >
                  <td>
                    {expanded && <CloseIcon />}
                    {!expanded && <ExpandIcon />}
                  </td>
                  <td>
                    <SupplierLabel delivery={delivery} />
                  </td>
                  <td>
                    <ProductionOrderLabel delivery={delivery} />

                    {delivery.attached_this_order && <AttachedLabel />}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td className="text-right">{delivery.quantity}</td>
                  <td className="text-right">{delivery.reserved_this_order}</td>
                  <td className="text-right">
                    {delivery.reserved_other_orders}
                  </td>
                  <td className="text-right">
                    <AvailableLabel delivery={delivery} />
                  </td>
                </DeliveryRow>

                {expanded &&
                  all_order_lines.map(orderLine => {
                    const key = createDeliveryKey({
                      order_id: orderLine.order_id,
                      production_order_id: delivery.production_order_id,
                      production_order_delivery_note_id:
                        delivery.production_order_delivery_note_id,
                    })

                    let orderLineDelivery = orderLineDeliveriesByKey[key]
                    if (!orderLineDelivery) {
                      orderLineDelivery = {
                        order_id: orderLine.order_id,
                        production_order_id: delivery.production_order_id,
                        production_order_delivery_note_id:
                          delivery.production_order_delivery_note_id,
                        priority: null,
                        min: null,
                        max: null,
                        production_order_expected_quantity: 0,
                        production_order_delivery_note_expected_quantity: 0,
                        production_order_delivery_note_received_quantity: 0,
                        invoiced: 0,
                        variant_id: orderLine.variant_id,
                        batch: orderLine.batch,
                        inventory_location_id: orderLine.inventory_location_id,
                      }
                    }

                    const isFocusOrder =
                      orderLine.order_id === order_line.order_id

                    return (
                      <OrdersRow focus={isFocusOrder} key={orderLine.id}>
                        <td />
                        <td>
                          <CustomerLabel orderLine={orderLine} />
                        </td>
                        <td>
                          <OrderLabel orderLine={orderLine} />

                          {orderLineDelivery.attached && <AttachedLabel />}
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                priority: e.target.value,
                              })
                            }
                            value={orderLineDelivery.priority}
                          />
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                min: e.target.value,
                              })
                            }
                            value={orderLineDelivery.min}
                          />
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                max: e.target.value,
                              })
                            }
                            value={orderLineDelivery.max}
                          />
                        </td>
                        <td className="text-right">{orderLine.need}</td>
                        <td className="text-right">
                          {isFocusOrder
                            ? orderLineDelivery.production_order_delivery_note_received_quantity ||
                              0
                            : ''}

                          {isFocusOrder && orderLineDelivery.invoiced > 0 && (
                            <InvoicedLabel>
                              ({orderLineDelivery.invoiced} invoiced)
                            </InvoicedLabel>
                          )}
                        </td>
                        <td className="text-right">
                          {!isFocusOrder
                            ? orderLineDelivery.production_order_delivery_note_received_quantity ||
                              0
                            : ''}

                          {!isFocusOrder && orderLineDelivery.invoiced > 0 && (
                            <InvoicedLabel>
                              ({orderLineDelivery.invoiced} invoiced)
                            </InvoicedLabel>
                          )}
                        </td>
                        <td className="text-right" />
                      </OrdersRow>
                    )
                  })}
              </>
            )
          })}

          <SpacerRow>
            <td />
            <td colSpan="9" />
          </SpacerRow>

          <SectionHeaderRow>
            <td />
            <td colSpan="9">On the way</td>
          </SectionHeaderRow>

          <ProductionOrderDeliveryNoteHeaders />

          {on_the_way_deliveries.length === 0 && (
            <DeliveryRow>
              <td />
              <td colSpan="9">
                <i>There are no deliveries on the way</i>
              </td>
            </DeliveryRow>
          )}
          {sortDeliveries(on_the_way_deliveries).map(delivery => {
            const expanded = expandedProductionOrderDeliveryNotes.includes(
              delivery.production_order_delivery_note_id
            )

            return (
              <>
                <DeliveryRow
                  active={expanded}
                  key={delivery.production_order_delivery_note_id}
                  onClick={() =>
                    toggleProductionOrderDeliveryNote(
                      delivery.production_order_delivery_note_id
                    )
                  }
                >
                  <td>
                    {expanded && <CloseIcon />}
                    {!expanded && <ExpandIcon />}
                  </td>
                  <td>
                    <SupplierLabel delivery={delivery} />
                  </td>
                  <td>
                    <ProductionOrderLabel delivery={delivery} />

                    {delivery.attached_this_order && <AttachedLabel />}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td className="text-right">{delivery.quantity}</td>
                  <td className="text-right">{delivery.reserved_this_order}</td>
                  <td className="text-right">
                    {delivery.reserved_other_orders}
                  </td>
                  <td className="text-right">
                    <AvailableLabel delivery={delivery} />
                  </td>
                </DeliveryRow>

                {expanded &&
                  all_order_lines.map(orderLine => {
                    const key = createDeliveryKey({
                      order_id: orderLine.order_id,
                      production_order_id: delivery.production_order_id,
                      production_order_delivery_note_id:
                        delivery.production_order_delivery_note_id,
                    })

                    let orderLineDelivery = orderLineDeliveriesByKey[key]
                    if (!orderLineDelivery) {
                      orderLineDelivery = {
                        order_id: orderLine.order_id,
                        production_order_id: delivery.production_order_id,
                        production_order_delivery_note_id:
                          delivery.production_order_delivery_note_id,
                        priority: null,
                        min: null,
                        max: null,
                        production_order_expected_quantity: 0,
                        production_order_delivery_note_expected_quantity: 0,
                        production_order_delivery_note_received_quantity: null,
                        variant_id: orderLine.variant_id,
                        batch: orderLine.batch,
                        inventory_location_id: orderLine.inventory_location_id,
                      }
                    }

                    const isFocusOrder =
                      orderLine.order_id === order_line.order_id

                    return (
                      <OrdersRow focus={isFocusOrder} key={orderLine.id}>
                        <td />
                        <td>
                          <CustomerLabel orderLine={orderLine} />
                        </td>
                        <td>
                          <OrderLabel orderLine={orderLine} />

                          {orderLineDelivery.attached && <AttachedLabel />}
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                priority: e.target.value,
                              })
                            }
                            value={orderLineDelivery.priority}
                          />
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                min: e.target.value,
                              })
                            }
                            value={orderLineDelivery.min}
                          />
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                max: e.target.value,
                              })
                            }
                            value={orderLineDelivery.max}
                          />
                        </td>
                        <td className="text-right">{orderLine.need}</td>
                        <td className="text-right">
                          {isFocusOrder
                            ? orderLineDelivery.production_order_delivery_note_expected_quantity ||
                              0
                            : ''}
                        </td>
                        <td className="text-right">
                          {!isFocusOrder
                            ? orderLineDelivery.production_order_delivery_note_expected_quantity ||
                              0
                            : ''}
                        </td>
                        <td className="text-right" />
                      </OrdersRow>
                    )
                  })}
              </>
            )
          })}

          <SpacerRow>
            <td />
            <td colSpan="9" />
          </SpacerRow>

          <SectionHeaderRow>
            <td />
            <td colSpan="9">In production</td>
          </SectionHeaderRow>

          <ProductionOrderDeliveryNoteHeaders />

          {in_delivery_deliveries.length === 0 &&
            in_production_deliveries.length === 0 && (
              <DeliveryRow>
                <td />
                <td colSpan="9">
                  <i>There are no quantities in production</i>
                </td>
              </DeliveryRow>
            )}
          {sortDeliveries(in_delivery_deliveries).map((delivery, i) => {
            const expanded = expandedProductionOrderDeliveryNotes.includes(
              delivery.production_order_delivery_note_id
            )

            return (
              <>
                <DeliveryRow
                  active={expanded}
                  last={in_delivery_deliveries.length - 1 === i}
                  key={delivery.production_order_delivery_note_id}
                  onClick={() =>
                    toggleProductionOrderDeliveryNote(
                      delivery.production_order_delivery_note_id
                    )
                  }
                >
                  <td>
                    {expanded && <CloseIcon />}
                    {!expanded && <ExpandIcon />}
                  </td>
                  <td>
                    <SupplierLabel delivery={delivery} />
                  </td>
                  <td>
                    <ProductionOrderLabel delivery={delivery} />

                    {delivery.attached_this_order && <AttachedLabel />}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td className="text-right">{delivery.quantity}</td>
                  <td className="text-right">{delivery.reserved_this_order}</td>
                  <td className="text-right">
                    {delivery.reserved_other_orders}
                  </td>
                  <td className="text-right">
                    <AvailableLabel delivery={delivery} />
                  </td>
                </DeliveryRow>

                {expanded &&
                  all_order_lines.map(orderLine => {
                    const key = createDeliveryKey({
                      order_id: orderLine.order_id,
                      production_order_id: delivery.production_order_id,
                      production_order_delivery_note_id:
                        delivery.production_order_delivery_note_id,
                    })

                    let orderLineDelivery = orderLineDeliveriesByKey[key]
                    if (!orderLineDelivery) {
                      orderLineDelivery = {
                        order_id: orderLine.order_id,
                        production_order_id: delivery.production_order_id,
                        production_order_delivery_note_id:
                          delivery.production_order_delivery_note_id,
                        priority: null,
                        min: null,
                        max: null,
                        production_order_expected_quantity: 0,
                        production_order_delivery_note_expected_quantity: 0,
                        production_order_delivery_note_received_quantity: null,
                        variant_id: orderLine.variant_id,
                        batch: orderLine.batch,
                        inventory_location_id: orderLine.inventory_location_id,
                      }
                    }

                    const isFocusOrder =
                      orderLine.order_id === order_line.order_id

                    return (
                      <OrdersRow focus={isFocusOrder} key={orderLine.id}>
                        <td />
                        <td>
                          <CustomerLabel orderLine={orderLine} />
                        </td>
                        <td>
                          <OrderLabel orderLine={orderLine} />

                          {orderLineDelivery.attached && <AttachedLabel />}
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                priority: e.target.value,
                              })
                            }
                            value={orderLineDelivery.priority}
                          />
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                min: e.target.value,
                              })
                            }
                            value={orderLineDelivery.min}
                          />
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                max: e.target.value,
                              })
                            }
                            value={orderLineDelivery.max}
                          />
                        </td>
                        <td className="text-right">{orderLine.need}</td>
                        <td className="text-right">
                          {isFocusOrder
                            ? orderLineDelivery.production_order_delivery_note_expected_quantity ||
                              0
                            : ''}
                        </td>
                        <td className="text-right">
                          {!isFocusOrder
                            ? orderLineDelivery.production_order_delivery_note_expected_quantity ||
                              0
                            : ''}
                        </td>
                        <td className="text-right" />
                      </OrdersRow>
                    )
                  })}
              </>
            )
          })}
          {sortDeliveries(in_production_deliveries).map((delivery, i) => {
            const expanded = expandedProductionOrders.includes(
              delivery.production_order_id
            )

            return (
              <>
                <DeliveryRow
                  key={delivery.production_order_id}
                  onClick={() =>
                    toggleProductionOrder(delivery.production_order_id)
                  }
                >
                  <td>
                    {expanded && <CloseIcon />}
                    {!expanded && <ExpandIcon />}
                  </td>
                  <td>
                    <SupplierLabel delivery={delivery} />
                  </td>
                  <td>
                    <ProductionOrderLabel delivery={delivery} />

                    {delivery.attached_this_order && <AttachedLabel />}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td className="text-right">{delivery.quantity}</td>
                  <td className="text-right">{delivery.reserved_this_order}</td>
                  <td className="text-right">
                    {delivery.reserved_other_orders}
                  </td>
                  <td className="text-right">
                    <AvailableLabel delivery={delivery} />
                  </td>
                </DeliveryRow>

                {expanded &&
                  all_order_lines.map(orderLine => {
                    const key = createDeliveryKey({
                      order_id: orderLine.order_id,
                      production_order_id: delivery.production_order_id,
                      production_order_delivery_note_id: null,
                    })

                    let orderLineDelivery = orderLineDeliveriesByKey[key]
                    if (!orderLineDelivery) {
                      orderLineDelivery = {
                        order_id: orderLine.order_id,
                        production_order_id: delivery.production_order_id,
                        production_order_delivery_note_id: null,
                        priority: null,
                        min: null,
                        max: null,
                        production_order_expected_quantity: 0,
                        production_order_delivery_note_expected_quantity: null,
                        production_order_delivery_note_received_quantity: null,
                        variant_id: orderLine.variant_id,
                        batch: orderLine.batch,
                        inventory_location_id: orderLine.inventory_location_id,
                      }
                    }

                    const isFocusOrder =
                      orderLine.order_id === order_line.order_id

                    return (
                      <OrdersRow focus={isFocusOrder} key={orderLine.id}>
                        <td />
                        <td>
                          <CustomerLabel orderLine={orderLine} />
                        </td>
                        <td>
                          <OrderLabel orderLine={orderLine} />

                          {orderLineDelivery.attached && <AttachedLabel />}
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                priority: e.target.value,
                              })
                            }
                            value={orderLineDelivery.priority}
                          />
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                min: e.target.value,
                              })
                            }
                            value={orderLineDelivery.min}
                          />
                        </td>
                        <td>
                          <Input
                            onChange={e =>
                              onUpdateDelivery(key, {
                                ...orderLineDelivery,
                                max: e.target.value,
                              })
                            }
                            value={orderLineDelivery.max}
                          />
                        </td>
                        <td className="text-right">{orderLine.need}</td>
                        <td className="text-right">
                          {isFocusOrder
                            ? orderLineDelivery.production_order_expected_quantity ||
                              0
                            : ''}
                        </td>
                        <td className="text-right">
                          {!isFocusOrder
                            ? orderLineDelivery.production_order_expected_quantity ||
                              0
                            : ''}
                        </td>
                        <td className="text-right" />
                      </OrdersRow>
                    )
                  })}
              </>
            )
          })}

          {freely_available !== false && (
            <>
              <SpacerRow>
                <td />
                <td colSpan="9" />
              </SpacerRow>

              <SectionHeaderRow>
                <td />
                <td colSpan="9">Freely available inventory</td>
              </SectionHeaderRow>

              <SectionSubHeaderRow>
                <td className="listview-action" />
                <td width="140"></td>
                <td></td>

                <td className="text-right" width="102">
                  Priority
                </td>
                <td className="text-right" width="102">
                  Min
                </td>
                <td className="text-right" width="102">
                  Max
                </td>
                <td className="text-right" width="85">
                  Quantity
                </td>
                <td className="text-right" width="85">
                  <div>Reserved</div>
                  <div style={{ fontSize: 10 }}>(this order)</div>
                </td>
                <td className="text-right" width="85">
                  <div>Reserved</div>
                  <div style={{ fontSize: 10 }}>(other orders)</div>
                </td>
                <td className="text-right" width="85">
                  Available
                </td>
              </SectionSubHeaderRow>

              <DeliveryRow>
                <td></td>
                <td></td>
                <td></td>
                <td />
                <td />
                <td />
                <td className="text-right">{freely_available.quantity}</td>
                <td className="text-right">
                  {freely_available.reserved_this_order}
                </td>
                <td className="text-right">
                  {freely_available.reserved_other_orders}
                </td>
                <td className="text-right">
                  <AvailableLabel delivery={freely_available} />
                </td>
              </DeliveryRow>

              {all_order_lines.map(orderLine => {
                const key = createDeliveryKey({
                  order_id: orderLine.order_id,
                  production_order_id: null,
                  production_order_delivery_note_id: null,
                })

                let orderLineDelivery = orderLineDeliveriesByKey[key]
                if (!orderLineDelivery) {
                  orderLineDelivery = {
                    order_id: orderLine.order_id,
                    production_order_id: null,
                    production_order_delivery_note_id: null,
                    priority: null,
                    min: null,
                    max: null,
                    production_order_expected_quantity: null,
                    production_order_delivery_note_expected_quantity: null,
                    production_order_delivery_note_received_quantity: null,
                    free_inventory_quantity: 0,
                    variant_id: orderLine.variant_id,
                    batch: orderLine.batch,
                    inventory_location_id: orderLine.inventory_location_id,
                  }
                }

                const isFocusOrder = orderLine.order_id === order_line.order_id

                return (
                  <OrdersRow focus={isFocusOrder} key={orderLine.id}>
                    <td />
                    <td>
                      <CustomerLabel orderLine={orderLine} />
                    </td>
                    <td>
                      <OrderLabel orderLine={orderLine} />
                    </td>
                    <td>
                      <Input
                        onChange={e =>
                          onUpdateDelivery(key, {
                            ...orderLineDelivery,
                            priority: e.target.value,
                          })
                        }
                        value={orderLineDelivery.priority}
                      />
                    </td>
                    <td>
                      <Input
                        onChange={e =>
                          onUpdateDelivery(key, {
                            ...orderLineDelivery,
                            min: e.target.value,
                          })
                        }
                        value={orderLineDelivery.min}
                      />
                    </td>
                    <td>
                      <Input
                        onChange={e =>
                          onUpdateDelivery(key, {
                            ...orderLineDelivery,
                            max: e.target.value,
                          })
                        }
                        value={orderLineDelivery.max}
                      />
                    </td>
                    <td className="text-right">{orderLine.need}</td>
                    <td className="text-right">
                      {isFocusOrder
                        ? orderLineDelivery.free_inventory_quantity || 0
                        : ''}
                    </td>
                    <td className="text-right">
                      {!isFocusOrder
                        ? orderLineDelivery.free_inventory_quantity || 0
                        : ''}
                    </td>
                    <td className="text-right" />
                  </OrdersRow>
                )
              })}
            </>
          )}
        </tbody>
      </OrdersHtmlTable>
    </div>
  )
}

const MemoizedTable = React.memo(Table)

const createDeliveryKey = delivery =>
  `${delivery.order_id}-${delivery.production_order_id}-${delivery.production_order_delivery_note_id}`

const OrdersHtmlTable = styled.table.attrs({
  className: 'table table-condensed-xs table-hover table-bordered',
})``

const CurrentOrderHeroesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-left: -15px;
  text-align: right;
`

const CurrentOrderHero = styled.div`
  margin-left: 15px;
  width: 175px;
  padding: 5px;
  border-radius: 5px;
  background: #f8f8f8;
`

const CurrentOrderHeroLabel = styled.div`
  color: #afafaf;
  font-size: 12px;
  letter-spacing: 1px;
`

const CurrentOrderHeroCount = styled.div`
  color: black;
  font-size: 32px;
`

const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const ImageContainer = styled.div`
  margin-right: 15px;
`

const ProductDataContainer = styled.div`
  display: flex;
  flex: 1;
`

const OrderDataContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const ProductLabel = styled(Link)`
  color: black;
  font-size: 16px;
`

const DeliveryRow = styled.tr`
  cursor: pointer;

  td {
    font-size: 13px;
    padding: 4px !important;

    ${({ active, last }) =>
      active || last
        ? css`
            border-bottom: 1px solid #ccc !important;
          `
        : ''}

    ${({ active }) =>
      active
        ? css`
            background: #f7f7f7;
            font-weight: bold;
          `
        : ''};
  }
`

const OrdersRow = styled.tr`
  &:hover > td {
    background-color: ${({ focus }) =>
      focus ? '#d6d5c3 !important' : '#cccccc !important'};
  }

  td {
    border: 1px solid white !important;
    border-bottom: ${({ last }) =>
      last ? '1px solid #c4c5c7 !important' : 'inherit'};
    background: ${({ focus }) => (focus ? '#e5e2c0' : '#e8e8e8')};
    font-size: 10px;
  }
`

const ExpandOrderIcon = styled.span`
  color: #d6d6d6;
  font-size: 10px;
`

const RowExtraInfo = styled.span`
  font-size: 10px;
`

const SpacerRow = styled.tr`
  td {
    border: 1px solid white !important;
    height: 10px;
  }
`

const StyledConfigCheckbox = styled(ConfigCheckbox)`
  .checkbox label {
    margin-bottom: 0 !important;
  }
`

const Input = styled.input.attrs({
  type: 'text',
})`
  &[type='text'] {
    text-align: right;
    width: 85px;
  }
`

const StyledPriorityLabel = styled(PriorityLabel)`
  display: inline-block;
  margin-right: 5px;
`

const InvoicedLabel = styled.div`
  font-size: 10px;
  margin-top: 2px;
  font-style: italic;
`

const AttachedLabel = () => (
  <div className="label label-success label-xxs">Attached</div>
)

const PrioritizeButton = ({ onClick, prioritize }) => {
  return (
    <button
      type="button"
      className={
        `btn btn-xs btn-block ` + (prioritize ? 'btn-success' : 'btn-white')
      }
      onClick={onClick}
      style={{ marginTop: 5 }}
    >
      {prioritize && (
        <span>
          <span className="glyphicon glyphicon-ok" /> Prioritize
        </span>
      )}
      {!prioritize && (
        <span>
          <span className="glyphicon glyphicon-remove" /> Prioritize
        </span>
      )}
    </button>
  )
}

const ProductionOrderLabel = ({ delivery }) => {
  if (delivery.production_order_delivery_note_id) {
    return (
      <div>
        <div>
          <Link
            to={`/production/orders/delivery-notes/${delivery.production_order_delivery_note_id}`}
          >
            #{delivery.production_order_delivery_note_number}
          </Link>
        </div>
        <div>
          {delivery.expected_delivery && (
            <RowExtraInfo>
              Exp. date {renderDate(delivery.expected_delivery)}
            </RowExtraInfo>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div>
          <Link to={`/production/orders/${delivery.production_order_id}`}>
            #{delivery.production_order_number}
          </Link>
        </div>

        <div>
          {delivery.expected_delivery && (
            <RowExtraInfo>
              Exp. date {renderDate(delivery.expected_delivery)}
            </RowExtraInfo>
          )}
        </div>
      </div>
    )
  }
}

const CustomerLabel = ({ orderLine }) => {
  return (
    <div>
      <StyledPriorityLabel
        priority={orderLine.customer_priority}
        size="xsmall"
      />
      <Link to={`/customers/${orderLine.customer_id}`}>
        {orderLine.customer_name}
      </Link>
    </div>
  )
}

const SupplierLabel = ({ delivery }) => {
  return (
    <Link to={`/suppliers/${delivery.supplier_id}`}>
      {delivery.supplier_name}
    </Link>
  )
}

const OrderLabel = ({ orderLine }) => {
  return (
    <div>
      <div>
        <Link to={`/orders/${orderLine.order_id}`}>
          #{orderLine.order_number}
        </Link>
      </div>

      <div>
        <RowExtraInfo>
          <WrappedOrderDeliveryDateLabel orderLine={orderLine} />
        </RowExtraInfo>
      </div>
    </div>
  )
}

const ProductionOrderDeliveryNoteHeaders = () => {
  return (
    <SectionSubHeaderRow>
      <td className="listview-action" />
      <td width="140">Supplier</td>
      <td>PO Number</td>

      <td className="text-right" width="102">
        Priority
      </td>
      <td className="text-right" width="102">
        Min
      </td>
      <td className="text-right" width="102">
        Max
      </td>
      <td className="text-right" width="85">
        Quantity
      </td>
      <td className="text-right" width="85">
        <div>Reserved</div>
        <div style={{ fontSize: 10 }}>(this order)</div>
      </td>
      <td className="text-right" width="85">
        <div>Reserved</div>
        <div style={{ fontSize: 10 }}>(other orders)</div>
      </td>
      <td className="text-right" width="85">
        Available
      </td>
    </SectionSubHeaderRow>
  )
}

const WrappedOrderDeliveryDateLabel = ({ orderLine }) => {
  return (
    <OrderDeliveryDateLabel
      order={{
        confirmed_delivery: orderLine.order_confirmed_delivery,
        estimated_delivery: orderLine.order_estimated_delivery,
        requested_delivery: orderLine.order_requested_delivery,
      }}
    />
  )
}

const ProductionOrderQuantity = ({ delivery }) => {
  if (delivery.production_order_delivery_note_id) {
    return (
      delivery.production_order_delivery_note_received_quantity ||
      delivery.production_order_delivery_note_expected_quantity
    )
  }

  return delivery.production_order_expected_quantity
}

const ShouldReserveLabel = ({ delivery }) => {
  return delivery.should_reserve === null ? (
    <span style={{ fontSize: '9px' }}>All</span>
  ) : (
    <span>{delivery.should_reserve}</span>
  )
}

const ExpandedOrderTable = ({
  order,
  onShipmentUpdate,
  shipmentsToCreate,
  showImages,
  showNotReady,
  toggleOrder,
  toggleVariant,
}) => {
  const variantsSorted = React.useMemo(() => {
    const readyToShip = []
    const unreleased = []
    const readyThisWeek = []
    const readyNextWeek = []
    const notReady = []

    for (let variantRow of order.variants) {
      if (variantRow.ready_to_ship > 0) {
        readyToShip.push(variantRow)
      } else if (variantRow.unreleased > 0) {
        unreleased.push(variantRow)
      } else if (variantRow.ready_this_week > 0) {
        readyThisWeek.push(variantRow)
      } else if (variantRow.ready_next_week > 0) {
        readyNextWeek.push(variantRow)
      } else {
        notReady.push(variantRow)
      }
    }

    const sections = [
      {
        section: 'Ready to ship',
        variants: readyToShip,
      },
      {
        section: 'Unreleased',
        variants: unreleased,
      },
      {
        section: 'Ready this week',
        variants: readyThisWeek,
      },
      {
        section: 'Ready next week',
        variants: readyNextWeek,
      },
    ]

    if (showNotReady) {
      sections.push({
        section: 'Not ready',
        variants: notReady,
      })
    }

    return sections
  }, [order.variants, showNotReady])

  return (
    <>
      <ExpandedOrderHeaderRow>
        <td />
        {showImages && <td />}
        <td colSpan={2}>Product</td>
        <td className="text-right">Ready to ship</td>
        <td className="text-right">Unreleased</td>
        <td className="text-right">Rdy. this week</td>
        <td className="text-right">Rdy. next week</td>
        <td className="text-right">Not ready</td>
        <td className="text-right">Ship</td>
      </ExpandedOrderHeaderRow>

      {variantsSorted.map((section, i) => {
        const isLastSection = i === variantsSorted.length - 1
        const selectedVariantsOfOrder = shipmentsToCreate[order.id] ?? {}

        return (
          <>
            <SectionHeaderRow>
              <td colSpan={showImages ? 10 : 9}>{section.section}</td>
            </SectionHeaderRow>

            {section.variants.length === 0 && (
              <VariantRow>
                <td colSpan={showImages ? 10 : 9}>
                  <i>None</i>
                </td>
              </VariantRow>
            )}

            {section.variants.map((variantRow, k) => {
              const isLastVariant = k === section.variants.length - 1

              return (
                <VariantRow
                  key={variantRow.variant_id}
                  last={isLastSection && isLastVariant}
                >
                  <td />
                  {showImages && (
                    <td>
                      <CloudinaryResource
                        className="round-pic"
                        id={variantRow.image}
                        presets="table_small"
                        fallback="table_small"
                      />
                    </td>
                  )}
                  <td colSpan={2}>
                    <Link
                      target="_blank"
                      to={`/products/${variantRow.product_id}`}
                    >
                      <VariantLabel
                        product={{
                          name: variantRow.product_name,
                          attributes: variantRow.product_attributes,
                        }}
                        showProductName
                        variant={{ attributes: variantRow.attributes }}
                      />
                    </Link>
                  </td>
                  <td className="text-right">{variantRow.ready_to_ship}</td>
                  <td className="text-right">{variantRow.unreleased}</td>
                  <td className="text-right">{variantRow.ready_this_week}</td>
                  <td className="text-right">{variantRow.ready_next_week}</td>
                  <td className="text-right">{variantRow.not_ready}</td>
                  <td>
                    <input
                      checked={
                        selectedVariantsOfOrder[variantRow.variant_id] === true
                      }
                      onChange={() => toggleVariant(order, variantRow)}
                      type="checkbox"
                    />
                  </td>
                </VariantRow>
              )
            })}
          </>
        )
      })}
    </>
  )
}

const MemoizedExpandedOrderTable = React.memo(ExpandedOrderTable)

const ExpandCloseIcon = styled.span`
  color: #d6d6d6;
`

const ExpandIcon = () => {
  return <ExpandCloseIcon className="glyphicon glyphicon-chevron-up" />
}

const CloseIcon = () => {
  return <ExpandCloseIcon className="glyphicon glyphicon-chevron-down" />
}

const ExpandedOrderHeaderRow = styled.tr`
  td {
    font-size: 10px;
    font-weight: bold;
  }
`

const SectionHeaderRow = styled.tr`
  &:hover > td {
    background-color: black !important;
  }

  td {
    background: black;
    color: white;
    cursor: pointer;
    font-weight: bold;
  }
`

const SectionSubHeaderRow = styled.tr`
  td {
    background-color: #f8f8f8;
    font-weight: bold;
  }
`

const VariantRow = styled.tr`
  td {
    border-bottom: ${({ last }) =>
      last ? '2px solid #ccc !important' : 'inherit'};
    font-size: 10px;
    opacity: ${({ opaque }) => (opaque ? 0.5 : 1)};
  }
`

const ValueLabel = styled.div`
  font-size: 11px;
  margin-top: 4px;
`

const sortDeliveries = memo(deliveries => {
  return sortBy(deliveries, delivery => {
    let sortString = delivery.expected_delivery === null ? '1' : '0'

    sortString += delivery.expected_delivery
      ? moment(delivery.expected_delivery).unix()
      : '99999999999999999999'

    return sortString
  })
})

const AvailableLabel = ({ delivery }) => {
  if (
    delivery.b2c_available === undefined ||
    delivery.b2c_available === null ||
    delivery.available == delivery.b2c_available
  ) {
    return delivery.available
  }

  return (
    <span>
      {delivery.available} ({delivery.b2c_available} B2C)
    </span>
  )
}
