/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import moment from 'moment-timezone'

import OrderIncomingAvailableDistributionModal from '../../../../../orders/components/OrderIncomingAvailableDistributionModal'
import LeadTimeLabel from '../../../LeadTimeLabel'

import type { ColumnConfig } from '../../types'
import {
  addPrefixIfNoneExists,
  renderDate,
  Tooltip,
  SplitComponent,
} from '../../../../../shared'

import InventoryIndicator from './InventoryIndicator'

type Options = {
  editable?: boolean,
  showProductionOrder?: boolean,
}

const delivery_status: (options: Options) => ColumnConfig = ({
  checkLineForPrice = false,
  defaultRender,
  editable = false,
  label = 'Delivery',
  showProductionOrder = false,
}) => ({
  data_source: 'delivery_status',
  key: 'delivery',
  label,
  render: ({
    brand,
    context,
    data,
    onClearDataCache,
    lines,
    variant,
    value,
    userEntity,
  }) => {
    return (
      <DeliveryStatus
        brand={brand}
        context={context}
        data={data}
        defaultRender={defaultRender}
        onClearDataCache={onClearDataCache}
        editable={editable}
        lines={lines}
        showProductionOrder={showProductionOrder}
        variant={variant}
        value={value}
        userEntity={userEntity}
      />
    )
  },
  style: showProductionOrder
    ? () => ({
        width: '150px',
      })
    : undefined,
})

export default delivery_status

const DeliveryStatus = ({
  brand,
  context,
  data,
  defaultRender,
  onClearDataCache,
  editable,
  lines,
  showProductionOrder,
  variant,
  value,
  userEntity,
}) => {
  const [
    showIncomingAvailableDistributionModal,
    setShowIncomingAvailableDistributionModal,
  ] = React.useState(false)

  const usesPoAllocationReservations =
    brand.feature_flags.po_allocation_reservations === true
  const usePrioritizeAttachedPo =
    brand.feature_flags.prioritize_attached_pos === true

  const onDistributionChanged = React.useCallback(
    closeOnSuccess => {
      onClearDataCache()

      if (closeOnSuccess) {
        setShowIncomingAvailableDistributionModal(false)
      }
    },
    [onClearDataCache, setShowIncomingAvailableDistributionModal]
  )

  let isApproved = false
  if (context.order) {
    isApproved = context.order.internal_status === 'approved'
  }

  // The status endpoint will return a delivery per inventory location
  // When selling we want to show this is "one location", so we combine deliveries
  // of the same location into 1 location
  const status = React.useMemo(() => {
    if (!value || value.length == 0) {
      return null
    }

    let status = { ...value[0] }
    status.available = 0
    delete status.inventory_location_id

    const deliveries = value.reduce(
      (carry, status) => carry.concat(status.deliveries),
      []
    )
    const filteredDeliveries = {}
    for (let delivery of deliveries) {
      const key = `${delivery.location_label}-${delivery.production_order_id}-${delivery.production_order_delivery_note_id}`

      if (!filteredDeliveries[key]) {
        filteredDeliveries[key] = { ...delivery }

        if (delivery.quantity !== null) {
          filteredDeliveries[key].quantity = 0
        } else if (delivery.received_quantity !== null) {
          filteredDeliveries[key].received_quantity = 0
        }
      }

      if (delivery.quantity !== null) {
        if (delivery.quantity > 0) {
          filteredDeliveries[key].quantity += delivery.quantity
        }
      } else if (delivery.received_quantity !== null) {
        if (delivery.received_quantity > 0) {
          filteredDeliveries[key].received_quantity +=
            delivery.received_quantity
        }
      }

      if (
        delivery.production_order_id == null &&
        delivery.production_order_delivery_note_id == null &&
        delivery.received_quantity > 0
      ) {
        status.available += delivery.received_quantity
      }
    }

    status.deliveries = Object.values(filteredDeliveries)

    return status
  }, [value])

  // TODO: Memoize somehow
  const sortedDeliveries = sortDeliveries(status, showProductionOrder)

  const available = React.useMemo(() => {
    if (!status) {
      return 0
    }

    return status.deliveries.reduce((acc, d) => acc + d.received_quantity, 0)
  }, [status])

  const inventoryIndicatorRule = React.useMemo(() => {
    if (userEntity.entity_type !== 'shop') {
      return null
    }

    return brand.settings.stock_settings.inventory_indicator_colors.find(
      rule => {
        const from = parseInt(rule.from)
        const to = parseInt(rule.to)

        if (from && to) {
          return from <= available && to >= available
        } else if (from) {
          return from <= available
        } else {
          return to >= available
        }
      }
    )
  }, [
    brand.settings.stock_settings.inventory_indicator_colors,
    available,
    userEntity,
  ])

  if (!status) {
    if (defaultRender) {
      return defaultRender()
    }

    return null
  }

  if (status.deliveries.length === 0 && !status.lead_time) {
    return null
  }

  if (
    !showProductionOrder &&
    (status.confirmed_delivery || status.estimated_delivery)
  ) {
    return (
      <CustomerFacingDeliveryDate
        brand={brand}
        deliveryDate={status.confirmed_delivery || status.estimated_delivery}
        isEstimated={status.confirmed_delivery == null}
      />
    )
  }

  return (
    <WithProductionOrderContainer>
      {showIncomingAvailableDistributionModal !== false && (
        <OrderIncomingAvailableDistributionModal
          batch={status.batch}
          onHide={() => setShowIncomingAvailableDistributionModal(false)}
          onSaved={onDistributionChanged}
          orderId={context.order.id}
          productId={variant.product_id}
          show={showIncomingAvailableDistributionModal !== false}
          variantId={status.variant_id}
        />
      )}

      {inventoryIndicatorRule ? (
        <InventoryIndicator brand={brand} rule={inventoryIndicatorRule} />
      ) : (
        <div>
          {status.deliveries.length == 0 && (
            <LeadTimeLabel
              from={status.lead_time.lead_time_from}
              to={status.lead_time.lead_time_to}
              type={status.lead_time.lead_time_type}
            />
          )}

          {status.deliveries.length > 0 && (
            <DeliveryList multi={sortedDeliveries.length > 1}>
              {sortedDeliveries.map(delivery => {
                return (
                  <li key={delivery.id}>
                    {showProductionOrder && (
                      <DeliveryStatusWithProductionOrder
                        delivery={delivery}
                        editable={editable}
                        leadTime={status.lead_time}
                        multipleRows={sortedDeliveries.length > 1}
                        userEntity={userEntity}
                        usesPoAllocationReservations={
                          usesPoAllocationReservations
                        }
                        usePrioritizeAttachedPo={usePrioritizeAttachedPo}
                      />
                    )}
                    {!showProductionOrder && (
                      <DeliveryStatusWithoutProductionOrder
                        brand={brand}
                        delivery={delivery}
                        leadTime={status.lead_time}
                      />
                    )}
                  </li>
                )
              })}
            </DeliveryList>
          )}
        </div>
      )}
      {editable && isApproved && (
        <div style={{ display: 'flex' }}>
          <SplitComponent
            splitKey="po_allocation_reservations"
            true={
              <EditButton
                onClick={() => setShowIncomingAvailableDistributionModal(true)}
              >
                <span className="glyphicon glyphicon-pencil" />
              </EditButton>
            }
          />
        </div>
      )}
    </WithProductionOrderContainer>
  )
}

const DeliveryList = styled.ul`
  list-style-type: ${({ multi }) => (multi ? 'disc' : 'none')};
  margin-bottom: 0;
  padding-left: ${({ multi }) => (multi ? `20px` : 0)};

  li {
    padding-left: 0;
    white-space: nowrap;
  }
`

const DeliveryDateLabel = styled.div`
  font-size: 10px;
`

const sortDeliveries = (status, showProductionOrder) => {
  if (!status || !status.deliveries) {
    return []
  }

  const leadTime = status.lead_time

  let filteredDeliveries = status.deliveries

  // remove lead time entries without lead time available
  /*filteredDeliveries = filteredDeliveries.filter(d => {
    const isLeadTime =
      d.production_order_id === null &&
      d.production_order_delivery_note_id === null

    return !isLeadTime || leadTime !== null
  })*/

  if (!showProductionOrder) {
    // we dont want to show received POs when not showing production order
    filteredDeliveries = filteredDeliveries.filter(d => {
      const isStock =
        d.production_order_id === null &&
        d.production_order_delivery_note_id === null

      if (isStock && d.received_quantity > 0) {
        return true
      }

      return d.received_quantity === null
    })

    filteredDeliveries = filteredDeliveries.filter(d => {
      return d.delivery_date !== null || leadTime !== null
    })
  }

  return sortBy(filteredDeliveries, delivery => {
    const received = delivery.received_quantity !== null
    const isDeliveryNote = delivery.production_order_delivery_note_id !== null
    const isStock =
      delivery.production_order_id === null &&
      delivery.production_order_delivery_note_id === null
    const expectedDelivery =
      delivery.delivery_date !== null
        ? new Date(delivery.delivery_date).getTime()
        : 9999999999999

    return `${isStock ? '0' : '1'}-${isDeliveryNote ? '0' : '1'}-${
      received ? '0' : '1'
    }-${expectedDelivery}`
  })
}

const DeliveryStatusWithProductionOrder = ({
  delivery,
  editable,
  leadTime,
  multipleRows,
  userEntity,
  usesPoAllocationReservations,
  usePrioritizeAttachedPo,
}) => {
  const isBrand = userEntity ? userEntity.entity_type === 'brand' : 'brand'

  return (
    <div>
      {delivery.production_order_delivery_note_id && (
        <div>
          <span>
            {delivery.received_quantity || delivery.quantity}
            {isBrand && ` x `}
          </span>
          {isBrand && (
            <>
              <Link
                to={`/production/orders/delivery-notes/${delivery.production_order_delivery_note_id}`}
              >
                {addPrefixIfNoneExists(
                  delivery.production_order_delivery_note_number,
                  'PO: '
                )}
              </Link>
              <Notes notes={delivery.notes} />
            </>
          )}
          {/* In the NORR11 system it's called delivery_date, but elsewhere it's called expected_delivery (great stuff) */}
          {!delivery.received_quantity &&
            delivery.delivery_date &&
            !delivery.expected_delivery && (
              <DeliveryDateLabel>
                Exp. {renderDate(delivery.delivery_date)}
              </DeliveryDateLabel>
            )}
          {!delivery.received_quantity &&
            !delivery.delivery_date &&
            delivery.expected_delivery && (
              <DeliveryDateLabel>
                Exp. {renderDate(delivery.expected_delivery)}
              </DeliveryDateLabel>
            )}
          {delivery.received_quantity && <div>Received</div>}
        </div>
      )}

      {!delivery.production_order_delivery_note_id &&
        delivery.production_order_id && (
          <>
            <div>
              <span>
                {delivery.quantity}
                {isBrand && ` x `}
              </span>
              {isBrand && (
                <>
                  <Link
                    to={`/production/orders/${delivery.production_order_id}`}
                  >
                    {addPrefixIfNoneExists(
                      delivery.production_order_number,
                      'PO: '
                    )}
                  </Link>
                  <Notes notes={delivery.notes} />
                </>
              )}
            </div>
            {delivery.delivery_date && !delivery.expected_delivery && (
              <DeliveryDateLabel>
                Exp. {renderDate(delivery.delivery_date)}
              </DeliveryDateLabel>
            )}
            {!delivery.delivery_date && delivery.expected_delivery && (
              <DeliveryDateLabel>
                Exp. {renderDate(delivery.expected_delivery)}
              </DeliveryDateLabel>
            )}
          </>
        )}

      {!delivery.production_order_delivery_note_id &&
        !delivery.production_order_id && (
          <>
            {delivery.received_quantity && (
              <div>
                {delivery.received_quantity}
                {(usesPoAllocationReservations ||
                  usePrioritizeAttachedPo ||
                  multipleRows) &&
                  ` x ${delivery.location_label || 'stock'}`}
              </div>
            )}
            {!delivery.received_quantity && leadTime && (
              <div>
                <span>{delivery.quantity} x </span>
                <LeadTimeLabel
                  from={leadTime.lead_time_from}
                  to={leadTime.lead_time_to}
                  type={leadTime.lead_time_type}
                />
              </div>
            )}
          </>
        )}
    </div>
  )
}

const WithProductionOrderContainer = styled.div`
  display: flex;
`

const DeliveryStatusWithoutProductionOrder = ({
  brand,
  delivery,
  leadTime,
}) => {
  let deliveryDate = delivery.expected_delivery
  let quantity = delivery.quantity
  const isStock =
    delivery.production_order_id === null &&
    delivery.production_order_delivery_note_id === null

  return (
    <div>
      {isStock && delivery.received_quantity > 0 && (
        <InStockLabel delivery={delivery} />
      )}

      {!isStock && deliveryDate && (
        <span>
          <span>{quantity} pcs.</span>{' '}
          <CustomerFacingDeliveryDate
            brand={brand}
            deliveryDate={deliveryDate}
          />
        </span>
      )}
      {!isStock && !deliveryDate && (
        <span>
          <span>{quantity} pcs.</span>{' '}
          {!leadTime && (
            <Tooltip
              id="in-prod-type"
              tip="There are pieces currently production but they have no delivery date as of yet"
            >
              <span style={{ borderBottom: '1px dashed #d4d4d4' }}>
                in prod
              </span>
            </Tooltip>
          )}
          {leadTime && (
            <LeadTimeLabel
              from={leadTime.lead_time_from}
              to={leadTime.lead_time_to}
              type={leadTime.lead_time_type}
            />
          )}
        </span>
      )}
    </div>
  )
}

const Notes = ({ notes }) => {
  if (!notes) {
    return null
  }

  return (
    <span className="print-hide">
      <Tooltip id="delivery-notes" tip={notes}>
        <NotesIcon />
      </Tooltip>
    </span>
  )
}

const NotesIcon = styled.span.attrs({ className: 'fa fa-comment' })`
  font-size: 10px;
  margin-left: 5px;
`

const CustomerFacingDeliveryDate = ({
  brand,
  deliveryDate,
  isEstimated = false,
}) => {
  if (!deliveryDate) {
    return null
  }

  const date = moment(deliveryDate)
  addToDisplayDate(date, brand)
  let formatted

  switch (brand.settings.display_delivery_dates_as) {
    default:
    case 'date':
      formatted = renderDate(date.toString())
      break

    case 'week':
      formatted = `week ${date.format('w')}`
      break

    case 'month':
      formatted = date.format('MMM YYYY')
      break
  }

  let prefix = isEstimated ? 'Est. ' : ''

  return (
    <span>
      {prefix}
      {formatted}
    </span>
  )
}

const addToDisplayDate = (date, brand) => {
  if (!brand.settings.display_delivery_dates_add_to_date) {
    return date
  }

  let momentAddShortHand
  switch (brand.settings.display_delivery_dates_add_to_date_type) {
    case 'day':
      momentAddShortHand = 'd'

      break

    case 'week':
      momentAddShortHand = 'w'

      break
  }

  if (!momentAddShortHand) {
    return date
  }

  date.add(
    brand.settings.display_delivery_dates_add_to_date,
    momentAddShortHand
  )

  return date
}

const EditButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  font-size: 10px;
  outline: 0;
`

const InStockLabel = ({ delivery }) => {
  return (
    <span style={{ color: delivery.location_color }}>{`${
      delivery.received_quantity
    } ${delivery.location_label || 'stock'}`}</span>
  )
}
