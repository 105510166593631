import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { shouldFetchPaymentTerms } from 'payment-terms/actions'
import { SelectWrapper } from 'infrastructure/components/Forms'

class PaymentTermsSelector extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props

    dispatch(shouldFetchPaymentTerms({ context: 'selector' }))
  }

  render() {
    const { paymentTerms, isFetching, ...rest } = this.props

    const activePaymentTerms = paymentTerms.filter(
      paymentTerm => paymentTerm.active == true
    )
    return (
      <SelectWrapper
        {...rest}
        options={activePaymentTerms}
        valueKey="id"
        labelKey="name"
        isLoading={isFetching}
      />
    )
  }
}

export default connect(state => {
  const { items: paymentTerms, isFetching } = state.misc.payment_terms

  return { paymentTerms, isFetching }
})(PaymentTermsSelector)
