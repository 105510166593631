/* @flow */

import * as React from 'react'
import {
  DropdownButton as BootstrapDropdownButton,
  MenuItem,
} from 'react-bootstrap'
import styled from 'styled-components'

type Value = string | number

type Props = {
  checked: boolean,
  onChange: () => void,
  label: string,
  id: string,
}

const ConfigCheckbox = ({
  checked,
  onChange: propsOnChange,
  label,
  id,
  ...rest
}: Props) => {
  const onChange = () => {
    const newValue = !checked

    localStorage.setItem(`config_${id}`, JSON.stringify(newValue))
    propsOnChange(newValue)
  }

  React.useEffect(() => {
    const storage = localStorage.getItem(`config_${id}`)

    if (!storage) {
      return
    }

    propsOnChange(JSON.parse(storage))
  }, [id])

  return (
    <div {...rest}>
      <div className="checkbox check-success">
        <input type="checkbox" id={id} checked={checked} onChange={onChange} />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  )
}

export default ConfigCheckbox
