/* @flow */

import React from 'react'
import styled from 'styled-components'

import { productionOrderDeliveryNoteStatusOptions } from './shared'

import { Tooltip } from '../shared'
import type { ProductionOrderDeliveryNote } from '../types'

type Props = {
  productionOrderDeliveryNote: ProductionOrderDeliveryNote,
  size?: 'xs' | 'sm' | 'lg',
}

const ProductionOrderDeliveryNoteStatusLabel = ({
  productionOrderDeliveryNote,
  size = 'sm',
}: Props) => {
  const buttonClasses = React.useMemo(() => {
    let classes = `label label-${size}`

    const s = productionOrderDeliveryNote.status
    switch (s) {
      case 'open':
        classes += ' label-warning'
        break
      case 'cancelled':
        classes += ' label-danger'
        break
      case 'received':
        classes += ' label-green'
        break
      case 'sent':
        classes += ' label-success'
        break
      default:
        classes += ' label-success'
        break
    }

    return classes
  }, [productionOrderDeliveryNote.status, size])
  const label = React.useMemo(() => {
    return productionOrderDeliveryNoteStatusOptions.find(
      s => s.value === productionOrderDeliveryNote.status
    ).label
  }, [
    productionOrderDeliveryNote.status,
    productionOrderDeliveryNoteStatusOptions,
  ])

  let tip = 'Delivery has been received'
  if (productionOrderDeliveryNote.status === 'sent') {
    tip = 'Delivery is on its way'
  } else if (productionOrderDeliveryNote.status === 'open') {
    tip = 'Open'
  } else if (productionOrderDeliveryNote.status === 'booked') {
    tip = 'Delivery is booked'
  }

  return (
    <Tooltip id={`${productionOrderDeliveryNote.id}-status`} tip={tip}>
      <span className={buttonClasses}>{label}</span>
    </Tooltip>
  )
}

export default ProductionOrderDeliveryNoteStatusLabel
