import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { routerActions } from 'react-router-redux'
import { Template } from 'template'
import {
  SettingsNavigation,
  AdminNavigation,
} from 'template/components/Navigation'
import PublicScreen from 'template/components/PublicScreen'
import {
  UserIsAuthenticated,
  UserIsNotAuthenticated,
  UserIsSetup,
  UserIsNotSetup,
  UserIsAdmin,
  LogoutUser,
} from './auth'

import FileBankV2Session from './file-bank-v2/FileBankSession'
import WebshopSession from './shop/WebshopSessionAuthenticationWrap'
import WebshopSessionV2 from './shopV2/WebshopSessionAuthenticationWrap'
import ShowroomSession from './showroom/ShowroomSessionAuthenticationWrap'
import WarehouseTemplate from './warehouse/pages/WarehouseTemplate'
import WebshopTemplate from 'shop/WebshopTemplate'
import DomContainer from 'template/DomContainer'
import WarehouseErrorBoundary from './warehouse/components/WarehouseErrorBoundary'
import { SplitComponent } from './shared'

const loadRoute = (
  protect = module => UserIsAuthenticated(UserIsSetup(module))
) => {
  return module => {
    return protect !== false ? protect(module.default) : module.default
    //cb(null, wrapped);
  }
}

// We need to apply auth wrappers inside of some components, because
// otherwise they would get remounted on query change.
// See https://github.com/mjrussell/redux-auth-wrapper/tree/v0.8.0#where-to-define--apply-the-wrappers
const loadSelfAuthRoute = cb => loadRoute(false)

const loadDurandalRoute = ParentComponent => {
  return modules => {
    return loadRoute(ParentComponent)({
      default: DurandalRoute(modules[0], modules[1]),
    })
  }
}

// getComponent is a function that returns a promise for a component
// It will not be called until the first mount
export function asyncComponent(getComponent, title) {
  return class AsyncComponent extends React.Component {
    static Component = null
    static defaultTitle = document.title
    state = {
      Component: AsyncComponent.Component,
    }

    componentDidMount() {
      if (!this.state.Component) {
        getComponent().then(Component => {
          AsyncComponent.Component = Component
          this.setState({ Component })
        })
      }
      if (title) {
        document.title = `${title} | ${AsyncComponent.defaultTitle}`
      } else {
        document.title = AsyncComponent.defaultTitle
      }
    }
    render() {
      const { Component } = this.state
      if (Component) {
        return <Component {...this.props} />
      }
      return null
    }
  }
}

// Index
const IndexModule = asyncComponent(
  () => import('./template/IndexModule').then(loadSelfAuthRoute()),
  'Dashboard'
)

// Auth
const Activate = asyncComponent(() =>
  import('./login/components/Activate').then(loadRoute(UserIsNotAuthenticated))
)
const ExpiredConfirmation = asyncComponent(() =>
  import('./login/components/ExpiredConfirmation').then(
    loadRoute(UserIsNotAuthenticated)
  )
)
const LoginForm = asyncComponent(() =>
  import('./login/components/LoginForm').then(loadRoute(UserIsNotAuthenticated))
)
const Logout = asyncComponent(() =>
  import('./login/components/Logout').then(loadRoute(LogoutUser))
)
const Setup = asyncComponent(() =>
  import('./users/components/Setup').then(
    loadRoute(module => UserIsAuthenticated(UserIsNotSetup(module)))
  )
)

// Register
const Register = asyncComponent(() =>
  import('./register/components/Register').then(
    loadRoute(UserIsNotAuthenticated)
  )
)

const ReturnPortal = asyncComponent(() =>
  import('./returns/components/ReturnPortal').then(loadRoute(false))
)

// Backend
const AlertTypeViewer = asyncComponent(() =>
  import('../infrastructure/components/Alerts/AlertTypeViewer').then(
    loadRoute()
  )
)
const CollectionList = asyncComponent(
  () => import('collections/CollectionList').then(loadRoute()),
  'Collections | Products'
)
const LinesheetViewer = asyncComponent(() =>
  import('linesheets/components/LinesheetViewer').then(loadRoute())
)
const LinesheetViewerNoAuth = asyncComponent(() =>
  import('linesheets/components/LinesheetViewer').then(loadRoute(false))
)
const LinesheetEditor = asyncComponent(() =>
  import('linesheets/components/LinesheetEditor').then(loadRoute())
)
const CollectionForm = asyncComponent(() =>
  import('./collections/components/CollectionForm').then(loadRoute())
)
const ShowroomView = asyncComponent(() =>
  import('./showroom/Showroom').then(loadSelfAuthRoute())
)
const ShowroomList = asyncComponent(
  () => import('./showroom/ShowroomList').then(loadRoute()),
  'Showrooms | Sales'
)
const ShowroomDocument = asyncComponent(() =>
  import('./showroom/ShowroomDocument').then(loadRoute())
)
const ShowroomSelectionDocument = asyncComponent(() =>
  import('./showroom/ShowroomSelectionDocument').then(loadRoute())
)
const ShowroomSubmissions = asyncComponent(() =>
  import('./showroom/ShowroomSubmissions').then(loadRoute())
)
const ShowroomEdit = asyncComponent(() =>
  import('./showroom/components/EditShowroom').then(loadRoute())
)
const SelectionsList = asyncComponent(() =>
  import('./brands/SelectionsList').then(loadRoute())
)
const ShowroomTracking = asyncComponent(() =>
  import('./showroom/ShowroomTracking').then(loadRoute())
)
const ConsignmentList = asyncComponent(
  () => import('./consignment/components/ConsignmentList').then(loadRoute()),
  'Consignment | Inventory'
)
const ConsignmentProfile = asyncComponent(() =>
  import('./consignment/components/ConsignmentProfile').then(loadRoute())
)
const CustomerList = asyncComponent(
  () => import('./customers/CustomerList').then(loadRoute()),
  'Customer list | Customers'
)
const CustomerGroups = asyncComponent(
  () => import('./customers/CustomerGroups').then(loadRoute()),
  'Customer groups | Customers'
)
const CustomerProfile = asyncComponent(() =>
  import('./customers/components/CustomerProfile').then(loadRoute())
)
const CustomersImport = asyncComponent(() =>
  import('./customers/CustomerImporter').then(loadRoute())
)
const CustomerImportCustomerNumbers = asyncComponent(() =>
  import('./customers/CustomerImporterCustomerNumbers').then(loadRoute())
)
const Categories = asyncComponent(
  () => import('./categories/CategoriesAdmin').then(loadRoute()),
  'Categories | Products'
)
const Drops = asyncComponent(
  () => import('./orders/components/Drops/DropsList').then(loadRoute()),
  'Drops | Sales'
)
const Inventory = asyncComponent(
  () => import('./inventory/components/InventoryWrapper').then(loadRoute()),
  'Inventory list | Inventory'
)
const InventoryProfile = asyncComponent(() =>
  import('./inventory/components/InventoryProfileScreen').then(loadRoute())
)
const InventoryReport = asyncComponent(
  () => import('./inventory/components/InventoryReport').then(loadRoute()),
  'Inventory report | Inventory'
)
const ReservationsEditor = asyncComponent(
  () => import('./inventory/components/ReservationsEditor').then(loadRoute()),
  'Reservations | Inventory'
)
const LinesheetList = asyncComponent(() =>
  import('./linesheets/components/LinesheetsList').then(loadRoute())
)
const Filebank = asyncComponent(() =>
  import('./file-bank/components/FileBank').then(loadRoute())
)
const EntityTypeOrderList = asyncComponent(
  () => import('./orders/EntityTypeOrderList').then(loadRoute()),
  'Orders | Sales'
)
const OrderChangeProposal = asyncComponent(() =>
  import('./orders/components/OrderChangeProposal').then(loadRoute())
)
const ConsolidatedInvoicesList = asyncComponent(
  () => import('./orders/ConsolidatedInvoiceList').then(loadRoute()),
  'Consolidated Invoices | Orders | Sales'
)
const ConsolidatedInvoiceDocumentWrap = asyncComponent(() =>
  import('./orders/components/Document/ConsolidatedInvoiceDocumentWrap').then(
    loadRoute()
  )
)
const EntityTypeInvoiceList = asyncComponent(
  () => import('./orders/EntityTypeInvoiceList').then(loadRoute()),
  'Invoices | Sales'
)
const PrepaymentsList = asyncComponent(
  () => import('./orders/PrepaymentList').then(loadRoute()),
  'Prepayments | Sales'
)
const PrepaymentPay = asyncComponent(() =>
  import('./orders/PrepaymentPay').then(loadSelfAuthRoute())
)
const Gdpr = asyncComponent(() => import('./users/Gdpr').then(loadRoute()))
const InventoryImporter = asyncComponent(() =>
  import('./inventory/components/InventoryImporter').then(loadRoute())
)
const InventoryImporterLandedCost = asyncComponent(() =>
  import('./inventory/components/InventoryImporterLandedCost').then(loadRoute())
)
const InventoryInitialImporter = asyncComponent(() =>
  import('./inventory/components/InventoryInitialImporter').then(loadRoute())
)
const NewOrder = asyncComponent(() =>
  import('./orders/components/NewOrderWrapper').then(loadRoute())
)
const ShowOrder = asyncComponent(() =>
  import('./orders/components/ShowOrderWrapper').then(loadRoute())
)
const ShowOrderV2 = asyncComponent(() =>
  import('./orders/components/ShowOrderV2').then(loadRoute())
)
const OrderDocument = asyncComponent(() =>
  import('./orders/components/Document/OrderDocumentV2').then(loadRoute())
)
const OrderOverviewDocument = asyncComponent(() =>
  import('./orders/components/Document/OrderOverviewDocument').then(loadRoute())
)
const DraftDocument = asyncComponent(() =>
  import('./orders/components/Document/DraftDocument').then(loadRoute())
)
const InvoiceDocument = asyncComponent(() =>
  import('./orders/components/Document/InvoiceDocumentWrap').then(loadRoute())
)
const InvoiceDocumentV2 = asyncComponent(() =>
  import('./orders/components/Invoice/InvoiceDocumentV2').then(loadRoute())
)
const ProductionOrderDocument = asyncComponent(() =>
  import('./orders/components/Document/ProductionOrderDocumentWrap').then(
    loadRoute()
  )
)
const ProductionOrderDeliveryNoteDocument = asyncComponent(() =>
  import(
    './orders/components/Document/ProductionOrderDeliveryNoteDocumentWrap'
  ).then(loadRoute())
)
const CreditNoteDocument = asyncComponent(() =>
  import('./orders/components/Document/CreditNoteDocumentWrap').then(
    loadRoute()
  )
)
const CreditNoteDocumentV2 = asyncComponent(() =>
  import('./orders/components/CreditNote/CreditNoteDocumentV2').then(
    loadRoute()
  )
)
const PrepaymentDocument = asyncComponent(() =>
  import('./orders/components/Document/OrderPrepaymentDocumentWrap').then(
    loadRoute()
  )
)
const InvoiceForm = asyncComponent(() =>
  import('./orders/components/InvoiceForm').then(loadRoute())
)
const Invoice = asyncComponent(() =>
  import('./orders/components/Invoice').then(loadRoute())
)
const CreditNote = asyncComponent(() =>
  import('./orders/components/CreditNote').then(loadRoute())
)
const CreditNoteWrapper = asyncComponent(() =>
  import('./orders/components/CreditNoteWrapper').then(loadRoute())
)
const PrepaymentForm = asyncComponent(() =>
  import('./orders/components/PrepaymentForm').then(loadRoute())
)
const ShipmentProfile = asyncComponent(() =>
  import('./shipments/components/ShipmentProfile').then(loadRoute())
)
const ShippingPlanner = asyncComponent(() =>
  import('./shipments/components/ShippingPlanner').then(loadRoute())
)
const ShipmentDocument = asyncComponent(() =>
  import('./shipments/ShipmentDocumentWrap').then(loadRoute())
)
const ShipmentDeliveryNoteDocument = asyncComponent(() =>
  import('./shipments/ShipmentDeliveryNoteDocumentWrap').then(loadRoute())
)
const ProductsList = asyncComponent(
  () => import('./products/ProductsList').then(loadRoute()),
  'Product List | Products'
)
const AddProduct = asyncComponent(() =>
  import('./products/components/AddProduct').then(loadRoute())
)
const EditBulkProduct = asyncComponent(() =>
  import('./products/components/BulkEditor').then(loadRoute())
)
const ProductProfile = asyncComponent(() =>
  import('./products/components/ProductProfile').then(loadRoute())
)
const ProductImporter = asyncComponent(() =>
  import('./products/ProductImporter').then(loadRoute())
)
const ProductImporterDelete = asyncComponent(() =>
  import('./products/ProductImporterDelete').then(loadRoute())
)
const ProductImporterUpdateIds = asyncComponent(() =>
  import('./products/ProductImporterUpdateIds').then(loadRoute())
)
const ProductImageImporter = asyncComponent(() =>
  import('./products/ProductImageImporter').then(loadRoute())
)
const ImageImportList = asyncComponent(() =>
  import('./products/components/ImageImport/ImageImportList').then(loadRoute())
)
const ImageImportView = asyncComponent(() =>
  import('./products/components/ImageImport/ImageImportView').then(loadRoute())
)
const PriceLists = asyncComponent(
  () =>
    import('./customers/components/PriceLists/PriceLists').then(loadRoute()),
  'Price lists | Customers'
)
const PriceListEditor = asyncComponent(() =>
  import('./customers/components/PriceLists/PriceListEditor').then(loadRoute())
)
const ReturnsList = asyncComponent(
  () => import('./returns/components/ReturnsList').then(loadRoute()),
  'Returns | Sales'
)
const ReturnForm = asyncComponent(() =>
  import('./returns/components/ReturnForm').then(loadRoute())
)
const ReturnDocument = asyncComponent(() =>
  import('./returns/components/ReturnDocument').then(loadRoute())
)
const ReportGenerator = asyncComponent(
  () => import('./reports/components/Generator').then(loadRoute()),
  'Report Generator | Reports'
)
const ReportOrderCount = asyncComponent(
  () => import('./reports/components/OrderCount').then(loadRoute()),
  'Report Count | Reports'
)
const ReportOrderTotals = asyncComponent(
  () => import('./reports/components/OrderTotals').then(loadRoute()),
  'Report Totals | Reports'
)
const ReportClaims = asyncComponent(
  () => import('./reports/components/ClaimReport').then(loadRoute()),
  'Claims | Reports'
)
const ReportRevenue = asyncComponent(
  () => import('./reports/components/RevenueReport').then(loadRoute()),
  'Revenue | Reports'
)
const ReportRankings = asyncComponent(
  () => import('./reports/components/RankingsReport').then(loadRoute()),
  'Top Rankings | Reports'
)
const ReportWarehousing = asyncComponent(
  () => import('./reports/components/WarehousingReport').then(loadRoute()),
  'Warehousing | Reports'
)
const ReportCancellations = asyncComponent(
  () => import('./reports/components/CancellationsReport').then(loadRoute()),
  'Cancellations | Reports'
)
const ReportIntrastat = asyncComponent(
  () => import('./reports/components/IntrastatReports').then(loadRoute()),
  'Intrastat | Reports'
)
const ReportOneStopShop = asyncComponent(
  () => import('./reports/components/OneStopShopReports').then(loadRoute()),
  'One stop shop | Reports'
)
const ReportCommissionList = asyncComponent(
  () =>
    import('./reports/components/Commission/CommissionReportList').then(
      loadRoute()
    ),
  'Commission | Reports'
)
const ReportCommissionEditor = asyncComponent(() =>
  import('./reports/components/Commission/CommissionReportEditor').then(
    loadRoute()
  )
)
const SalesGoals = asyncComponent(
  () => import('./reports/components/SalesGoals').then(loadRoute()),
  'Sales Goals | Reports'
)
const UnpaidInvoicesDashboard = asyncComponent(
  () =>
    import(
      './reports/components/UnpaidInvoicesDashboard/UnpaidInvoicesDashboardScreen'
    ).then(loadRoute()),
  'Unpaid Invoices | Reports'
)
const StockCountList = asyncComponent(
  () =>
    import('./inventory/components/StockCount/StockCountList').then(
      loadRoute()
    ),
  'Stock count | Inventory'
)
const StockCountView = asyncComponent(() =>
  import('./inventory/components/StockCount/StockCountView').then(loadRoute())
)
const StockCountScansProductsView = asyncComponent(() =>
  import('./inventory/components/StockCount/StockCountScansProductsView').then(
    loadRoute()
  )
)
const StockCountScansWarehouseLocationView = asyncComponent(() =>
  import(
    './inventory/components/StockCount/StockCountScansWarehouseLocationView'
  ).then(loadRoute())
)
const FulfilmentDashboard = asyncComponent(
  () => import('./shipments/components/FulfilmentDashboard').then(loadRoute()),
  'Dashboard | Fulfilment'
)
const ShipmentList = asyncComponent(
  () => import('./shipments/ShipmentList').then(loadRoute()),
  'Shipments | Fulfilment'
)
const SegmentationList = asyncComponent(
  () =>
    import('./products/components/Segmentation/SegmentationList').then(
      loadRoute()
    ),
  'Segmentation | Products'
)
const SegmentationEditor = asyncComponent(() =>
  import('./products/components/Segmentation/SegmentationEditor').then(
    loadRoute()
  )
)
const LaunchEventsPage = asyncComponent(
  () =>
    import('./products/components/LaunchEvents/LaunchEventsPage').then(
      loadRoute()
    ),
  'Launch Events | Products'
)
const AddLaunchEventsPage = asyncComponent(() =>
  import('./products/components/LaunchEvents/AddLaunchEventsPage').then(
    loadRoute()
  )
)
const Subbrands = asyncComponent(
  () => import('./subbrands/Admin').then(loadRoute()),
  'Brands | Products'
)
const ProductGroups = asyncComponent(
  () => import('./product-groups/ProductGroups').then(loadRoute()),
  'Product Groups | Products'
)
const NewsArchive = asyncComponent(() =>
  import('./news/NewsArchive').then(loadRoute())
)
const TaskList = asyncComponent(() =>
  import('./activities/TaskList').then(loadRoute())
)

const Parts = asyncComponent(() =>
  import('./production/Parts').then(loadRoute())
)
const Projects = asyncComponent(() =>
  import('./production/Projects').then(loadRoute())
)
const ProductionDashboard = asyncComponent(
  () => import('./production/ProductionDashboard').then(loadRoute()),
  'Dashboard | Production'
)
const ProductionOrder = asyncComponent(() =>
  import('./production/ProductionOrder').then(loadRoute())
)
const ProductionOrderDeliveryNote = asyncComponent(() =>
  import('./production/ProductionOrderDeliveryNote').then(loadRoute())
)
const ProductionDeliveryPackingInstructions = asyncComponent(() =>
  import('./production/ProductionDeliveryPackingInstructions').then(loadRoute())
)
const ProductionDeliveryColliLabels = asyncComponent(() =>
  import('./production/ProductionDeliveryColliLabels').then(loadRoute())
)
const ProductionOrderList = asyncComponent(
  () => import('./production/ProductionOrderList').then(loadRoute()),
  'Production Orders | Production'
)
const ProductionOrderDeliveryNoteList = asyncComponent(
  () =>
    import('./production/ProductionOrderDeliveryNoteList').then(loadRoute()),
  'Deliveries | Production'
)
const OverheadCostInvoiceList = asyncComponent(
  () =>
    import('./production/OverheadCostInvoice/OverheadCostInvoiceList').then(
      loadRoute()
    ),
  'Overhead cost invoices | Production'
)
const OverheadCostInvoiceProfile = asyncComponent(
  () =>
    import('./production/OverheadCostInvoice/OverheadCostInvoiceProfile').then(
      loadRoute()
    ),
  'Overhead cost invoice | Production'
)
const ProductionPrepaymentList = asyncComponent(
  () =>
    import('./production/ProductionPrepayment/ProductionPrepaymentList').then(
      loadRoute()
    ),
  'Production prepayments | Production'
)
const ProductionPrepaymentProfile = asyncComponent(
  () =>
    import(
      './production/ProductionPrepayment/ProductionPrepaymentProfile'
    ).then(loadRoute()),
  'Production prepayment | Production'
)
const DeliveryChart = asyncComponent(
  () =>
    import('./production/DeliveryChart/DeliveryChartScreen').then(loadRoute()),
  'Delivery Chart | Production'
)
const SupplierList = asyncComponent(
  () => import('./production/SupplierList').then(loadRoute()),
  'Suppliers | Production'
)
const SupplierProfile = asyncComponent(() =>
  import('./production/SupplierProfile').then(loadRoute())
)
const ProspectList = asyncComponent(
  () => import('./customers/ProspectList').then(loadRoute()),
  'Prospects | Customers'
)
const ProspectImport = asyncComponent(() =>
  import('./customers/ProspectImporter').then(loadRoute())
)

// File bank
const FileBankV2Search = asyncComponent(() =>
  import('./file-bank-v2/FileBankSearch').then(loadSelfAuthRoute())
)
const FileBankV2Selection = asyncComponent(() =>
  import('./file-bank-v2/FileBankSelection').then(loadSelfAuthRoute())
)
const FileBankV2ShowFolder = asyncComponent(() =>
  import('./file-bank-v2/FileBankShowFolder').then(loadSelfAuthRoute())
)

// Warehouse
const WarehouseDashboard = asyncComponent(() =>
  import('./warehouse/pages/Dashboard/WarehouseDashboard').then(loadRoute())
)
const WarehouseInvoice = asyncComponent(() =>
  import('./warehouse/pages/Invoice/WarehouseInvoice').then(loadRoute())
)
const WarehouseShipment = asyncComponent(() =>
  import('./warehouse/pages/Shipments/WarehouseShipment').then(loadRoute())
)
const WarehousePackingRouteScan = asyncComponent(() =>
  import('./warehouse/pages/Shipments/WarehousePackingRouteScan').then(
    loadRoute()
  )
)
const WarehousePackingRouteInvoice = asyncComponent(() =>
  import('./warehouse/pages/Shipments/WarehousePackingRouteInvoice').then(
    loadRoute()
  )
)
const WarehouseShipments = asyncComponent(() =>
  import('./warehouse/pages/Shipments/WarehouseShipments').then(loadRoute())
)
const WarehouseScan = asyncComponent(() =>
  import('./warehouse/pages/Shipments/WarehouseScan').then(loadRoute())
)
const WarehouseShipmentList = asyncComponent(() =>
  import('./warehouse/pages/Shipments/WarehouseShipmentList').then(loadRoute())
)
const WarehouseInventory = asyncComponent(() =>
  import('./warehouse/pages/Inventory/WarehouseInventory').then(loadRoute())
)
const WarehouseStockCounts = asyncComponent(() =>
  import('./warehouse/pages/Inventory/WarehouseStockCounts').then(loadRoute())
)
const WarehouseStockCountScan = asyncComponent(() =>
  import('./warehouse/pages/Inventory/WarehouseStockCountScan').then(
    loadRoute()
  )
)
const WarehouseNewStockCount = asyncComponent(() =>
  import('./warehouse/pages/Inventory/WarehouseNewStockCount').then(loadRoute())
)
const WarehouseLocation = asyncComponent(() =>
  import('./warehouse/pages/Inventory/WarehouseLocation').then(loadRoute())
)
const WarehouseProduct = asyncComponent(() =>
  import('./warehouse/pages/Inventory/WarehouseProduct').then(loadRoute())
)
const WarehouseVariant = asyncComponent(() =>
  import('./warehouse/pages/Inventory/WarehouseVariantWrapper').then(
    loadRoute()
  )
)
const WarehouseNewProductionOrderDeliveryNote = asyncComponent(() =>
  import(
    './warehouse/pages/ProductionOrderDeliveryNote/WarehouseNewProductionOrderDeliveryNote'
  ).then(loadRoute())
)
const WarehouseProductionOrderDeliveryNoteScan = asyncComponent(() =>
  import(
    './warehouse/pages/ProductionOrderDeliveryNote/WarehouseProductionOrderDeliveryNoteScan'
  ).then(loadRoute())
)
const WarehouseProductionOrderDeliveryNoteScanFinish = asyncComponent(() =>
  import(
    './warehouse/pages/ProductionOrderDeliveryNote/WarehouseProductionOrderDeliveryNoteScanFinish'
  ).then(loadRoute())
)
const WarehouseProductionOrderDeliveryNotes = asyncComponent(() =>
  import(
    './warehouse/pages/ProductionOrderDeliveryNote/WarehouseProductionOrderDeliveryNotes'
  ).then(loadRoute())
)
const WarehouseReturns = asyncComponent(() =>
  import('./warehouse/pages/WarehouseReturns').then(loadRoute())
)
const WarehouseSearch = asyncComponent(() =>
  import('./warehouse/pages/Search/WarehouseSearch').then(loadRoute())
)
const WarehouseNavigation = asyncComponent(() =>
  import('./warehouse/pages/Navigation/WarehouseNavigation').then(loadRoute())
)
const WarehouseApplicationInfo = asyncComponent(() =>
  import('./warehouse/pages/Navigation/WarehouseApplicationInfo').then(
    loadRoute()
  )
)
const WarehouseComputerList = asyncComponent(() =>
  import('./warehouse/pages/Navigation/WarehouseComputerList').then(loadRoute())
)
const WarehouseSubUserListScreen = asyncComponent(() =>
  import('./warehouse/pages/Navigation/WarehouseSubUserListScreen').then(
    loadRoute()
  )
)
const WarehouseBrand = asyncComponent(() =>
  import('./warehouse/pages/Navigation/WarehouseBrand').then(loadRoute())
)

// Webshop
const WebshopLanding = asyncComponent(() =>
  import('./shop/components/Landing').then(loadSelfAuthRoute())
)
const WebshopCart = asyncComponent(() =>
  import('./shop/components/Cart').then(loadSelfAuthRoute())
)
const WebshopFavorites = asyncComponent(() =>
  import('./shop/Favorites').then(loadSelfAuthRoute())
)
const WebshopQuerySearch = asyncComponent(() =>
  import('./shop/components/QuerySearch').then(loadSelfAuthRoute())
)
const WebshopLinkSearch = asyncComponent(() =>
  import('./shop/components/NavigationLinkSearch').then(loadSelfAuthRoute())
)
const WebshopProduct = asyncComponent(() =>
  import('./shop/ShopShow').then(loadSelfAuthRoute())
)
const WebshopPage = asyncComponent(() =>
  import('./shop/WebshopPage').then(loadSelfAuthRoute())
)
const WebshopPay = asyncComponent(() =>
  import('./shop/WebshopPay').then(loadSelfAuthRoute())
)
const WebshopV2Pay = asyncComponent(() =>
  import('./shopV2/WebshopPay').then(loadSelfAuthRoute())
)
const WebshopLinesheet = asyncComponent(() =>
  import('./shop/WebshopLinesheet').then(loadSelfAuthRoute())
)

// WebshopV2
const WebshopV2Landing = asyncComponent(() =>
  import('./shopV2/components/WebshopLandingV2/WebshopLandingV2').then(
    loadSelfAuthRoute()
  )
)
const WebshopV2Cart = asyncComponent(() =>
  import('./shopV2/components/Cart').then(loadSelfAuthRoute())
)
const WebshopV2Favorites = asyncComponent(() =>
  import('./shopV2/Favorites').then(loadSelfAuthRoute())
)
const WebshopV2QuerySearch = asyncComponent(() =>
  import('./shopV2/components/QuerySearch').then(loadSelfAuthRoute())
)
const WebshopV2LinkSearch = asyncComponent(() =>
  import('./shopV2/components/NavigationLinkSearch').then(loadSelfAuthRoute())
)
const WebshopV2Product = asyncComponent(() =>
  import(
    './shopV2/components/WebshopProductDetail/WebshopProductDetailRoute'
  ).then(loadSelfAuthRoute())
)
const WebshopV2Page = asyncComponent(() =>
  import('./shopV2/WebshopPage').then(loadSelfAuthRoute())
)
const WebshopV2Sprii = asyncComponent(() =>
  import('./shopV2/WebshopSprii').then(loadSelfAuthRoute())
)
const WebshopV2Linesheet = asyncComponent(() =>
  import('./shopV2/WebshopLinesheet').then(loadSelfAuthRoute())
)
const WebshopSessionDocument = asyncComponent(() =>
  import('./orders/components/Document/WebshopSessionDocument').then(
    loadRoute()
  )
)

// Admin
const AdminAgentList = asyncComponent(() =>
  import('./admin/AgentList').then(loadRoute())
)
const AdminUsers = asyncComponent(() =>
  import('./admin/Users').then(loadRoute())
)
const AdminAccountingFixingTools = asyncComponent(() =>
  import('./admin/AccountingFixingTools').then(loadRoute())
)
const AdminElasticIntegrity = asyncComponent(() =>
  import('./admin/ElasticIntegrity').then(loadRoute())
)
const AdminReports = asyncComponent(() =>
  import('./admin/AdminReports').then(loadRoute())
)
const AdminPayingCustomers = asyncComponent(() =>
  import('./admin/PayingCustomers').then(loadRoute())
)
const AdminTabletStates = asyncComponent(() =>
  import('./admin/TabletAppStates').then(loadRoute())
)
const AdminApps = asyncComponent(() => import('./admin/Apps').then(loadRoute()))
const AdminFeatureAdoption = asyncComponent(() =>
  import('./admin/FeatureAdoption').then(loadRoute())
)
const AdminFeatureFlags = asyncComponent(() =>
  import('./admin/FeatureFlags').then(loadRoute())
)
const AdminImports = asyncComponent(() =>
  import('./admin/Imports').then(loadRoute())
)
const AdminSettingChanges = asyncComponent(() =>
  import('./admin/SettingChanges').then(loadRoute())
)
const AdminQueue = asyncComponent(() =>
  import('./admin/Queue').then(loadRoute())
)
const AdminNewsList = asyncComponent(() =>
  import('./admin/NewsList').then(loadRoute())
)
const AdminNewsForm = asyncComponent(() =>
  import('./admin/NewsForm').then(loadRoute())
)
const AdminMiscellaneousTools = asyncComponent(() =>
  import('./admin/MiscellaneousTools').then(loadRoute())
)

// Learn
const LearnDashboardPage = asyncComponent(() =>
  import('./learn/LearnDashboardPage').then(loadRoute())
)
const LearnArticlePage = asyncComponent(() =>
  import('./learn/LearnArticlePage').then(loadRoute())
)

// App store
const AppStoreLanding = asyncComponent(() =>
  import('./app-store/components/AppStore').then(loadRoute())
)
const AppStoreBusinessCentral = asyncComponent(() =>
  import('./app-store/components/BusinessCentral').then(loadRoute())
)
const AppStoreCreate2Stay = asyncComponent(() =>
  import('./app-store/components/Create2Stay').then(loadRoute())
)
const AppStoreCsvExporter = asyncComponent(() =>
  import('./app-store/components/CsvExporter').then(loadRoute())
)
const AppStoreCsvImporter = asyncComponent(() =>
  import('./app-store/components/CsvImporter').then(loadRoute())
)
const AppStoreCustomerRegistration = asyncComponent(() =>
  import('./app-store/components/CustomerRegistration').then(loadRoute())
)
const AppStoreDnb = asyncComponent(() =>
  import('./app-store/components/Dnb').then(loadRoute())
)
const AppStoreRiverty = asyncComponent(() =>
  import('./app-store/components/Riverty').then(loadRoute())
)
const AppStoreRivertyTransferDocument = asyncComponent(() =>
  import('./app-store/components/RivertyTransferDocument').then(loadRoute())
)
const AppStoreDataExport = asyncComponent(() =>
  import('./app-store/components/DataExport').then(loadRoute())
)
const AppStoreDelogue = asyncComponent(() =>
  import('./app-store/components/Delogue').then(loadRoute())
)
const AppStoreEaccounting = asyncComponent(() =>
  import('./app-store/components/Eaccounting').then(loadRoute())
)
const AppStoreEconomic = asyncComponent(() =>
  import('./app-store/components/Economic').then(loadRoute())
)
const AppStoreExact = asyncComponent(() =>
  import('./app-store/components/Exact').then(loadRoute())
)
const AppStoreFileBank = asyncComponent(() =>
  import('./app-store/components/FileBank').then(loadRoute())
)
const AppStoreFortnox = asyncComponent(() =>
  import('./app-store/components/Fortnox').then(loadRoute())
)
const AppStoreFoPower = asyncComponent(() =>
  import('./app-store/components/FoPower').then(loadRoute())
)
const AppStoreEpay = asyncComponent(() =>
  import('./app-store/components/Epay').then(loadRoute())
)
const AppStoreExternalDataMapping = asyncComponent(() =>
  import('./app-store/components/ExternalDataMapping').then(loadRoute())
)
const AppStoreHlt = asyncComponent(() =>
  import('./app-store/components/Hlt').then(loadRoute())
)
const AppStoreJoor = asyncComponent(() =>
  import('./app-store/components/Joor').then(loadRoute())
)
const AppStoreGeneric3pl = asyncComponent(() =>
  import('./app-store/components/Generic3pl').then(loadRoute())
)
const AppStoreGenericB2c = asyncComponent(() =>
  import('./app-store/components/GenericB2c').then(loadRoute())
)
const AppStoreGenericPos = asyncComponent(() =>
  import('./app-store/components/GenericPos').then(loadRoute())
)
const AppStoreMailMak = asyncComponent(() =>
  import('./app-store/components/MailMak').then(loadRoute())
)
const AppStoreNavision = asyncComponent(() =>
  import('./app-store/components/Navision').then(loadRoute())
)
const AppStoreFaire = asyncComponent(() =>
  import('./app-store/components/Faire').then(loadRoute())
)
const AppStoreThetisPack = asyncComponent(() =>
  import('./app-store/components/ThetisPack').then(loadRoute())
)
const AppStoreMailchimp = asyncComponent(() =>
  import('./app-store/components/Mailchimp').then(loadRoute())
)
const AppStoreMidtFactoring = asyncComponent(() =>
  import('./app-store/components/MidtFactoring').then(loadRoute())
)
const AppStoreOngoing = asyncComponent(() =>
  import('./app-store/components/Ongoing').then(loadRoute())
)
const AppStoreCoolrunner = asyncComponent(() =>
  import('./app-store/components/Coolrunner').then(loadRoute())
)
const AppStoreEdi = asyncComponent(() =>
  import('./app-store/components/Edi').then(loadRoute())
)
const AppStoreDsv = asyncComponent(() =>
  import('./app-store/components/Dsv').then(loadRoute())
)
const AppStoreRhenus = asyncComponent(() =>
  import('./app-store/components/Rhenus').then(loadRoute())
)
const AppStoreShipments = asyncComponent(() =>
  import('./app-store/components/Shipments').then(loadRoute())
)
const AppStoreSvea = asyncComponent(() =>
  import('./app-store/components/Svea').then(loadRoute())
)
const AppStoreQuickbooks = asyncComponent(() =>
  import('./app-store/components/Quickbooks').then(loadRoute())
)
const AppStoreProduction = asyncComponent(() =>
  import('./app-store/components/Production').then(loadRoute())
)
const AppStorePublicWebshop = asyncComponent(() =>
  import('./app-store/components/PublicWebshop').then(loadRoute())
)
const AppStoreTripletex = asyncComponent(() =>
  import('./app-store/components/Tripletex').then(loadRoute())
)
const AppStorePicqer = asyncComponent(() =>
  import('./app-store/components/Picqer').then(loadRoute())
)
const AppStorePrimeCargo = asyncComponent(() =>
  import('./app-store/components/PrimeCargo').then(loadRoute())
)
const AppStoreQuickpay = asyncComponent(() =>
  import('./app-store/components/Quickpay').then(loadRoute())
)
const AppStoreSendcloud = asyncComponent(() =>
  import('./app-store/components/Sendcloud').then(loadRoute())
)
const AppStoreShipmondo = asyncComponent(() =>
  import('./app-store/components/Shipmondo').then(loadRoute())
)
const AppStoreShopify = asyncComponent(() =>
  import('./app-store/components/Shopify').then(loadRoute())
)
const AppStoreShippingCost = asyncComponent(() =>
  import('./app-store/components/ShippingCost').then(loadRoute())
)
const AppStoreSystemtransport = asyncComponent(() =>
  import('./app-store/components/Systemtransport').then(loadRoute())
)
const AppStoreTmc = asyncComponent(() =>
  import('./app-store/components/Tmc').then(loadRoute())
)
const AppStoreWebshipper = asyncComponent(() =>
  import('./app-store/components/Webshipper').then(loadRoute())
)
const AppStoreWoocommerce = asyncComponent(() =>
  import('./app-store/components/Woocommerce').then(loadRoute())
)
const AppStoreWoocommerceUpdateSkusImporter = asyncComponent(() =>
  import('./app-store/components/WoocommerceUpdateSkusImporter').then(
    loadRoute()
  )
)
const AppStoreKlaviyo = asyncComponent(() =>
  import('./app-store/components/Klaviyo').then(loadRoute())
)
const AppStorePakkecenterNord = asyncComponent(() =>
  import('./app-store/components/PakkecenterNord').then(loadRoute())
)
const AppStoreSprii = asyncComponent(() =>
  import('./app-store/components/Sprii').then(loadRoute())
)
const AppStoreXero = asyncComponent(() =>
  import('./app-store/components/Xero').then(loadRoute())
)
const AppStoreZalando = asyncComponent(() =>
  import('./app-store/components/Zalando').then(loadRoute())
)
const DocumentExchange = asyncComponent(() =>
  import('./app-store/components/DocumentExchange').then(loadRoute())
)

// Customer Registration
const CustomerRegistrationPage = asyncComponent(() =>
  import('./customers/components/CustomerRegistration').then(loadRoute(false))
)

const WrapInDomContainer = InputComponent => {
  return props => (
    <DomContainer {...props}>
      <InputComponent {...props} />
    </DomContainer>
  )
}

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={IndexModule} />

      {/* Public authentication routes */}
      <Route exact path="/activate/:code" component={Activate} />
      <Route exact path="/confirm/:code" component={ExpiredConfirmation} />
      <Route
        exact
        path="/login/:mode?/:code?"
        component={WrapInDomContainer(LoginForm)}
      />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/setup" component={Setup} />
      <Route
        exact
        path="/brand/register/:entityId?"
        component={WrapInDomContainer(Register)}
      />

      {/* Unauthenticated Epay payment pages */}
      <Route
        exact
        path="/pay/:brandId/prepayment/:prepaymentId/:token"
        component={PrepaymentPay}
      />
      <Route
        exact
        path="/pay/:brandId/webshop/:webshopSessionId/:token"
        component={WebshopPay}
      />
      <Route
        exact
        path="/pay/:brandId/webshop-2/:webshopSessionId/:token"
        component={WebshopV2Pay}
      />

      {/* Unauthenticated customer registration */}
      <Route
        exact
        path="/register"
        component={WrapInDomContainer(CustomerRegistrationPage)}
      />

      {/*Authenticated routes*/}
      <Route
        exact
        path="/shop/:brandId/sessions/:webshopSessionId/documents/:id/:print?"
        component={WrapInDomContainer(WebshopSessionDocument)}
      />
      <Route
        exact
        path="/campaigns/:id/edit"
        component={WrapInDomContainer(LinesheetEditor)}
      />
      <Route
        exact
        path="/campaigns/:id/print"
        component={WrapInDomContainer(LinesheetViewerNoAuth)}
      />
      <Route
        exact
        path="/campaigns/:id/documents/:documentId/print"
        component={WrapInDomContainer(LinesheetViewerNoAuth)}
      />
      <Route
        exact
        path="/campaigns/:id/share"
        component={LinesheetViewerNoAuth}
      />
      <Route path="/admin" component={Admin} />
      <Route path="/file-bank-new/:brandId" component={FileBankV2} />
      <Route path="/settings" component={Settings} />
      <Route path="/shop/:brandId" component={Webshop} />
      <Route path="/showroom/:brandId/:showroomId" component={Showroom} />
      <Route path="/warehouse" component={Warehouse} />
      <Route path="/apps" component={AppStore} />
      <Route
        exact
        path="/return-portals/:returnPortalPublicId"
        component={WrapInDomContainer(ReturnPortal)}
      />
      <Route path="/" component={Backend} />
    </Switch>
  )
}

const Backend = UserIsAuthenticated(
  UserIsSetup(props => {
    return (
      <DomContainer {...props}>
        <Template {...props}>
          <Switch>
            <Route
              exact
              path="/alerts/:alertType"
              component={AlertTypeViewer}
            />
            <Route exact path="/collections" component={CollectionList} />
            <Route exact path="/collections/add" component={CollectionForm} />
            <Route
              exact
              path="/collections/edit/:id"
              component={CollectionForm}
            />
            <Route exact path="/customer-groups" component={CustomerGroups} />
            <Route exact path="/customers/import" component={CustomersImport} />
            <Route
              exact
              path="/customers/:customerId(\d+)/:tab?"
              component={CustomerProfile}
            />
            <Route
              exact
              path="/customers/:tab([\w\d\-]+)?"
              component={CustomerList}
            />
            <Route
              exact
              path="/customers/import/update-customer-numbers"
              component={CustomerImportCustomerNumbers}
            />
            <Route exact path="/categories" component={Categories} />
            <Route
              exact
              path="/consignment/:tab([a-z\-]+)?"
              component={ConsignmentList}
            />
            <Route
              exact
              path="/consignment/:customerId/:tab?/:subTab?"
              component={ConsignmentProfile}
            />
            <Route
              exact
              path="/document-exchange"
              component={DocumentExchange}
            />
            <Route exact path="/inventory" component={Inventory} />
            <Route
              exact
              path="/inventory/import"
              component={InventoryImporter}
            />
            <Route
              exact
              path="/inventory/initial-inventory-import"
              component={InventoryInitialImporter}
            />
            <Route
              exact
              path="/inventory/import/update-landed-cost"
              component={InventoryImporterLandedCost}
            />
            <Route exact path="/inventory/report" component={InventoryReport} />
            <Route
              exact
              path="/inventory/reservations"
              component={ReservationsEditor}
            />
            <Route
              exact
              path="/inventory/stock-counts/:tab([a-z]+)?"
              component={StockCountList}
            />
            <Route
              exact
              path="/inventory/stock-counts/:stockCountId([0-9]+)"
              component={StockCountView}
            />
            <Route
              exact
              path="/inventory/stock-counts/:stockCountId([0-9]+)/products/:productId([0-9]+)"
              component={StockCountScansProductsView}
            />
            <Route
              exact
              path="/inventory/stock-counts/:stockCountId([0-9]+)/locations/:warehouseLocationId([0-9]+)"
              component={StockCountScansWarehouseLocationView}
            />
            <Route
              exact
              path="/inventory/:productId([0-9]+)/:tab?"
              component={InventoryProfile}
            />
            <Route
              exact
              path="/inventory/:tab([\w\d\-]+)?"
              component={Inventory}
            />
            <Route exact path="/learn" component={LearnDashboardPage} />
            <Route
              exact
              path="/learn/courses/:courseId([0-9]+)"
              component={LearnArticlePage}
            />
            <Route
              exact
              path="/campaigns/:tab([a-z]+)?"
              component={LinesheetList}
            />
            <Route exact path="/gdpr" component={Gdpr} />
            <Route
              exact
              path="/file-bank/:id/folder/:folderId"
              component={Filebank}
            />
            <Route exact path="/file-bank/:id" component={Filebank} />
            <Route
              exact
              path="/orders/drops/:tab([\w\d\-]+)?"
              component={Drops}
            />
            <Route exact path="/orders/new/:brandId?" component={NewOrder} />
            <Route exact path="/orders/show/:orderId" component={ShowOrder} />
            <Route
              exact
              path="/orders/:orderId([0-9]+)/:tab?"
              component={ShowOrder}
            />
            <Route
              exact
              path="/orders-new/:orderId([0-9]+)/:tab?"
              component={ShowOrderV2}
            />
            <Route
              exact
              path="/orders/:tab([\w\d\-]+)?"
              component={EntityTypeOrderList}
            />
            <Route
              exact
              path="/orders/change-proposal-groups/:id"
              component={OrderChangeProposal}
            />
            <Route
              exact
              path="/orders/:orderId/documents/:id?/:print?"
              component={OrderDocument}
            />
            <Route
              exact
              path="/orders/overviews/:orderOverviewId/:print?"
              component={OrderOverviewDocument}
            />
            <Route
              exact
              path="/order-drafts/:draftId/documents/:id?/:print?"
              component={DraftDocument}
            />
            <Route
              exact
              path="/consolidated-invoices/:tab([a-z]+)?"
              component={ConsolidatedInvoicesList}
            />
            <Route
              exact
              path="/consolidated-invoices/:consolidatedInvoiceId/documents/:id/:print?"
              component={ConsolidatedInvoiceDocumentWrap}
            />
            <Route
              exact
              path="/invoices/:invoiceId/documents/:id/:print?"
              component={InvoiceDocument}
            />
            <Route
              exact
              path="/invoices-new/:invoiceId([0-9]+)/:tab?"
              component={Invoice}
            />
            <Route
              exact
              path="/invoices/:invoiceId([0-9]+)/:tab?"
              component={InvoiceForm}
            />
            <Route
              exact
              path="/invoices/:tab([\w\d\-]+)?"
              component={EntityTypeInvoiceList}
            />
            <Route
              exact
              path="/selections/:tab([\w\d\-]+)?"
              component={SelectionsList}
            />
            <Route
              exact
              path="/invoices-new/:invoiceId/documents/:id/:print?"
              component={InvoiceDocumentV2}
            />
            <Route
              exact
              path="/credit-notes/:creditNoteId/documents/:id/:print?"
              component={CreditNoteDocument}
            />
            <Route
              exact
              path="/credit-notes/:creditNoteId([0-9]+)/:tab?"
              component={CreditNoteWrapper}
            />
            <Route
              exact
              path="/credit-notes-new/:creditNoteId([0-9]+)/:tab?"
              component={CreditNote}
            />
            <Route
              exact
              path="/credit-notes-new/:creditNoteId/documents/:id/:print?"
              component={CreditNoteDocumentV2}
            />
            <Route
              exact
              path="/prepayments/:tab([\w\d\-]+)?"
              component={PrepaymentsList}
            />
            <Route
              exact
              path="/orders/:orderId/shipments/new/:tab?"
              component={ShipmentProfile}
            />
            <Route
              exact
              path="/orders/:orderId/shipments/:shipmentId/documents/:id?/:print?"
              component={ShipmentDocument}
            />
            <Route
              exact
              path="/shipments/:shipmentId([0-9]+)/:tab?"
              component={ShipmentProfile}
            />
            <Route
              exact
              path="/shipments/delivery-notes/:deliveryNoteId/documents/:id?/:print?"
              component={ShipmentDeliveryNoteDocument}
            />
            <Route
              exact
              path="/shipments/list/:tab([\w\d\-]+)?"
              component={ShipmentList}
            />
            <Route
              exact
              path="/shipments/planner"
              component={ShippingPlanner}
            />
            <Route
              exact
              path="/shipments/planner/:shippingPlanId?"
              component={ShippingPlanner}
            />
            <Route
              exact
              path="/orders/:orderId/prepayments/:prepaymentId"
              component={PrepaymentForm}
            />
            <Route
              exact
              path="/orders/:orderId/prepayments/:prepaymentId/documents/:id/:print?"
              component={PrepaymentDocument}
            />
            <Route exact path="/price-lists" component={PriceLists} />
            <Route
              exact
              path="/price-lists/:priceListId"
              component={PriceListEditor}
            />
            <Route exact path="/product-groups" component={ProductGroups} />
            <Route exact path="/products/add" component={AddProduct} />
            <Route exact path="/products/edit" component={EditBulkProduct} />
            <Route exact path="/products/import" component={ProductImporter} />
            <Route
              exact
              path="/products/import/update-ids"
              component={ProductImporterUpdateIds}
            />
            <Route
              exact
              path="/products/import/delete-products"
              component={ProductImporterDelete}
            />
            <Route
              exact
              path="/products/images/import"
              component={ProductImageImporter}
            />
            <Route
              exact
              path="/products/images-import"
              component={ImageImportList}
            />
            <Route
              exact
              path="/products/images-import/:imageImportId([0-9]+)"
              component={ImageImportView}
            />
            <Route
              exact
              path="/products/:productId([0-9]+)/:tab?/:subTab?"
              component={ProductProfile}
            />
            <Route
              exact
              path="/products/:tab([\w\-\_]+)?"
              component={ProductsList}
            />
            /* Showrooms */
            <Route exact path="/showrooms" component={ShowroomList} />
            <Route
              exact
              path="/showrooms/:showroomId"
              component={ShowroomEdit}
            />
            <Route
              exact
              path="/showrooms/:showroomId/submissions/:tab?"
              component={ShowroomSubmissions}
            />
            <Route
              exact
              path="/showrooms/:showroomId/print"
              component={ShowroomDocument}
            />
            <Route
              exact
              path="/showrooms/selections/:showroomSelectionId/print"
              component={ShowroomSelectionDocument}
            />
            <Route
              exact
              path="/showrooms/:showroomId/tracking/:tab?"
              component={ShowroomTracking}
            />
            <Route exact path="/production" component={ProductionDashboard} />
            <Route
              exact
              path="/production/parts/:partId?/:tab?"
              component={Parts}
            />
            <Route
              exact
              path="/production/parts/:partId?/:tab?/:subEntity?"
              component={Parts}
            />
            <Route exact path="/production/projects" component={Projects} />
            {/* delivery notes has to come before PO list, because delivery note route will also match PO list */}
            <Route
              exact
              path="/production/orders/delivery-notes/:tab([a-z\-\_]+)?"
              component={ProductionOrderDeliveryNoteList}
            />
            {/* overhead cost invoices has to come before PO list, because delivery note route will also match PO list */}
            <Route
              exact
              path="/production/overhead-cost-invoices/:tab([a-z\-\_]+)?"
              component={OverheadCostInvoiceList}
            />
            <Route
              exact
              path="/production/overhead-cost-invoices/:overheadCostInvoiceId([0-9]+)/:tab?"
              component={OverheadCostInvoiceProfile}
            />
            {/* overhead cost invoices has to come before PO list, because delivery note route will also match PO list */}
            <Route
              exact
              path="/production/production-prepayments/:tab([a-z\-\_]+)?"
              component={ProductionPrepaymentList}
            />
            <Route
              exact
              path="/production/production-prepayments/:productionPrepaymentId([0-9]+)/:tab?"
              component={ProductionPrepaymentProfile}
            />
            <Route
              exact
              path="/production/delivery-chart"
              component={DeliveryChart}
            />
            <Route
              exact
              path="/production/orders/:id([0-9]+)"
              component={ProductionOrder}
            />
            <Route
              exact
              path="/production/orders/:productionOrderId([0-9]+)/documents/:id/:print?"
              component={ProductionOrderDocument}
            />
            <Route
              exact
              path="/production/orders/delivery-notes/:deliveryNoteId([0-9]+)/documents/:id/:print?"
              component={ProductionOrderDeliveryNoteDocument}
            />
            <Route
              exact
              path="/production/orders/delivery-notes/:deliveryNoteId([0-9]+)/packing/instructions/:print?"
              component={ProductionDeliveryPackingInstructions}
            />
            <Route
              exact
              path="/production/orders/delivery-notes/:deliveryNoteId([0-9]+)/packing/colli-labels/:print?"
              component={ProductionDeliveryColliLabels}
            />
            <Route
              exact
              path="/production/orders/delivery-notes/:productionOrderDeliveryNoteId([0-9]+)/:tab([^/]+)?/:subTab?"
              component={ProductionOrderDeliveryNote}
            />
            <Route
              exact
              path="/production/orders/new-delivery-notes/:productionOrderDeliveryNoteId([0-9]+)/:tab?/:subTab?"
              component={ProductionOrderDeliveryNote}
            />
            {/* has to come after all detail views */}
            <Route
              exact
              path="/production/orders/:tab([\w\d\-]+)?"
              component={ProductionOrderList}
            />
            <Route
              exact
              path="/production/suppliers/:tab([a-z]+)?"
              component={SupplierList}
            />
            <Route
              exact
              path="/production/suppliers/:supplierId/:tab?"
              component={SupplierProfile}
            />
            <Route exact path="/reports" component={ReportGenerator} />
            <Route
              exact
              path="/reports/order_count"
              component={ReportOrderCount}
            />
            <Route
              exact
              path="/reports/order_totals"
              component={ReportOrderTotals}
            />
            <Route
              exact
              path="/reports/intrastat"
              component={ReportIntrastat}
            />
            <Route
              exact
              path="/reports/one-stop-shop"
              component={ReportOneStopShop}
            />
            <Route
              exact
              path="/reports/commission/:tab([a-z]+)?"
              component={ReportCommissionList}
            />
            <Route
              exact
              path="/reports/commission/:id([0-9]+)/:print?"
              component={ReportCommissionEditor}
            />
            <Route exact path="/reports/claims" component={ReportClaims} />
            <Route exact path="/reports/revenue" component={ReportRevenue} />
            <Route exact path="/reports/rankings" component={ReportRankings} />
            <Route
              exact
              path="/reports/warehousing"
              component={ReportWarehousing}
            />
            <Route
              exact
              path="/reports/cancellations"
              component={ReportCancellations}
            />
            <Route
              exact
              path="/reports/sales-goals/:action?/:id?"
              component={SalesGoals}
            />
            <Route
              exact
              path="/reports/unpaid-invoices"
              component={UnpaidInvoicesDashboard}
            />
            <Route exact path="/returns/:id([0-9]+)" component={ReturnForm} />
            <Route
              exact
              path="/returns/:returnId/documents/:id?/:print?"
              component={ReturnDocument}
            />
            <Route
              exact
              path="/returns/:tab([\w\d\-]+)?"
              component={ReturnsList}
            />
            <Route exact path="/segmentation" component={SegmentationList} />
            <Route
              exact
              path="/segmentation/:id"
              component={SegmentationEditor}
            />
            <Route exact path="/launch-events" component={LaunchEventsPage} />
            <Route
              exact
              path="/launch-events/add"
              component={AddLaunchEventsPage}
            />
            <Route
              exact
              path="/launch-events/:id([0-9]+)"
              component={AddLaunchEventsPage}
            />
            <Route exact path="/fulfilment" component={FulfilmentDashboard} />
            <Route exact path="/subbrands" component={Subbrands} />
            <Route exact path="/tasks/:tab([a-z-]+)?" component={TaskList} />
            <Route
              exact
              path="/news/:newsId([0-9]+)?"
              component={NewsArchive}
            />
            <Route exact path="/prospects/import" component={ProspectImport} />
            <Route
              exact
              path="/prospects/:tab([a-z]+)?"
              component={ProspectList}
            />
          </Switch>
        </Template>
      </DomContainer>
    )
  })
)

const FileBankV2 = props => {
  return (
    <DomContainer {...props}>
      <FileBankV2Session {...props}>
        <Switch>
          <Route
            exact
            path="/file-bank-new/:brandId/search"
            component={FileBankV2Search}
          />
          <Route
            exact
            path="/file-bank-new/:brandId/selection/:selectionId?"
            component={FileBankV2Selection}
          />
          {/* Intentionally last route & without exact to also catch paths like /file-bank/10919/123/456/789 */}
          <Route
            path="/file-bank-new/:brandId"
            component={FileBankV2ShowFolder}
          />
        </Switch>
      </FileBankV2Session>
    </DomContainer>
  )
}

const Warehouse = props => {
  return (
    <DomContainer {...props}>
      <WarehouseErrorBoundary>
        <WarehouseTemplate {...props}>
          <Route exact path="/warehouse" component={WarehouseDashboard} />
          <Route
            exact
            path="/warehouse/shipments"
            component={WarehouseShipments}
          />
          <Route
            exact
            path="/warehouse/shipments/:shipmentId"
            component={WarehouseShipment}
          />
          <Route
            exact
            path="/warehouse/packing-routes/scan/:packingRouteId"
            component={WarehousePackingRouteScan}
          />
          <Route
            exact
            path="/warehouse/packing-routes/invoice/:packingRouteId"
            component={WarehousePackingRouteInvoice}
          />
          <Route
            exact
            path="/warehouse/shipments/scan/:shipmentId"
            component={WarehouseScan}
          />
          <Route
            exact
            path="/warehouse/shipments/:shipmentId/list"
            component={WarehouseShipmentList}
          />
          <Route
            exact
            path="/warehouse/inventory"
            component={WarehouseInventory}
          />
          <Route
            exact
            path="/warehouse/inventory/stock-counts"
            component={WarehouseStockCounts}
          />
          <Route
            exact
            path="/warehouse/inventory/stock-counts/new"
            component={WarehouseNewStockCount}
          />
          <Route
            exact
            path="/warehouse/inventory/stock-counts/:stockCountId([0-9]+)"
            component={WarehouseStockCountScan}
          />
          <Route
            exact
            path="/warehouse/inventory/locations/:warehouseLocationId"
            component={WarehouseLocation}
          />
          <Route
            exact
            path="/warehouse/inventory/product/:productId"
            component={WarehouseProduct}
          />
          <Route
            exact
            path="/warehouse/inventory/variant/:variantId"
            component={WarehouseVariant}
          />
          <Route
            exact
            path="/warehouse/production-orders/new"
            component={WarehouseNewProductionOrderDeliveryNote}
          />
          <Route
            exact
            path="/warehouse/production-orders/:productionOrderDeliveryNoteId([0-9]+)"
            component={WarehouseProductionOrderDeliveryNoteScan}
          />
          <Route
            exact
            path="/warehouse/production-orders/:productionOrderDeliveryNoteId/finish"
            component={WarehouseProductionOrderDeliveryNoteScanFinish}
          />
          <Route
            exact
            path="/warehouse/production-orders"
            component={WarehouseProductionOrderDeliveryNotes}
          />
          <Route exact path="/warehouse/returns" component={WarehouseReturns} />
          <Route exact path="/warehouse/search" component={WarehouseSearch} />
          <Route
            exact
            path="/warehouse/navigation"
            component={WarehouseNavigation}
          />
          <Route
            exact
            path="/warehouse/navigation/application-info"
            component={WarehouseApplicationInfo}
          />
          <Route
            exact
            path="/warehouse/navigation/computers"
            component={WarehouseComputerList}
          />
          <Route
            exact
            path="/warehouse/navigation/sub-users"
            component={WarehouseSubUserListScreen}
          />
          <Route
            exact
            path="/warehouse/navigation/brand"
            component={WarehouseBrand}
          />
          <Route
            exact
            path="/warehouse/invoice/:shipmentId"
            component={WarehouseInvoice}
          />
        </WarehouseTemplate>
      </WarehouseErrorBoundary>
    </DomContainer>
  )
}

const Showroom = props => {
  return (
    <DomContainer {...props}>
      <ShowroomSession {...props}>
        <Route
          exact
          path="/showroom/:brandId/:showroomId/:showroomHash"
          component={ShowroomView}
        />
      </ShowroomSession>
    </DomContainer>
  )
}

const Webshop = props => {
  return (
    <DomContainer {...props}>
      <SplitComponent
        false={
          <WebshopSession {...props}>
            <Route exact path="/shop/:brandId" component={WebshopLanding} />
            <Route
              exact
              path="/shop/:brandId/cart/:tab?"
              component={WebshopCart}
            />
            <Route
              exact
              path="/shop/:brandId/favorites"
              component={WebshopFavorites}
            />
            <Route
              exact
              path="/shop/:brandId/search"
              component={WebshopQuerySearch}
            />
            <Route
              exact
              path="/shop/:brandId/search/:linkId"
              component={WebshopLinkSearch}
            />
            <Route
              exact
              path="/shop/:brandId/show/:productId"
              component={WebshopProduct}
            />
            <Route
              exact
              path="/shop/:brandId/page/:pageId"
              component={WebshopPage}
            />
            <Route
              exact
              path="/shop/:brandId/linesheet/:linesheetPublicId"
              component={WebshopLinesheet}
            />
          </WebshopSession>
        }
        entityOrUser
        splitKey="webshop_2"
        true={
          <WebshopSessionV2 {...props}>
            <Route exact path="/shop/:brandId" component={WebshopV2Landing} />
            <Route
              exact
              path="/shop/:brandId/landing"
              component={WebshopV2Landing}
            />
            <Route
              exact
              path="/shop/:brandId/cart/:tab?"
              component={WebshopV2Cart}
            />
            <Route
              exact
              path="/shop/:brandId/favorites"
              component={WebshopV2Favorites}
            />
            <Route
              exact
              path="/shop/:brandId/search"
              component={WebshopV2QuerySearch}
            />
            <Route
              exact
              path="/shop/:brandId/search/:linkId([0-9]+)(/show)?/:productId?"
              component={WebshopV2LinkSearch}
            />
            <Route
              exact
              path="/shop/:brandId/show/:productId"
              component={WebshopV2Product}
            />
            <Route
              exact
              path="/shop/:brandId/page/:pageId"
              component={WebshopV2Page}
            />
            <Route
              exact
              path="/shop/:brandId/sprii/:linkId"
              component={WebshopV2Sprii}
            />
            <Route
              exact
              path="/shop/:brandId/linesheet/:linesheetPublicId"
              component={WebshopV2Linesheet}
            />
          </WebshopSessionV2>
        }
        {...props}
      />
    </DomContainer>
  )
}

const AppStore = UserIsAuthenticated(
  UserIsSetup(props => {
    return (
      <DomContainer {...props}>
        <Template SideBar={SettingsNavigation} settingsPage {...props}>
          <Switch>
            <Route exact path="/apps" component={AppStoreLanding} />
            <Route exact path="/apps/3pl" component={AppStoreGeneric3pl} />
            <Route exact path="/apps/b2c" component={AppStoreGenericB2c} />
            <Route exact path="/apps/pos" component={AppStoreGenericPos} />
            <Route
              exact
              path="/apps/business-central"
              component={AppStoreBusinessCentral}
            />
            <Route
              exact
              path="/apps/create2stay"
              component={AppStoreCreate2Stay}
            />
            <Route
              exact
              path="/apps/csv-exporter"
              component={AppStoreCsvExporter}
            />
            <Route
              exact
              path="/apps/csv-importer"
              component={AppStoreCsvImporter}
            />
            <Route
              exact
              path="/apps/customer-registration"
              component={AppStoreCustomerRegistration}
            />
            <Route
              exact
              path="/apps/coolrunner"
              component={AppStoreCoolrunner}
            />
            <Route exact path="/apps/dnb" component={AppStoreDnb} />
            <Route exact path="/apps/riverty" component={AppStoreRiverty} />
            <Route
              exact
              path="/apps/riverty/transfer-document/:batchId"
              component={AppStoreRivertyTransferDocument}
            />
            <Route
              exact
              path="/apps/data-export"
              component={AppStoreDataExport}
            />
            <Route exact path="/apps/delogue" component={AppStoreDelogue} />
            <Route
              exact
              path="/apps/eaccounting"
              component={AppStoreEaccounting}
            />
            <Route exact path="/apps/economic" component={AppStoreEconomic} />
            <Route exact path="/apps/exact" component={AppStoreExact} />
            <Route exact path="/apps/faire" component={AppStoreFaire} />
            <Route exact path="/apps/file-bank" component={AppStoreFileBank} />
            <Route exact path="/apps/fortnox" component={AppStoreFortnox} />
            <Route exact path="/apps/fopower" component={AppStoreFoPower} />
            <Route exact path="/apps/epay" component={AppStoreEpay} />
            <Route exact path="/apps/quickpay" component={AppStoreQuickpay} />
            <Route exact path="/apps/joor" component={AppStoreJoor} />
            <Route exact path="/apps/navision" component={AppStoreNavision} />
            <Route exact path="/apps/ongoing" component={AppStoreOngoing} />
            <Route exact path="/apps/dsv" component={AppStoreDsv} />
            <Route exact path="/apps/rhenus" component={AppStoreRhenus} />
            <Route
              exact
              path="/apps/external-data-mapping"
              component={AppStoreExternalDataMapping}
            />
            <Route exact path="/apps/edi" component={AppStoreEdi} />
            <Route exact path="/apps/hlt" component={AppStoreHlt} />
            <Route exact path="/apps/mailchimp" component={AppStoreMailchimp} />
            <Route exact path="/apps/mailmak" component={AppStoreMailMak} />
            <Route
              exact
              path="/apps/midtfactoring"
              component={AppStoreMidtFactoring}
            />
            <Route
              exact
              path="/apps/production"
              component={AppStoreProduction}
            />
            <Route exact path="/apps/shipments" component={AppStoreShipments} />
            <Route exact path="/apps/svea" component={AppStoreSvea} />
            <Route
              exact
              path="/apps/primecargo"
              component={AppStorePrimeCargo}
            />
            <Route exact path="/apps/picqer" component={AppStorePicqer} />
            <Route
              exact
              path="/apps/public-webshop"
              component={AppStorePublicWebshop}
            />
            <Route
              exact
              path="/apps/quickbooks"
              component={AppStoreQuickbooks}
            />
            <Route exact path="/apps/sendcloud" component={AppStoreSendcloud} />
            <Route exact path="/apps/shipmondo" component={AppStoreShipmondo} />
            <Route
              exact
              path="/apps/shopify/:subPath?"
              component={AppStoreShopify}
            />
            <Route
              exact
              path="/apps/shipping-cost"
              component={AppStoreShippingCost}
            />
            <Route
              exact
              path="/apps/systemtransport"
              component={AppStoreSystemtransport}
            />
            <Route exact path="/apps/tmc" component={AppStoreTmc} />
            <Route
              exact
              path="/apps/thetis-pack"
              component={AppStoreThetisPack}
            />
            <Route exact path="/apps/tripletex" component={AppStoreTripletex} />
            <Route
              exact
              path="/apps/webshipper"
              component={AppStoreWebshipper}
            />
            <Route
              exact
              path="/apps/woocommerce"
              component={AppStoreWoocommerce}
            />
            <Route
              exact
              path="/apps/woocommerce/update-skus"
              component={AppStoreWoocommerceUpdateSkusImporter}
            />
            <Route exact path="/apps/klaviyo" component={AppStoreKlaviyo} />
            <Route
              exact
              path="/apps/pakkecenternord"
              component={AppStorePakkecenterNord}
            />
            <Route exact path="/apps/sprii" component={AppStoreSprii} />
            <Route exact path="/apps/xero" component={AppStoreXero} />
            <Route exact path="/apps/zalando" component={AppStoreZalando} />
          </Switch>
        </Template>
      </DomContainer>
    )
  })
)

const SettingsContactInformation = asyncComponent(() =>
  import('./settings/components/ContactInformationForm').then(loadRoute())
)
const SettingsAccounting = asyncComponent(() =>
  import('./settings/brand/components/AccountingSettings').then(loadRoute())
)
const SettingsAddresses = asyncComponent(() =>
  import('./settings/brand/components/Addresses').then(loadRoute())
)
const SettingsAgencies = asyncComponent(() =>
  import('./settings/brand/components/Agencies').then(loadRoute())
)
const SettingsApiKey = asyncComponent(() =>
  import('./settings/components/ApiKeys').then(loadRoute())
)
const SettingsAttributes = asyncComponent(() =>
  import('./settings/brand/components/AttributesSettings').then(loadRoute())
)
const SettingsAccountingYears = asyncComponent(() =>
  import('./settings/brand/components/AccountingYearSettings').then(loadRoute())
)
const SettingsBankAccounts = asyncComponent(() =>
  import('./bank-accounts/BankAccountsList').then(loadRoute())
)
const SettingsColliTypes = asyncComponent(() =>
  import('./settings/brand/components/ColliTypesSettings').then(loadRoute())
)
const SettingsCountrySettingsList = asyncComponent(() =>
  import(
    './settings/brand/components/CountrySettings/CountrySettingsList'
  ).then(loadRoute())
)
const SettingsCountrySettingsForm = asyncComponent(() =>
  import(
    './settings/brand/components/CountrySettings/CountrySettingsForm'
  ).then(loadRoute())
)
const SettingsCustomFields = asyncComponent(() =>
  import('./custom-fields/components/CustomFieldsList').then(loadRoute())
)
const SettingsDocuments = asyncComponent(() =>
  import('./settings/brand/components/Documents').then(loadRoute())
)
const SettingsEmailSettings = asyncComponent(() =>
  import('./settings/brand/components/EmailSettings').then(loadRoute())
)
const SettingsEmailSettingsEmail = asyncComponent(() =>
  import('./settings/brand/components/EmailSettingsEmail').then(loadRoute())
)
const SettingsExchangeRates = asyncComponent(() =>
  import('./settings/brand/components/ExchangeRates').then(loadRoute())
)
const SettingsInternalCompaniesSettings = asyncComponent(() =>
  import('./settings/brand/components/InternalCompaniesSettings').then(
    loadRoute()
  )
)
const SettingsInternalCompaniesRoutingSettings = asyncComponent(() =>
  import('./settings/brand/components/InternalCompaniesRoutingSettings').then(
    loadRoute()
  )
)
const SettingsFileTags = asyncComponent(() =>
  import('./settings/brand/components/FileTagsSettings').then(loadRoute())
)
const SettingsLocalization = asyncComponent(() =>
  import('./settings/localization').then(loadRoute())
)
const SettingsMisc = asyncComponent(() =>
  import('./settings/brand/components/Misc').then(loadRoute())
)
const SettingsMySettings = asyncComponent(() =>
  import('./settings/components/MySettingsForm').then(loadRoute())
)
const SettingsNumberSeries = asyncComponent(() =>
  import('./settings/brand/components/NumberSeries').then(loadRoute())
)
const SettingsOrderChannelsList = asyncComponent(() =>
  import('./order-channels/components/OrderChannelsList').then(loadRoute())
)
const SettingsOrderChannelsForm = asyncComponent(() =>
  import('./order-channels/components/OrderChannelsForm').then(loadRoute())
)
const SettingsPriceGroupsList = asyncComponent(() =>
  import('./settings/brand/components/PriceGroups/PriceGroupsList').then(
    loadRoute()
  )
)
const SettingsPriceGroupForm = asyncComponent(() =>
  import('./settings/brand/components/PriceGroups/PriceGroupForm').then(
    loadRoute()
  )
)
const SettingsPrinters = asyncComponent(() =>
  import('./settings/brand/components/PrinterSettings').then(loadRoute())
)
const SettingsProductAccountingGroups = asyncComponent(() =>
  import('./settings/brand/components/ProductAccountingGroupSettings').then(
    loadRoute()
  )
)
const SettingsSales = asyncComponent(() =>
  import('./settings/brand/components/Sales').then(loadRoute())
)
const SettingsShippingCodeConversion = asyncComponent(() =>
  import(
    './settings/brand/components/ShippingCodeConversionSettings/ShippingCodeConversionSettings'
  ).then(loadRoute())
)
const SettingsImages = asyncComponent(() =>
  import('./settings/brand/components/Images').then(loadRoute())
)
const SettingsInventory = asyncComponent(() =>
  import('./settings/brand/components/Inventory').then(loadRoute())
)
const SettingsInventoryLocationsList = asyncComponent(() =>
  import(
    './settings/brand/components/InventoryLocations/InventoryLocationsList'
  ).then(loadRoute())
)
const SettingsInventoryBatches = asyncComponent(() =>
  import('./settings/brand/components/InventoryBatches').then(loadRoute())
)
const SettingsInventoryLocationsForm = asyncComponent(() =>
  import(
    './settings/brand/components/InventoryLocations/InventoryLocationForm'
  ).then(loadRoute())
)
const SettingsFonts = asyncComponent(() =>
  import('./settings/brand/components/FontsSettings').then(loadRoute())
)
const SettingsForwarders = asyncComponent(() =>
  import('./settings/brand/components/ForwardersSettings').then(loadRoute())
)
const SettingsModeOfTransportations = asyncComponent(() =>
  import('./settings/brand/components/ModeOfTransportationsSettings').then(
    loadRoute()
  )
)
const SettingsReplenishment = asyncComponent(() =>
  import(
    './settings/brand/components/ReplenishmentSettings/ReplenishmentSettings'
  ).then(loadRoute())
)
const SettingsInventoryAlertPolicySettings = asyncComponent(() =>
  import(
    './settings/brand/components/ReplenishmentSettings/InventoryAlertPolicySettings'
  ).then(loadRoute())
)
const SettingsTablet = asyncComponent(() =>
  import('./settings/brand/components/Tablet').then(loadRoute())
)
const SettingsWarehouseAdjustmentCodes = asyncComponent(() =>
  import('./settings/brand/components/WarehouseAdjustmentCodesSettings').then(
    loadRoute()
  )
)
const SettingsWarehouseLocations = asyncComponent(() =>
  import('./settings/brand/components/WarehouseLocations').then(loadRoute())
)
const SettingsWarehouseManagement = asyncComponent(() =>
  import('./settings/brand/components/WarehouseManagementSettings').then(
    loadRoute()
  )
)
const SettingsWebshop = asyncComponent(() =>
  import('./settings/brand/components/Webshop').then(loadRoute())
)
const SettingsWebshopDesign = asyncComponent(() =>
  import('./settings/brand/components/WebshopDesignSettings').then(loadRoute())
)
const SettingsWebshopProductLabels = asyncComponent(() =>
  import('./settings/brand/components/WebshopProductLabels').then(loadRoute())
)
const SettingsWebshopNavigation = asyncComponent(() =>
  import('./settings/brand/components/WebshopNavigationSettings').then(
    loadRoute()
  )
)
const SettingsPages = asyncComponent(() =>
  import('./settings/brand/components/Pages').then(loadRoute())
)
const SettingsProductLocales = asyncComponent(() =>
  import('./settings/brand/components/ProductLocales').then(loadRoute())
)
const SettingsTags = asyncComponent(() =>
  import('./tags/TagList').then(loadRoute())
)
const SettingsPaymentTerms = asyncComponent(() =>
  import('./payment-terms/PaymentTermsList').then(loadRoute())
)
const SettingsUsersList = asyncComponent(() =>
  import('./users/UsersList').then(loadRoute())
)
const SettingsAddUser = asyncComponent(() =>
  import('./users/components/AddUser').then(loadRoute())
)
const SettingsEditUser = asyncComponent(() =>
  import('./users/components/EditUser').then(loadRoute())
)
const SettingsRoles = asyncComponent(() =>
  import('./users/components/RoleSettings').then(loadRoute())
)
const SettingsProductTables = asyncComponent(() =>
  import('./settings/brand/components/ProductTableSettings').then(loadRoute())
)
const SettingsClaims = asyncComponent(() =>
  import('./settings/brand/components/ClaimSettings').then(loadRoute())
)
const SettingsCancellationReasons = asyncComponent(() =>
  import('./settings/brand/components/CancellationReasonsSettings').then(
    loadRoute()
  )
)
const SettingsReturnPortalList = asyncComponent(() =>
  import('./returns/components/ReturnPortalSettings/ReturnPortalList').then(
    loadRoute()
  )
)
const SettingsReturnPortalForm = asyncComponent(() =>
  import('./returns/components/ReturnPortalSettings/ReturnPortalForm').then(
    loadRoute()
  )
)
const SettingsWorkflowsDashboard = asyncComponent(() =>
  import('./workflows/components/WorkflowsDashboard').then(loadRoute())
)
const SettingsWorkflowEditor = asyncComponent(() =>
  import('./workflows/components/WorkflowEditor').then(loadRoute())
)
const SettingsOutboundWebhooks = asyncComponent(() =>
  import('./outbound-webhooks/OutboundWebhooksList').then(loadRoute())
)

const Settings = UserIsAuthenticated(
  UserIsSetup(props => {
    return (
      <DomContainer {...props}>
        <Template SideBar={SettingsNavigation} settingsPage {...props}>
          <Route
            exact
            path="/settings"
            component={SettingsContactInformation}
          />
          <Route
            exact
            path="/settings/addresses"
            component={SettingsAddresses}
          />
          <Route exact path="/settings/agencies" component={SettingsAgencies} />
          <Route
            exact
            path="/settings/outbound-webhooks"
            component={SettingsOutboundWebhooks}
          />
          <Route exact path="/settings/api-keys" component={SettingsApiKey} />
          <Route
            exact
            path="/settings/attributes"
            component={SettingsAttributes}
          />
          <Route
            exact
            path="/settings/accounting"
            component={SettingsAccounting}
          />
          <Route
            exact
            path="/settings/accounting-years/:accountingYearId([\d]+)?"
            component={SettingsAccountingYears}
          />
          <Route
            exact
            path="/settings/bank-accounts"
            component={SettingsBankAccounts}
          />
          <Route
            exact
            path="/settings/colli-types"
            component={SettingsColliTypes}
          />
          <Route
            exact
            path="/settings/country-settings"
            component={SettingsCountrySettingsList}
          />
          <Route
            exact
            path="/settings/country-settings/:type/:id"
            component={SettingsCountrySettingsForm}
          />
          <Route
            exact
            path="/settings/custom-fields"
            component={SettingsCustomFields}
          />
          <Route
            exact
            path="/settings/documents"
            component={SettingsDocuments}
          />
          <Route
            exact
            path="/settings/emails"
            component={SettingsEmailSettings}
          />
          <Route
            exact
            path="/settings/emails/:templateGroupId/:customizationId?"
            component={SettingsEmailSettingsEmail}
          />
          <Route
            exact
            path="/settings/exchange-rates"
            component={SettingsExchangeRates}
          />
          <Route
            exact
            path="/settings/internal-companies"
            component={SettingsInternalCompaniesSettings}
          />
          <Route
            exact
            path="/settings/internal-companies/routing-settings"
            component={SettingsInternalCompaniesRoutingSettings}
          />
          <Route
            exact
            path="/settings/localization"
            component={SettingsLocalization}
          />
          <Route exact path="/settings/misc" component={SettingsMisc} />
          <Route exact path="/settings/my" component={SettingsMySettings} />
          <Route
            exact
            path="/settings/order-channels"
            component={SettingsOrderChannelsList}
          />
          <Route
            exact
            path="/settings/order-channels/:orderChannelId"
            component={SettingsOrderChannelsForm}
          />
          <Route exact path="/settings/printers" component={SettingsPrinters} />
          <Route
            exact
            path="/settings/product-accounting-groups"
            component={SettingsProductAccountingGroups}
          />
          <Route
            exact
            path="/settings/price-groups"
            component={SettingsPriceGroupsList}
          />
          <Route
            exact
            path="/settings/price-groups/:priceGroupId"
            component={SettingsPriceGroupForm}
          />
          <Route
            exact
            path="/settings/shipping-code-conversions"
            component={SettingsShippingCodeConversion}
          />
          <Route
            exact
            path="/settings/number-series"
            component={SettingsNumberSeries}
          />
          <Route exact path="/settings/sales" component={SettingsSales} />
          <Route
            exact
            path="/settings/inventory-locations"
            component={SettingsInventoryLocationsList}
          />
          <Route
            exact
            path="/settings/inventory-batches"
            component={SettingsInventoryBatches}
          />
          <Route
            exact
            path="/settings/inventory-locations/new"
            component={SettingsInventoryLocationsForm}
          />
          <Route
            exact
            path="/settings/inventory-locations/:locationId([0-9]+)"
            component={SettingsInventoryLocationsForm}
          />
          <Route
            exact
            path="/settings/inventory-alert-policies/new"
            component={SettingsInventoryAlertPolicySettings}
          />
          <Route
            exact
            path="/settings/inventory-alert-policies/:inventoryAlertPolicyId([0-9]+)"
            component={SettingsInventoryAlertPolicySettings}
          />
          <Route
            exact
            path="/settings/replenishment"
            component={SettingsReplenishment}
          />
          <Route exact path="/settings/stock" component={SettingsInventory} />
          <Route exact path="/settings/tablet" component={SettingsTablet} />
          <Route
            exact
            path="/settings/warehouse-management"
            component={SettingsWarehouseManagement}
          />
          <Route exact path="/settings/webshop" component={SettingsWebshop} />
          <Route
            exact
            path="/settings/webshop/design"
            component={SettingsWebshopDesign}
          />
          <Route
            exact
            path="/settings/webshop-product-labels"
            component={SettingsWebshopProductLabels}
          />
          <Route
            exact
            path="/settings/webshop/navigation"
            component={SettingsWebshopNavigation}
          />
          <Route exact path="/settings/pages" component={SettingsPages} />
          <Route
            exact
            path="/settings/pages/:id/:print"
            component={SettingsPages}
          />
          <Route
            exact
            path="/settings/product-tables"
            component={SettingsProductTables}
          />
          <Route exact path="/settings/images" component={SettingsImages} />
          <Route
            exact
            path="/settings/product-locales"
            component={SettingsProductLocales}
          />
          <Route exact path="/settings/tags" component={SettingsTags} />
          <Route
            exact
            path="/settings/file-tags"
            component={SettingsFileTags}
          />
          <Route
            exact
            path="/settings/payment-terms"
            component={SettingsPaymentTerms}
          />
          <Route exact path="/settings/users" component={SettingsUsersList} />
          <Route exact path="/settings/users/add" component={SettingsAddUser} />
          <Route
            exact
            path="/settings/users/edit/:id"
            component={SettingsEditUser}
          />
          <Route exact path="/settings/roles" component={SettingsRoles} />
          <Route exact path="/settings/claims" component={SettingsClaims} />
          <Route
            exact
            path="/settings/cancellation-reasons"
            component={SettingsCancellationReasons}
          />
          <Route exact path="/settings/fonts" component={SettingsFonts} />
          <Route
            exact
            path="/settings/forwarders"
            component={SettingsForwarders}
          />
          <Route
            exact
            path="/settings/mode-of-transportations"
            component={SettingsModeOfTransportations}
          />
          <Route
            exact
            path="/settings/return-portals"
            component={SettingsReturnPortalList}
          />
          <Route
            exact
            path="/settings/return-portals/:returnPortalId(new|[0-9]+)"
            component={SettingsReturnPortalForm}
          />
          <Route
            exact
            path="/settings/warehouse-adjustment-codes"
            component={SettingsWarehouseAdjustmentCodes}
          />
          <Route
            exact
            path="/settings/warehouse-locations"
            component={SettingsWarehouseLocations}
          />
          <Route
            exact
            path="/settings/workflows"
            component={SettingsWorkflowsDashboard}
          />
          <Route
            exact
            path="/settings/workflows/:id"
            component={SettingsWorkflowEditor}
          />
        </Template>
      </DomContainer>
    )
  })
)

const Admin = UserIsAuthenticated(
  UserIsAdmin(
    UserIsSetup(props => {
      return (
        <DomContainer {...props}>
          <Template SideBar={AdminNavigation} settingsPage {...props}>
            <Switch>
              <Route
                exact
                path="/admin/accounting-fixing-tools"
                component={AdminAccountingFixingTools}
              />
              <Route exact path="/admin/agents" component={AdminAgentList} />
              <Route exact path="/admin/apps" component={AdminApps} />
              <Route
                exact
                path="/admin/elastic-integrity"
                component={AdminElasticIntegrity}
              />
              <Route exact path="/admin/reports" component={AdminReports} />
              <Route
                exact
                path="/admin/feature-adoption"
                component={AdminFeatureAdoption}
              />
              <Route
                exact
                path="/admin/feature-flags"
                component={AdminFeatureFlags}
              />
              <Route
                exact
                path="/admin/miscellaneous-tools"
                component={AdminMiscellaneousTools}
              />
              <Route exact path="/admin/imports" component={AdminImports} />
              <Route
                exact
                path="/admin/setting-changes"
                component={AdminSettingChanges}
              />
              <Route
                exact
                path="/admin/paying-customers/:tab([\w\d\-]+)?"
                component={AdminPayingCustomers}
              />
              <Route exact path="/admin/news" component={AdminNewsList} />
              <Route exact path="/admin/news/:id" component={AdminNewsForm} />
              <Route
                exact
                path="/admin/news/:id/:preview"
                component={AdminNewsForm}
              />
              <Route exact path="/admin/queue" component={AdminQueue} />
              <Route
                exact
                path="/admin/tablet-app-states"
                component={AdminTabletStates}
              />
              <Route exact path="/admin(/users)?" component={AdminUsers} />
            </Switch>
          </Template>
        </DomContainer>
      )
    })
  )
)

export default Routes
