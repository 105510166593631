/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import RelativeDate from '../../../infrastructure/components/RelativeDate'

import { ConditionalWrap, Tooltip } from '../../shared'

const OrderDeliveryDateLabel = ({ order, textAlign = 'left', ...rest }) => {
  const hasAnyDate =
    order.estimated_delivery ||
    order.estimated_arrival ||
    order.confirmed_delivery ||
    order.requested_delivery
  const hasDeliveryDate =
    !!order.estimated_delivery ||
    !!order.estimated_arrival ||
    !!order.confirmed_delivery

  if (!hasAnyDate) {
    return null
  }

  return (
    <SpanContainer {...rest}>
      {hasDeliveryDate && (
        <div
          style={{
            fontWeight: order.confirmed_delivery ? 'bold' : 'normal',
            textAlign,
          }}
        >
          {order.confirmed_delivery && (
            <span>
              Conf: <RelativeDate colored date={order.confirmed_delivery} />
            </span>
          )}
          {!order.confirmed_delivery && (
            <span>
              {order.estimated_arrival && (
                <>
                  ETA: <RelativeDate colored date={order.estimated_arrival} />
                  <br />
                </>
              )}
              {order.estimated_delivery && (
                <>
                  ETD: <RelativeDate colored date={order.estimated_delivery} />
                </>
              )}
            </span>
          )}
        </div>
      )}
      {order.requested_delivery && (
        <div style={{ textAlign: textAlign }}>
          Req. <RelativeDate colored date={order.requested_delivery} />
        </div>
      )}
    </SpanContainer>
  )
}

export default OrderDeliveryDateLabel

const SpanContainer = styled.div`
  display: inline-block;
`
