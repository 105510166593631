/* @flow */

import api from '../api'

import type {
  ApiResponse,
  Address,
  BrunoRequestOptions,
  DataTableAttribute,
  DataTableRequestOptions,
  Filter,
  Id,
  ProductionOrder,
  ProductionOrderGroup,
  ProductionOrderList,
  SortDirection,
  Supplier,
  SupplierContactPerson,
} from '../types'

export type RequestProductionOrderGroup = {|
  ...ProductionOrder,
  orders?: Array<{ id: Id }>,
  products?: Array<{ id: Id, include: boolean }>,
|}

// Production order group

export const queueProductionOrderGroupCreation = (
  data: $Shape<ProductionOrderGroup>,
  orders: ?Array<{ id: Id }>,
  products: ?Array<{ id: Id }>
) =>
  api.post('/production/orders/queue-creation', {
    body: {
      production_order_group: data,
      orders,
      products,
    },
  })

export const createProductionOrderGroup = (
  data: $Shape<ProductionOrderGroup>,
  orders: ?Array<{ id: Id }>,
  products: ?Array<{ id: Id }>
) =>
  api.post(`/production/order-groups`, {
    body: {
      production_order_group: data,
      orders,
      products,
    },
  })

export const getProductionOrderGroup = (
  productionOrderGroupId: Id,
  options?: Object
) =>
  api.get(`/production/order-groups/${productionOrderGroupId}`, {
    query: options,
  })

export const updateProductionOrderGroup = (
  productionOrderGroupId: Id,
  data: $Shape<RequestProductionOrderGroup>
) =>
  api.put(`/production/order-groups/${productionOrderGroupId}`, {
    body: {
      production_order_group: data,
    },
  })

export const updateProductionOrdersBulk = data =>
  api.put(`/production/orders`, {
    body: {
      production_orders: data,
    },
  })

export const putSalesOrderIntoProduction = data =>
  api.post('/production/need/put-sales-order-into-production', {
    body: data,
  })

// Production orders

export const getProductionOrderDeliveryNotes = (
  options?: BrunoRequestOptions
) =>
  api.get(`/production/delivery-notes`, {
    query: options,
  })

export const createProductionOrderDeliveryNote = (
  productionOrderId: Id,
  data: Object
) =>
  api.post(`/production/delivery-notes`, {
    body: {
      production_order_delivery_note: data,
    },
  })

export const createProductionOrderDeliveryNotesBulk = (
  productionOrderId: Id,
  data: Object
) =>
  api.post(`/production/delivery-notes/bulk`, {
    body: {
      production_order_delivery_notes: data,
    },
  })

export const unreceiveProductionOrderDeliveryNote = (
  productionOrderDeliveryNoteId: Id,
  data
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/unreceive`,
    {
      body: data,
    }
  )

export const getProductionOrders = (options?: BrunoRequestOptions) =>
  api.get(`/production/orders`, {
    query: options,
  })

export const getProductionOrder = (productionOrderId: Id, options?: Object) =>
  api.get(`/production/orders/${productionOrderId}`, {
    query: options,
  })

export const deleteProductionOrder = (productionOrderId: Id) =>
  api.delete(`/production/orders/${productionOrderId}`)

export const getProductionOrderList = (
  options?: DataTableRequestOptions
): Promise<
  ApiResponse<{ production_orders: Array<ProductionOrderList>, total: number }>
> =>
  api.get('/production/orders/list', {
    query: options,
  })

export const getProductionOrderListAttributes = (): Promise<
  ApiResponse<{ attributes: Array<DataTableAttribute> }>
> => api.get('/production/orders/attributes')

export const updateProductionOrderHead = (
  productionOrderId: Id,
  data: $Shape<ProductionOrder>
) =>
  api.put(`/production/orders/${productionOrderId}`, {
    body: {
      production_order: data,
    },
  })

export const updateProductionOrderLines = (
  productionOrderId: Id,
  lines: Array<{ production_order_line_id: Id } & $Shape<ProductionOrderLine>>
) =>
  api.put(`/production/orders/${productionOrderId}/lines`, {
    body: {
      lines: lines,
    },
  })

export const setProductionProductPrices = (
  productionOrderId: Id,
  prices: Array<{ product_id: Id, price: number }>
) =>
  api.put(`/production/orders/${productionOrderId}/prices`, {
    body: {
      prices: prices,
    },
  })

export const exportProductionOrders = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  includeLines: boolean,
  productionOrderPerFile: boolean,
  options?: Object
) =>
  api.post(`/production/orders/export`, {
    body: {
      columns,
      filters,
      include_lines: includeLines,
      production_order_per_file: productionOrderPerFile,
      sort,
      options,
    },
  })

export const calculateProductionPrices = (productionOrderId, productIds) =>
  api.post(`/production/${productionOrderId}/prices`, {
    body: {
      product_ids: productIds,
    },
  })

export const setProductionOrderQuantitiesByExcel = (
  productionOrderId: Id,
  file: string
) =>
  api.post(`/production/orders/${productionOrderId}/set-quantities-excel`, {
    body: {
      file,
    },
  })

export const setProductionOrderDeliveryNoteQuantitiesByExcel = (
  productionOrderDeliveryNoteId: Id,
  file: string
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/set-quantities-excel`,
    {
      body: {
        file,
      },
    }
  )

export const putProductionOrderInProduction = (
  productionOrderId: Id,
  updateData: Object,
  emailData: Object
) =>
  api.post(`/production/orders/${productionOrderId}/put-in-production`, {
    body: {
      production_order: updateData,
      email: emailData,
    },
  })

export const sendProductionOrderPutInProductionEmails = (
  productionOrderId: Id,
  emailData: Object
) =>
  api.post(
    `/production/orders/${productionOrderId}/send-put-in-production-emails`,
    {
      body: {
        email: emailData,
      },
    }
  )

export const updateProductionOrderPrices = (productionOrderIds, productIds) =>
  api.post('/production/orders/update-prices', {
    body: {
      production_order_ids: productionOrderIds,
      product_ids: productIds,
    },
  })

export const ensureProductionOrderProductsFromDrops = productionOrderId =>
  api.post(`/production/orders/${productionOrderId}/ensure-drop-products`)

export const getOrderedQuantitiesOfProductionOrder = (
  productionOrderId,
  variantIds
) =>
  api.post(`/production/orders/${productionOrderId}/ordered-quantities`, {
    body: {
      variantIds: variantIds,
    },
  })

export const getDropOrderListOfProductionOrder = productionOrderId =>
  api.get(`/production/orders/${productionOrderId}/drop-orders`)

export const getAvailableProductionOrderProducts = (
  productionOrderId,
  options
) =>
  api.get(`/production/orders/${productionOrderId}/available-products`, {
    query: options,
  })

// Overhead cost invoices

export const getOverheadCostInvoice = (
  overheadCostInvoiceId: Id,
  options?: Object
) =>
  api.get(`/production/overhead-cost-invoices/${overheadCostInvoiceId}`, {
    query: options,
  })

export const getOverheadCostInvoiceAttributes = () =>
  api.get('/production/overhead-cost-invoices/attributes')

export const getOverheadCostInvoiceList = (options?: DataTableRequestOptions) =>
  api.get('/production/overhead-cost-invoices/list', {
    query: options,
  })

export const createOverheadCostInvoice = (data: $Shape<OverheadCostInvoice>) =>
  api.post(`/production/overhead-cost-invoices`, {
    body: {
      overhead_cost_invoice: data,
    },
  })

export const updateOverheadCostInvoice = (
  overheadCostInvoiceId: Id,
  data: $Shape<OverheadCostInvoice>
) =>
  api.put(`/production/overhead-cost-invoices/${overheadCostInvoiceId}`, {
    body: {
      overhead_cost_invoice: data,
    },
  })

export const deleteOverheadCostInvoice = (overheadCostInvoiceId: Id) =>
  api.delete(`/production/overhead-cost-invoices/${overheadCostInvoiceId}`)

export const createOverheadCostInvoiceAllocation = (
  overheadCostInvoiceId: Id,
  allocation
) =>
  api.post(
    `/production/overhead-cost-invoices/${overheadCostInvoiceId}/allocations`,
    {
      body: {
        allocation,
      },
    }
  )

// Delivery notes

export const getProductionOrderDeliveryNote = (
  productionOrderDeliveryNoteId: Id,
  options?: Object
) =>
  api.get(`/production/delivery-notes/${productionOrderDeliveryNoteId}`, {
    query: options,
  })

export const getProductionOrderDeliveryNoteAttributes = () =>
  api.get('/production/delivery-notes/attributes')

export const getProductionOrderDeliveryNoteList = (
  options?: DataTableRequestOptions
) =>
  api.get('/production/delivery-notes/list', {
    query: options,
  })

export const updateProductionOrderDeliveryNote = (
  productionOrderDeliveryNoteId: Id,
  data: $Shape<ProductionOrderDeliveryNote>
) =>
  api.put(`/production/delivery-notes/${productionOrderDeliveryNoteId}`, {
    body: {
      production_order_delivery_note: data,
    },
  })

export const updateProductionOrderDeliveryNoteFromScanner = (
  productionOrderDeliveryNoteId: Id,
  data
) =>
  api.put(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/from-scanner`,
    {
      body: {
        production_order_delivery_note: data,
      },
    }
  )

export const deleteProductionOrderDeliveryNote = (
  productionOrderDeliveryNoteId: Id
) => api.delete(`/production/delivery-notes/${productionOrderDeliveryNoteId}`)

// Production prepayments

export const getProductionPrepayment = (
  productionPrepaymentId: Id,
  options?: Object
) =>
  api.get(`/production/production-prepayments/${productionPrepaymentId}`, {
    query: options,
  })

export const getProductionPrepaymentAttributes = () =>
  api.get('/production/production-prepayments/attributes')

export const getProductionPrepaymentList = (
  options?: DataTableRequestOptions
) =>
  api.get('/production/production-prepayments/list', {
    query: options,
  })

export const createProductionPrepayment = (
  data: $Shape<ProductionPrepayment>
) =>
  api.post(`/production/production-prepayments`, {
    body: {
      production_prepayment: data,
    },
  })

export const updateProductionPrepayment = (
  productionPrepaymentId: Id,
  data: $Shape<ProductionPrepayment>
) =>
  api.put(`/production/production-prepayments/${productionPrepaymentId}`, {
    body: {
      production_prepayment: data,
    },
  })

export const deleteProductionPrepayment = (productionPrepaymentId: Id) =>
  api.delete(`/production/production-prepayments/${productionPrepaymentId}`)

// Production invoice

export const calculateProductionInvoiceTotals = (
  productionOrderDeliveryNoteId: Id,
  invoiceCosts: Array<ProductionInvoiceCostType>
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/calculate-totals`,
    {
      body: {
        invoice_costs: invoiceCosts,
      },
    }
  )

export const checkProductionInvoiceCostPrices = (
  productionOrderDeliveryNoteId: Id,
  lines
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/check-cost-prices`,
    {
      body: {
        lines,
      },
    }
  )

export const exportProductionOrderDeliveryNotes = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  includeLines: boolean,
  deliveryPerFile: boolean,
  options?: Object
) =>
  api.post(`/production/delivery-notes/export`, {
    body: {
      columns,
      filters,
      include_lines: includeLines,
      delivery_per_file: deliveryPerFile,
      sort,
      options,
    },
  })

export const splitProductionOrderDeliveryNote = (
  productionOrderDeliveryNoteId: Id,
  lines: Array<{ id: Id, quantity: number }>
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/split`,
    {
      body: {
        lines,
      },
    }
  )

export const getProductionOrderDeliveryNoteCostPricesOverview = (
  axiosOptions = {},
  productionOrderDeliveryNoteId: Id
) =>
  api.get(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/cost-prices-overview`,
    {
      ...axiosOptions,
    }
  )

// Packing instructions

export const generateProductionDeliveryPackingInstructions = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/generate-packing-instructions`
  )

export const updatedProductionOrderDeliveryNotePackingInstructions = (
  productionOrderDeliveryNoteId: Id,
  orders: Array<Order>
) =>
  api.put(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/packing-instructions`,
    {
      body: {
        orders: orders,
      },
    }
  )

export const shipPackingInstructionOrders = (
  productionOrderDeliveryNoteId: Id,
  dropIds: Array<Id>,
  orderIds?: Array<Id>
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/ship-packing-instructions`,
    {
      body: {
        drop_ids: dropIds,
        order_ids: orderIds,
      },
    }
  )

export const exportPackingInstructionOrdersToExcel = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/export-packing-instructions`
  )

export const processProductionOrderDeliveryDateChanges =
  productionDeliveryDateChangeIds =>
    api.post('/production/process-delivery-date-changes', {
      body: {
        delivery_date_change_ids: productionDeliveryDateChangeIds,
      },
    })

export const getProductionOrderDeliveryNoteForScanner = (
  productionOrderDeliveryNoteId,
  options
) =>
  api.get(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/for-scanner`,
    { query: options }
  )

export const startReceivingProductionOrderDeliveryNote = (
  productionOrderDeliveryNoteId,
  options
) =>
  api.post(
    `/production/delivery-notes/${productionOrderDeliveryNoteId}/start-receiving`,
    {
      body: options,
    }
  )

// Products

export const getProductPrices = () => api.get('/production/products/prices')

export const createProductPriceEstimates = products =>
  api.post('/production/products/prices', {
    body: {
      products,
    },
  })

export const getOrdersWithProducts = (orderStatuses, products) =>
  api.get('/production/products/orders', {
    query: {
      products: products,
      order_statuses: orderStatuses,
    },
  })

export const addProductionParts = (productId: Id, parts) =>
  api.post(`/production/products/${productId}/parts`, {
    body: {
      parts,
    },
  })

export const updateProductParts = (productId: Id, parts) =>
  api.put(`/production/products/${productId}/parts`, {
    body: {
      parts,
    },
  })

export const removePartFromProduct = (productId: Id, partId, partVariantId) =>
  api.delete(`/production/products/${productId}/parts`, {
    body: {
      part_id: partId,
      part_variant_id: partVariantId,
    },
  })

export const createProductPrice = (productId: Id, price) =>
  api.post(`/production/products/${productId}/prices`, {
    body: {
      price,
    },
  })

export const updateProductPrices = (productId: Id, prices) =>
  api.put(`/production/products/${productId}/prices`, {
    body: {
      prices,
    },
  })

export const removeProductPrice = (productId: Id, priceId) =>
  api.delete(`/production/products/${productId}/prices/${priceId}`)

// Suppliers

export const exportSuppliers = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  splitByAddresses?: boolean,
  currencies?: Array<Currency>,
  onlyProductsMissingValue?: boolean
) =>
  api.post(`/production/suppliers/export`, {
    body: {
      columns,
      filters,
      currencies,
      only_products_missing_value: onlyProductsMissingValue,
      sort,
      split_by_addresses: splitByAddresses,
    },
  })

export const getSuppliers = (options?: Object) =>
  api.get('/production/suppliers', {
    query: options,
  })

export const getSupplier = (supplierId: Id, options?: Object) =>
  api.get(`/production/suppliers/${supplierId}`, {
    query: options,
  })

export const activateSupplier = (supplierId: Id) =>
  api.post(`/production/suppliers/${supplierId}/activate`)

export const deactivateSupplier = (supplierId: Id) =>
  api.post(`/production/suppliers/${supplierId}/deactivate`)

export const createSupplier = (data: Object) =>
  api.post('/production/suppliers', {
    body: {
      supplier: data,
    },
  })

export const updateSupplier = (supplierId: Id, data: Object) =>
  api.put(`/production/suppliers/${supplierId}`, {
    body: {
      supplier: data,
    },
  })

export const getSupplierList = (options?: DataTableRequestOptions) =>
  api.get('/production/suppliers/list', {
    query: options,
  })

export const getSupplierAttributes = () =>
  api.get('/production/suppliers/attributes')

export const createSupplierContactPerson = (
  data: $Shape<SupplierContactPerson>
) =>
  api.post(`/production/suppliers/contact-persons`, {
    body: {
      supplier_contact_person: data,
    },
  })

export const updateSupplierContactPerson = (
  id: Id,
  data: $Shape<SupplierContactPerson>
) =>
  api.put(`/production/suppliers/contact-persons/${id}`, {
    body: {
      supplier_contact_person: data,
    },
  })

export const deleteSupplierContactPerson = (id: Id) =>
  api.delete(`/production/suppliers/contact-persons/${id}`)

// Addresses

export const getSupplierAddresses = (supplierId: Id) =>
  api.get(`/production/suppliers/${supplierId}/addresses`)

export const createSupplierAddress = (
  supplierId: Id,
  address: $Shape<Address>
) =>
  api.post(`/production/suppliers/${supplierId}/addresses`, {
    body: {
      address,
    },
  })

export const deleteSupplierAddress = (supplierId: Id, addressId: Id) =>
  api.delete(`/production/suppliers/${supplierId}/addresses/${addressId}`)

export const updateSupplierAddress = (
  supplierId: Id,
  addressId: Id,
  address: $Shape<Address>
) =>
  api.put(`/production/suppliers/${supplierId}/addresses/${addressId}`, {
    body: {
      address,
    },
  })

// Cost types

export const getCostTypes = () => api.get('/production/cost-types')

export const updateCostTypes = costTypes =>
  api.put('/production/cost-types', {
    body: {
      cost_types: costTypes,
    },
  })

export const getProductionInvoiceCostTypes = () =>
  api.get('/production/invoice-cost-types')

export const updateProductionInvoiceCostTypes = (
  costTypes: Array<$Shape<ProductionInvoiceCostType>>
) =>
  api.put('/production/invoice-cost-types', {
    body: {
      cost_types: costTypes,
    },
  })

// 3PL

export const pushProductionOrderDeliveryNoteGeneric3pl = (
  productionOrderDeliveryNoteId: Id,
  redo: boolean = false
) =>
  api.post(`/3pl/production/${productionOrderDeliveryNoteId}/push`, {
    body: {
      redo,
    },
  })

// DSV

export const pushProductionOrderDeliveryNoteDsv = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(`/apps/dsv/production-orders/${productionOrderDeliveryNoteId}/push`)

// Rhenus

export const pushProductionOrderDeliveryNoteRhenus = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(
    `/apps/rhenus/production-orders/${productionOrderDeliveryNoteId}/push`
  )

// Prime Cargo

export const pushProductionOrderDeliveryNotePrimeCargo = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(
    `/apps/primecargo/production-orders/${productionOrderDeliveryNoteId}/push`
  )

// Systemtransport

export const pushProductionOrderDeliveryNoteSystemtransport = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(
    `/apps/systemtransport/production-orders/${productionOrderDeliveryNoteId}/push`
  )

// Ongoing

export const pushProductionOrderDeliveryNoteOngoing = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(
    `/apps/ongoing/production-orders/${productionOrderDeliveryNoteId}/push`
  )

// Pakkecenter Nord

export const pushProductionOrderDeliveryNotePakkecenterNord = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(
    `/apps/pakkecenternord/production-orders/${productionOrderDeliveryNoteId}/push`
  )

// Tmc

export const pushProductionOrderDeliveryNoteTmc = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(`/apps/tmc/production-orders/${productionOrderDeliveryNoteId}/push`)

// ThetisPack

export const pushProductionOrderDeliveryNoteThetisPack = (
  productionOrderDeliveryNoteId: Id
) =>
  api.post(
    `/thetis-pack/production/delivery-notes/${productionOrderDeliveryNoteId}/push`
  )

// Parts

export const getParts = options =>
  api.get('/production/parts', {
    query: options,
  })

// Files

export const saveProductionFile = (
  fileHolderType,
  fileHolderId,
  filesArePrivate,
  files
) =>
  api.post('/production/files', {
    body: {
      file_holder_type: fileHolderType,
      file_holder_id: fileHolderId,
      files_are_private: filesArePrivate,
      files,
    },
  })

// Need

export const calculateProductionNeedBySupplier = () =>
  api.post('/production/need/by-supplier')

export const calculateProductionNeedByOrder = () =>
  api.post('/production/need/by-order')

export const calculateProductionNeedByProduct = options =>
  api.post('/production/need/by-product', {
    body: options,
  })

export const generateDeliveryCharts = input =>
  api.post('/production/delivery-charts', {
    body: input,
  })

// Quick production order

export const createProductionOrderDataFromQuickOrder = (values: Object) =>
  api.post(`/production/quick-order`, {
    body: values,
  })

// Fortnox

export const pushSupplierFortnox = (supplierId: Id) =>
  api.post(`/apps/fortnox/suppliers/${supplierId}/push`)
