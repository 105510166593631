/* @flow */

import React from 'react'
import styled from 'styled-components'

import { productionOrderStatusOptions } from './shared'

import type { ProductionOrder } from '../types'

type Props = {
  productionOrder: ProductionOrder,
  size?: 'xs' | 'sm' | 'lg',
}

const ProductionOrderStatusLabel = ({
  productionOrder,
  size = 'sm',
}: Props) => {
  const buttonClasses = React.useMemo(() => {
    let classes = `label label-${size}`

    const s = productionOrder.status
    switch (s) {
      case 'cancelled':
        classes += ' label-important'
        break
      case 'in_production':
        classes += ' label-success'
        break
      case 'partially_received':
        classes += ' label-danger'
        break
      case 'received':
        classes += ' label-green'
        break
      case 'open':
        classes += ' label-warning'
        break
      default:
        classes += ' label-success'
        break
    }

    return classes
  }, [productionOrder.status, size])
  const label = React.useMemo(() => {
    return productionOrderStatusOptions.find(
      s => s.value === productionOrder.status
    ).label
  }, [productionOrder.status, productionOrderStatusOptions])

  return <span className={buttonClasses}>{label}</span>
}

export default ProductionOrderStatusLabel
